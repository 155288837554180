import React from 'react';

import check_circle from '../../assets/image/check-circle.svg';
import exclamination from '../../assets/image/exclamation.svg';
import x_circle from '../../assets/image/x_circle.svg';

import './SummaryTable.scss'

const SummaryTable = ({active_companies, no_data_companies, unactive_companies}) => {
    return (
        <div className='summary_table'>
            <div className='data_card active'>
                <div className='card_text'>
                    <span className='card_title'>ACTIVE COMPANIES</span>
                    <span className='card_amount'>{active_companies}</span>
                </div>
                <img src={check_circle} alt='checked'/>
            </div>
            {/*<div className='data_card active_without_lawyer'>*/}
                {/*<div className='card_text'>*/}
                    {/*<span className='card_title'>ACTIVE COMPANIES WITHOUT A LAWYER</span>*/}
                    {/*<span className='card_amount'>{active_companies_without_lawyer}</span>*/}
                {/*</div>*/}
                {/*<img src={check_circle} alt='checked'/>*/}
            {/*</div>*/}
            <div className='data_card no_data' >
                <div className='card_text'>
                    <span className='card_title'>NO DATA COMPANIES</span>
                    <span className='card_amount'>{no_data_companies}</span>
                </div>
                <img src={exclamination} alt='exclamination' />
            </div>
            <div className='data_card unactive' >
                <div className='card_text'>
                    <span className='card_title'>UNACTIVE COMPANIES</span>
                    <span className='card_amount'>{unactive_companies}</span>
                </div>
                <img src={x_circle} alt='X ciircle' />
            </div>
        </div>
    );
};

export default SummaryTable;