import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';

import '../CategoryProfile.scss'

class AccessLevelForm extends Component {

    render(){
        const { handleSubmit, accessLevelSubmit,submitting, pristine, valid } = this.props;
        return (
            <form onSubmit={handleSubmit(accessLevelSubmit)}>
                <div className='btn_wrapper'><button  disabled={submitting || pristine || !valid} className='button'>Change access level</button></div>
                <div className='card_body'>
                    <label>
                        <Field name="access_level" component="input" type="radio" value="at" />
                        at
                    </label>
                    <label>
                        <Field name="access_level" component="input" type="radio" value="ma" />
                        ma
                    </label>
                    <label>
                        <Field name="access_level" component="input" type="radio" value="ba" />
                        ba
                    </label>
                </div>
            </form>
        );
    }
}

AccessLevelForm = reduxForm({
    form: 'AccessLevelForm',
    enableReinitialize: true
})(AccessLevelForm);

function  mapStateToProps(state, props) {
    return{

        initialValues: {
            access_level: state.categories.current_category.access_level ,
        },

    }
}

export default connect(mapStateToProps, null)(AccessLevelForm);