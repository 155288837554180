import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Field, reduxForm} from 'redux-form';
import { getAvailableCompaniesList } from "../../../actions/getAvailableCompaniesAction";

class ConnectCompanyForm extends Component {

    state={
        inputValue: null,
    };

    componentDidMount() {
        this.doRequest();
    }
    doRequest = () => {
        const { getAvailableCompaniesList } = this.props;
        this.setState({inputValue: null});
        getAvailableCompaniesList()
    };

    handleSelect = (e) => {
        this.setState({inputValue: e.target.value})
    };

    render(){
        const { handleSubmit, available_companies, connectionToCompany } = this.props;
        return (
            <form onSubmit={handleSubmit(connectionToCompany)}>
                <div className='collapse_form'>
                    <Field onChange={(e) => this.handleSelect(e)} name='company' component='select' className='form_select'>
                        <option value='' disabled defaultValue>Search from database</option>
                        {available_companies.map((company, key) => {
                            return(
                                <option value={company.id} key={key}>{company.name}</option>
                            )
                        })}
                    </Field>
                    <button disabled={!this.state.inputValue}>Connect</button>
                </div>
            </form>
        );
    }
}

ConnectCompanyForm = reduxForm({
    form: 'ConnectCompanyForm',
})(ConnectCompanyForm);

function  mapStateToProps(state, props) {
    return{
        available_companies: state.available_companies.available_companies,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAvailableCompaniesList,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCompanyForm);