import * as types from './constants.jsx'

export function seoFormInfoManipulates(slug, method, data) {
    return {
        type: types[`${method}_SEO_FORMS_INFO`],
        payload: {
            client: 'default',
            request: {
                url: `/api/static_pages/${slug}/`,
                method,
                data,
            },
        },
    }
}

export function seoFormProfileInfoManipulates(id, type, method, data) {
    return {
        type: types[`${method}_SEO_FORMS_PROFILE_INFO`],
        payload: {
            client: 'default',
            request: {
                url: `/api/${type}/${id}/`,
                method,
                data,
            },
        },
    }
}
