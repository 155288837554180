import React from 'react'
import './RenderField.scss'
import TooltipMUI from '../TooltipMUI'
import { ReactComponent as ErrorIcon } from './icons/error.svg'

const renderField_add = ({
    input,
    label,
    className,
    placeholder,
    type,
    meta: { touched, error, warning },
    handleInput,
}) => (
    <label
        className={
            touched && error
                ? input.value !== ''
                    ? 'block-input-mail error_border value'
                    : 'error_border  error_border-custom block-input-mail'
                : input.value !== ''
                ? 'block-input-mail value'
                : 'block-input-mail'
        }>
        <input
            {...input}
            className={className}
            placeholder={placeholder}
            type={type}
            autoComplete='off'
            onClick={handleInput}
        />
        {touched && error ? (
            <TooltipMUI title={error} position='left' errorColor>
                <ErrorIcon
                    className='input-mui__error-icon'
                    width='20px'
                    height='20px'
                />
            </TooltipMUI>
        ) : (
            ''
        )}
    </label>
)

export default renderField_add
