import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import AccessLevelForm from './Froms/AccessLevelForm'
import ContactForm from './Froms/ContactForm'
import {
    patchCurrentLawyer,
    getCurrentLawyer,
    postDisconnectLawyer,
    postNewPosition,
    deletePosition,
    changePositionState,
    postLanguage,
    deleteLanguage,
} from '../../actions/LawyersAction'
import { connectToUser } from '../../actions/connectToUserAction'
import { getUsersForLawyers } from '../../actions/UserAction'
import { getAvailableCompaniesList } from '../../actions/getAvailableCompaniesAction'
import {
    connectLawyerToCompany,
    disconnectLawyerFromCompany,
    resetErrors,
} from '../../actions/companiesAction'
import { changeCompaniesState } from '../../actions/changeCompanyStateAction'
import {
    patchCategoryForLawyer,
    disconnectLawyerFromCategory,
} from '../../actions/CategoriesAction'
import {
    changePublicationsState,
    deletePublications,
} from '../../actions/PublicationsAction'
import { deleteNews, changeNewsState } from '../../actions/NewsAction'
import FlagIcon from '../../FlagIcon'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Loader from '../HelperComponents/Loader/Loader'
import ConnectCompanyForm from './Froms/ConnectCompanyForm'
import CreatePositionForLawyerForm from './Froms/createPositionForLawyerForm'
import languages from '../../helpers/languages'
import { getOption } from '../../helpers/functions'
import SelectComponent from '../HelperComponents/SelectComponentDefault/SelectComponent'
import FormControl from '@material-ui/core/FormControl'
import ControlDisconnect from '../HelperComponents/ControlButtons/ControlDisconnect'
import ControlDelete from '../HelperComponents/ControlButtons/ControlDelete'
import Presentation from './Presentation'
import MyCategoriesForm from './Froms/MyCategoriesForm'
import AddCategoryForm from './Froms/AddCategoryForm'
import Pagination from '@material-ui/lab/Pagination'
import EmptyIcon from '../../assets/image/no-image.svg'
import trash from '../../assets/image/trash.svg'
import edit from '../../assets/image/edit.svg'
import DataPublicationsCard from './DataCard/DataPublicationsCard'
import DataNewsCard from './DataCard/DataNewsCard'
import ControlChangeCompany from './ChageControls/ControlChangeCompany'
import ControlChangePosition from './ChageControls/ControlChangePosition'
import SeoFormProfile from './../HelperComponents/SeoForm/SeoFormProfile'

import './LawyerProfile.scss'

class LawyerProfile extends Component {
    state = {
        loading: true,
        languagesOpen: false,
        access_level: '',
        companiesOpen: false,
        positionOpen: false,
        inputValue: '',
        changeable_input: false,
        openPopper: false,
        imageUploading: false,
        openChangeDialog: false,
        openDisconnectDialog: false,
        selected_id: null,
        languages_option: null,
        languages_options: [],
        categoriesIsOpen: false,
        total_category_pages: 1,
        current_category_page: 1,
        displayed_category: [],
        table_cols: [
            'Title',
            'Published by',
            'Date',
            'Updated on',
            'Author',
            'Co-author',
            'Linked company',
            'Status',
            'Controls',
        ],
    }

    componentDidMount() {
        const { getUsersForLawyers, lawyer_info } = this.props
        const languages_list = { ...languages }
        let languages_options = []
        Object.entries(languages_list).forEach(e =>
            languages_options.push({
                label: getOption(e[1].name),
                value: e[1].name,
                name: e[0],
            })
        )
        this.setState({ languages_options })
        getUsersForLawyers().then(() => {
            this.doRequest(true)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.lawyers.lawyer_info.categories !==
            this.props.lawyers.lawyer_info.categories
        ) {
            this.doRequest(true)
        }
        if (
            prevState.total_category_pages !== this.state.total_category_pages
        ) {
        }
    }

    doRequest = pagin => {
        const {
            getCurrentLawyer,
            match: { params },
        } = this.props
        getCurrentLawyer(params.id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({
                    loading: false,
                    inputValue:
                        res.payload.data.user && res.payload.data.user.name
                            ? res.payload.data.user.name
                            : '',
                    access_level: res.payload.data.access_level,
                    displayed_category: res.payload.data.categories,
                })
                if (res.payload.data.categories.length % 10 === 0) {
                    this.setState({
                        total_category_pages:
                            res.payload.data.categories.length / 10,
                    })
                } else {
                    this.setState({
                        total_category_pages: Math.ceil(
                            res.payload.data.categories.length / 10
                        ),
                    })
                }
                if (pagin) {
                    this.setState({
                        displayed_category: res.payload.data.categories.slice(
                            0,
                            10
                        ),
                    })
                }
            }
        })
    }

    handleInput = () => {
        this.setState(({ changeable_input }) => ({
            changeable_input: !changeable_input,
        }))
    }

    handlePopper = () => {
        this.setState(({ openPopper }) => ({
            openPopper: !openPopper,
        }))
    }

    onChange = e => {
        this.setState({ inputValue: e.target.value })
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    onKeyUp = e => {
        const { openPopper } = this.state
        if (
            e.target.value.length >= 1 &&
            e.target.value.trim() &&
            !openPopper
        ) {
            this.setState({ openPopper: true })
        }
        if (e.target.value.length === 0) {
            this.setState({ openPopper: false })
        }
    }

    selectUser = item => {
        const {
            connectToUser,
            match: { params },
        } = this.props
        const data = { lawyer: Number(params.id) }
        connectToUser(item.id, data).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 202
            ) {
                this.setState({
                    inputValue: item.name,
                    openPopper: false,
                    changeable_input: false,
                })
                this.doRequest()
            }
        })
    }

    openLanguages = () => {
        const { languagesOpen } = this.state
        const { resetErrors } = this.props
        resetErrors()
        this.setState({ languagesOpen: !languagesOpen })
    }

    openCompanies = () => {
        const { companiesOpen } = this.state
        const { resetErrors } = this.props
        resetErrors()
        this.setState({ companiesOpen: !companiesOpen })
    }

    openPositions = () => {
        const { positionOpen } = this.state
        const { resetErrors } = this.props
        resetErrors()
        this.setState({ positionOpen: !positionOpen })
    }

    openCategories = () => {
        const { categoriesIsOpen } = this.state
        const { resetErrors } = this.props
        resetErrors()
        this.setState({ categoriesIsOpen: !categoriesIsOpen })
    }

    patchLanguage = value => {
        const {
            lawyers: {
                lawyer_info: { id },
            },
            postLanguage,
        } = this.props

        postLanguage(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({ loading: false, languages_option: null })
                this.doRequest()
            }
        })
        // let data = {"languages": [...lawyer_info.languages_display, value]};
        // this.patchLawyer(data);
        // postLanguage
    }

    languageDeletion = value => {
        const {
            lawyers: {
                lawyer_info: { id },
            },
            deleteLanguage,
        } = this.props

        deleteLanguage(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({ loading: false, languages_option: null })
                this.doRequest()
            }
        })
        // let originArr = lawyer_info.languages_display
        // originArr.splice(value, 1)
        // let data = { languages: originArr }
        // this.patchLawyer(data)
    }

    patchLawyer = value => {
        const {
            patchCurrentLawyer,
            match: { params },
        } = this.props
        patchCurrentLawyer(params.id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({ loading: false, languages_option: null })
                this.doRequest()
            }
        })
    }

    postPosition = data => {
        const {
            postNewPosition,
            match: { params },
        } = this.props
        let array = { ...data }
        array.company = Number(data.company)
        let id = Number(params.id)
        postNewPosition(id, array).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 201
            ) {
                this.doRequest()
                this.setState({ positionOpen: false })
            }
        })
    }

    positionDeletion = id => {
        const { deletePosition } = this.props
        deletePosition(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    connectionToCompany = data => {
        const {
            connectLawyerToCompany,
            match: { params },
        } = this.props
        let array = { ...data, lawyer: Number(params.id) }
        array.company = Number(data.company)
        connectLawyerToCompany(array).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
                this.setState({ companiesOpen: false })
            }
        })
    }

    disconnectionFromCompany = value => {
        const { disconnectLawyerFromCompany } = this.props
        disconnectLawyerFromCompany(value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    companyChangeState = data => {
        const { changeCompaniesState } = this.props
        let id = data.is_active.split(',')[1]
        let value = {
            is_active: data.is_active.split(',')[0] === 'true' ? true : false,
        }
        changeCompaniesState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    positionChangeState = data => {
        const { changePositionState } = this.props
        let id = data.is_active.split(',')[1]
        let value = {
            is_active: data.is_active.split(',')[0] === 'true' ? true : false,
        }
        changePositionState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    moveToCompanyPage = id => {
        const { history } = this.props
        history.push(`/main/company-profile/${id}`)
    }

    disconnectLawyer = id => {
        const { postDisconnectLawyer } = this.props
        postDisconnectLawyer(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    categoryHandler = (category, idx) => {
        this.setState({
            chosenCategory: category,
            reloading: true,
            clicked: idx,
        })
        setTimeout(() => {
            this.setState({ reloading: false })
        }, 0)
    }

    patchCategory = data => {
        const { patchCategoryForLawyer } = this.props
        let obj = {
            description_english: data.description_english
                ? data.description_english
                : '',
            description_estonian: data.description_estonian
                ? data.description_estonian
                : '',
            fixed_rate: data.fixed_rate ? data.fixed_rate : 0,
            hourly_rate: data.hourly_rate ? data.hourly_rate : 0,
        }
        let id = data.id
        patchCategoryForLawyer(obj, id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
                this.categoryHandler()
            }
        })
    }

    disconnectCategoryFromLawyer = id => {
        const { disconnectLawyerFromCategory } = this.props
        disconnectLawyerFromCategory(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
                this.categoryHandler()
            }
        })
    }

    publicationsStateChanger = data => {
        const { changePublicationsState } = this.props
        let id = data.status.split(' ')[1]
        let value = {
            status:
                data.status.split(' ')[0] === 'review' ? 'review' : 'published',
        }
        changePublicationsState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    publicationsDeletion = id => {
        const { deletePublications } = this.props
        deletePublications(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    newsStateChanger = data => {
        const { changeNewsState } = this.props
        let id = data.status.split(' ')[1]
        let state = {
            status:
                data.status.split(' ')[0] === 'review' ? 'review' : 'published',
        }
        changeNewsState(id, state).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    newsDeletion = id => {
        const { deleteNews } = this.props
        deleteNews(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    imageSelectedHandler = e => {
        e.persist()
        this.setState({ image: e.target.files[0] })
    }

    imageUploadHandler = () => {
        const {
            patchCurrentLawyer,
            match: { params },
            resetErrors,
        } = this.props
        const { image } = this.state
        const fd = new FormData()
        fd.append('pic', image)
        patchCurrentLawyer(params.id, fd).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                resetErrors()
                this.doRequest()
            }
        })
    }

    handlePagination = (e, page) => {
        const {
            lawyers: {
                lawyer_info: { categories },
            },
        } = this.props
        let arr = categories.slice(page * 10 - 10, page * 10)
        this.setState({ displayed_category: arr, clicked: null })
        this.categoryHandler(null, null)
    }

    render() {
        const {
            access_level,
            languagesOpen,
            companiesOpen,
            positionOpen,
            loading,
            inputValue,
            openPopper,
            changeable_input,
            image,
            imageUploading,
            languages_options,
            languages_option,
            categoriesIsOpen,
            chosenCategory,
            reloading,
            clicked,
            total_category_pages,
            displayed_category,
            table_cols,
        } = this.state
        const {
            users_for_lawyers,
            lawyers: { lawyer_info },
            errorLawyer,
            match: { params },
            errorCategory,
            history,
        } = this.props

        if (loading) return null
        let users = [...users_for_lawyers]
        users = users.filter(el =>
            el.name.toLowerCase().includes(inputValue.toLowerCase())
        )
        let language_list = { ...languages }
        return (
            <TransitionedBlock>
                <div className='lawyer_profile_wrapper'>
                    <div className='breadcrumbs'>
                        <Link to='/main/dashboard'>Home</Link>
                        &nbsp; / &nbsp; <Link to='/main/lawyers'>
                            Lawyers
                        </Link>{' '}
                        &nbsp; / &nbsp;{' '}
                        {lawyer_info.first_name + ` ` + lawyer_info.last_name}
                    </div>

                    <div className='connection_row'>
                        <span className='connection_title'>
                            Connected to user
                        </span>
                        <input
                            autoFocus
                            tabIndex='1'
                            id='connection_input'
                            className='connection_input'
                            disabled={!changeable_input}
                            onChange={this.onChange}
                            onKeyUp={this.onKeyUp}
                            value={inputValue}
                        />
                        {openPopper && inputValue && (
                            <ClickAwayListener onClickAway={this.handlePopper}>
                                <div className='popper_wrapper'>
                                    <ul>
                                        {users.length > 0 ? (
                                            users.map(el => (
                                                <li
                                                    onClick={() =>
                                                        this.selectUser(el)
                                                    }
                                                    key={el.id}>
                                                    <div>{el.name}</div>
                                                </li>
                                            ))
                                        ) : (
                                            <li>No users</li>
                                        )}
                                    </ul>
                                </div>
                            </ClickAwayListener>
                        )}
                        <div className='control_wrapper'>
                            <div
                                onClick={this.handleInput}
                                className='control_btn link'>
                                <img src={edit} alt='edit icon' />
                            </div>
                        </div>

                        {lawyer_info.user ? (
                            <ControlDisconnect
                                disconnect_action={this.disconnectLawyer}
                                item={'lawyer'}
                                itemId={lawyer_info.id}
                            />
                        ) : null}
                    </div>

                    <div className='connection_row'>
                        <span className='connection_title'>
                            To public profile:
                        </span>
                        <input
                            className='connection_input'
                            disabled
                            placeholder={`https://www.lawthor.com/lawyer/${lawyer_info.slug}`}
                        />
                    </div>

                    <span className='lawyer_name'>
                        {lawyer_info.first_name + ` ` + lawyer_info.last_name}
                    </span>

                    <div className='blocks_wrapper'>
                        <div className='profile_block lawyer_sm'>
                            <div
                                className={`profile_block_title ${access_level}`}>
                                <span className='level'>{access_level}</span>
                            </div>
                            <div className='card_body'>
                                <img
                                    className='lawyer_banner'
                                    src={
                                        lawyer_info.pic
                                            ? lawyer_info.pic
                                            : EmptyIcon
                                    }
                                    alt='lawyer'
                                />
                            </div>
                            <div className='card_footer'>
                                <span className='pic_err'>
                                    {errorLawyer && errorLawyer.pic}
                                </span>
                                <div className='upload_file'>
                                    <input
                                        name='image'
                                        type='file'
                                        className='upload_input'
                                        onChange={e =>
                                            this.imageSelectedHandler(e)
                                        }
                                    />
                                    <label className='upload_label'>
                                        {image ? image.name : 'Choose file'}
                                    </label>
                                </div>
                                <button
                                    onClick={this.imageUploadHandler}
                                    className='button'
                                    disabled={!image}>
                                    {imageUploading ? (
                                        <Loader size={30} color='white' />
                                    ) : (
                                        'Update picture'
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className='column'>
                            <div className='profile_block access_level'>
                                <div className='profile_block_title'>
                                    Lawyer access level
                                </div>
                                <AccessLevelForm
                                    accessLevelSubmit={this.patchLawyer}
                                />
                            </div>
                            <div className='profile_block presentation'>
                                <div className='profile_block_title'>
                                    My resume
                                </div>
                                <div className='card_body'>
                                    <span>
                                        Your company presentation can be a 250
                                        words long.
                                    </span>
                                    <Presentation
                                        lawyer_info={lawyer_info}
                                        lawyerId={lawyer_info.id}
                                        errorLawyer={errorLawyer}
                                        doRequest={this.doRequest}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='blocks_wrapper'>
                        <div className='profile_block'>
                            <div className='profile_block_title'>
                                My languages
                                <button
                                    className='add_button'
                                    onClick={this.openLanguages}>
                                    <div>
                                        <svg
                                            aria-hidden='true'
                                            focusable='false'
                                            data-prefix='fas'
                                            data-icon='plus-circle'
                                            className='svg-inline--fa fa-plus-circle fa-w-16'
                                            role='img'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'>
                                            <path
                                                fill='currentColor'
                                                d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z'></path>
                                        </svg>
                                    </div>
                                    <span>Add a language</span>
                                </button>
                            </div>
                            {languagesOpen ? (
                                <div className='collapse_form'>
                                    <span className='back_error'>
                                        {errorLawyer.details}
                                    </span>
                                    <FormControl style={{ width: '100%' }}>
                                        <SelectComponent
                                            value={languages_option}
                                            options={languages_options}
                                            change={e =>
                                                this.changeOption(
                                                    'languages_option',
                                                    e
                                                )
                                            }
                                            isClearable='false'
                                            isSearchable={true}
                                            classname='state_selector'
                                            placeholder={'Search from database'}
                                        />
                                    </FormControl>
                                    <button
                                        onClick={() =>
                                            this.patchLanguage(
                                                languages_option.name
                                            )
                                        }
                                        disabled={!languages_option}>
                                        Add language
                                    </button>
                                </div>
                            ) : null}

                            <div className='card_body'>
                                <span>
                                    Languages displayed on your public profile
                                </span>
                                {lawyer_info.languages_display.map(
                                    (lang, index) => (
                                        <div
                                            key={index}
                                            className='language_box'>
                                            {Object.entries(language_list).map(
                                                (e, idx) =>
                                                    e[0] ===
                                                    lang.toLowerCase() ? (
                                                        <div key={idx}>
                                                            {e[1].name}
                                                        </div>
                                                    ) : null
                                            )}
                                            <FlagIcon
                                                code={lang.toLowerCase()}
                                                squared={true}
                                                size={'lg'}
                                            />
                                            <div className='control_wrapper'>
                                                <button
                                                    onClick={() =>
                                                        this.languageDeletion(
                                                            lang
                                                        )
                                                    }
                                                    className='control_btn trash'>
                                                    <img
                                                        src={trash}
                                                        alt='trash icon'
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                            <div className='card_footer'>
                                <button
                                    disabled={!languagesOpen}
                                    onClick={this.openLanguages}
                                    className='button'>
                                    Save
                                </button>
                            </div>
                        </div>
                        <div className='profile_block'>
                            <div className='profile_block_title'>
                                My contact information
                            </div>
                            <ContactForm contactFormSubmit={this.patchLawyer} />
                        </div>
                    </div>

                    <div className='blocks_wrapper switcher'>
                        <div className='profile_block'>
                            <div className='profile_block_title'>
                                Companies
                                <button
                                    className='add_button'
                                    onClick={this.openCompanies}>
                                    <div>
                                        <svg
                                            aria-hidden='true'
                                            focusable='false'
                                            data-prefix='fas'
                                            data-icon='plus-circle'
                                            className='svg-inline--fa fa-plus-circle fa-w-16'
                                            role='img'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'>
                                            <path
                                                fill='currentColor'
                                                d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z'></path>
                                        </svg>
                                    </div>
                                    <span>Connect a company</span>
                                </button>
                            </div>
                            <div className='card_body'>
                                {companiesOpen ? (
                                    <ConnectCompanyForm
                                        connectionToCompany={
                                            this.connectionToCompany
                                        }
                                    />
                                ) : null}
                                {lawyer_info.works_in.length > 0 ? (
                                    <div className='card_table'>
                                        <div className='card_items_row header_row'>
                                            <div>ID</div>
                                            <div>Name</div>
                                            <div>State</div>
                                            <div>Controls</div>
                                        </div>

                                        {lawyer_info.works_in.map((el, key) => (
                                            <div
                                                className='card_items_row default_row'
                                                key={key}>
                                                <div className='table_div'>
                                                    {el.id}
                                                </div>
                                                <div className='table_div'>
                                                    {el.name}
                                                </div>
                                                <div className='table_div'>
                                                    <div
                                                        className={
                                                            el.is_active
                                                                ? 'table_circle active'
                                                                : 'table_circle'
                                                        }
                                                    />
                                                    {el.is_active
                                                        ? 'Active'
                                                        : 'Disabled'}
                                                </div>
                                                <div className='card_controls'>
                                                    <span className='control_wrapper'>
                                                        <button
                                                            onClick={() =>
                                                                this.moveToCompanyPage(
                                                                    el.id
                                                                )
                                                            }
                                                            className='control_btn link'>
                                                            <img
                                                                src={edit}
                                                                alt='edit icon'
                                                            />
                                                        </button>
                                                    </span>
                                                    <ControlChangeCompany
                                                        itemId={el.id}
                                                        itemStatus={
                                                            el.is_active
                                                        }
                                                        stateChanger={
                                                            this
                                                                .companyChangeState
                                                        }
                                                    />
                                                    <ControlDisconnect
                                                        disconnect_action={
                                                            this
                                                                .disconnectionFromCompany
                                                        }
                                                        item={'company'}
                                                        itemId={{
                                                            company: el.id,
                                                            lawyer: lawyer_info.id,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <span>Connect company to the lawyer</span>
                                )}
                            </div>
                        </div>
                        <div className='profile_block'>
                            <div className='profile_block_title'>
                                Positions
                                <button
                                    className='add_button'
                                    onClick={this.openPositions}>
                                    <div>
                                        <svg
                                            aria-hidden='true'
                                            focusable='false'
                                            data-prefix='fas'
                                            data-icon='plus-circle'
                                            className='svg-inline--fa fa-plus-circle fa-w-16'
                                            role='img'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'>
                                            <path
                                                fill='currentColor'
                                                d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z'></path>
                                        </svg>
                                    </div>
                                    <span>Add a position</span>
                                </button>
                            </div>
                            <div className='card_body'>
                                {positionOpen ? (
                                    <div className='collapse_form'>
                                        <CreatePositionForLawyerForm
                                            postPosition={this.postPosition}
                                        />
                                    </div>
                                ) : null}

                                <div className='card_table'>
                                    {lawyer_info.positions.length > 0 ? (
                                        <Fragment>
                                            <div className='card_items_row header_row positions'>
                                                <div>Position</div>
                                                <div>Name</div>
                                                <div>Positions</div>
                                                <div>Controls</div>
                                            </div>
                                            {lawyer_info.positions.map(
                                                (el, key) => (
                                                    <div
                                                        className='card_items_row default_row positions'
                                                        key={key}>
                                                        <div className='table_div'>
                                                            {el.name}
                                                        </div>
                                                        <div className='table_div'>
                                                            {el.company.name}
                                                        </div>
                                                        <div className='table_div'>
                                                            <div
                                                                className={
                                                                    el.is_active
                                                                        ? 'table_circle active'
                                                                        : 'table_circle'
                                                                }
                                                            />
                                                            {el.is_active
                                                                ? 'Active'
                                                                : 'Disabled'}
                                                        </div>

                                                        <div className='card_controls'>
                                                            {el.company
                                                                .is_active ? (
                                                                <ControlChangePosition
                                                                    itemId={
                                                                        el.id
                                                                    }
                                                                    itemStatus={
                                                                        el.is_active
                                                                    }
                                                                    stateChanger={
                                                                        this
                                                                            .positionChangeState
                                                                    }
                                                                />
                                                            ) : null}
                                                            <ControlDelete
                                                                delete_action={
                                                                    this
                                                                        .positionDeletion
                                                                }
                                                                item={
                                                                    'position'
                                                                }
                                                                itemId={el.id}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </Fragment>
                                    ) : (
                                        <span>
                                            Create a position for lawyer
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='blocks_wrapper column'>
                        <SeoFormProfile
                            title='SEO tags'
                            id={params.id}
                            type='lawyers'
                            link=''
                            idx='1'
                            info={lawyer_info}
                        />
                        <span className='lawyer_name'>My categories</span>
                        <div className='profile_block categories'>
                            <div className='collapse_wrapper'>
                                <div className='profile_block_title categories'>
                                    <button
                                        className='add_button'
                                        onClick={this.openCategories}>
                                        <div>
                                            <svg
                                                aria-hidden='true'
                                                focusable='false'
                                                data-prefix='fas'
                                                data-icon='plus-circle'
                                                className='svg-inline--fa fa-plus-circle fa-w-16'
                                                role='img'
                                                xmlns='http://www.w3.org/2000/svg'
                                                viewBox='0 0 512 512'>
                                                <path
                                                    fill='currentColor'
                                                    d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z'></path>
                                            </svg>
                                        </div>
                                        <span>Add a category</span>
                                    </button>
                                </div>
                                {categoriesIsOpen ? (
                                    <div className='collapse_form'>
                                        <span className='back_error'>
                                            {errorCategory && errorCategory[0]}
                                        </span>
                                        <AddCategoryForm
                                            access_level={
                                                lawyer_info.access_level
                                            }
                                            toggler={this.openCategories}
                                            lawyerId={params.id}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <div className='card_body'>
                                <ul className='card_body_categories'>
                                    {displayed_category.map((category, idx) => (
                                        <li
                                            onClick={() =>
                                                this.categoryHandler(
                                                    category,
                                                    idx
                                                )
                                            }
                                            className={`category ${
                                                clicked === idx ? 'blue' : ''
                                            }`}
                                            id={idx}>
                                            {category.title}
                                        </li>
                                    ))}
                                </ul>
                                <div className='card_body_fields'>
                                    {!reloading && (
                                        <MyCategoriesForm
                                            submitFunc={this.patchCategory}
                                            disabler={chosenCategory}
                                            initialValues={chosenCategory}
                                            deleteFunc={
                                                this
                                                    .disconnectCategoryFromLawyer
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <Pagination
                                style={{ margin: '0 0 20px 20px' }}
                                count={total_category_pages}
                                color='primary'
                                variant='outlined'
                                shape='rounded'
                                onChange={this.handlePagination}
                                defaultPage={1}
                            />
                        </div>
                    </div>
                    <DataPublicationsCard
                        table_cols={table_cols}
                        items={lawyer_info.my_publications}
                        delete_action={this.publicationsDeletion}
                        stateChanger={this.publicationsStateChanger}
                        card_title='Publications'
                        history={history}
                    />
                    <DataNewsCard
                        table_cols={table_cols}
                        items={lawyer_info.my_news}
                        delete_action={this.newsDeletion}
                        stateChanger={this.newsStateChanger}
                        card_title='News'
                        history={history}
                    />
                </div>
            </TransitionedBlock>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Required field'
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)
    ) {
        errors.email = 'Incorrect email'
    }
    if (!values.password) {
        errors.password = 'Required field'
    }
    if (!values.first_name) {
        errors.first_name = 'Required field'
    }
    if (!values.last_name) {
        errors.last_name = 'Required field'
    }
    return errors
}

LawyerProfile = reduxForm({
    form: 'LawyerProfile',
    validate,
})(LawyerProfile)

function mapStateToProps(state, props) {
    return {
        users_for_lawyers: state.users.users_for_lawyers,
        user: state.certainUser.user,
        lawyers: state.lawyers,
        errorLawyer: state.errors.error,
        errorCategory: state.categories.error,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCurrentLawyer,
            patchCurrentLawyer,
            getUsersForLawyers,
            postDisconnectLawyer,
            getAvailableCompaniesList,
            connectLawyerToCompany,
            disconnectLawyerFromCompany,
            postNewPosition,
            deletePosition,
            connectToUser,
            changeCompaniesState,
            resetErrors,
            changePositionState,
            patchCategoryForLawyer,
            disconnectLawyerFromCategory,
            changePublicationsState,
            deletePublications,
            deleteNews,
            changeNewsState,
            postLanguage,
            deleteLanguage,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(LawyerProfile)
