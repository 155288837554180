import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { getAllUsersList } from "../../actions/UserAction";
import { createUser } from '../../actions/UserAction';
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock';
import { changeUserState } from '../../actions/UserAction';
import { deleteUser } from "../../actions/UserAction";

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DataCard from "./DataCard/DataCard";
import RenderField_pass from "../HelperComponents/RenderField/RenderField_pass";
import RenderField_mail from "../HelperComponents/RenderField/renderField_mail";

import './Users.scss'
import SummaryTable from "./SummaryTable";

class Users extends Component {

    state = {
        formOpen: false,
        table_cols: ['ID', 'Name, Surname', 'Connected companies', 'Connected lawyer', 'State', 'Controls'],
        setOpen: false,
        deleteText: ['This action will permanently delete this account and the links attached to it? The alternative is to disable the account then recovery is possible.'],
        loading: true,

    };

    componentDidMount() {
        this.doRequest();
    }

    doRequest = () => {
        const { getAllUsersList } = this.props;
        getAllUsersList();

    };

    openForm = () => {
        const formOpen = this.state.formOpen;
        this.setState({formOpen: !formOpen})
    };


    submitForm = ( data ) => {
        const { createUser, reset } = this.props;
        let obj = { ...data };
        createUser(obj).then(res =>{
            if(res.payload && res.payload.status && res.payload.status === 201) {
                this.doRequest();
                reset('Users');
                this.openForm();
                this.setState({setOpen: true});
            }else{
                this.doRequest()
            }

        })
    };
    userChangeState = (data) => {
        const { changeUserState } = this.props;
        let id = data.is_active.split(',')[1];
        let value = { 'is_active' : data.is_active.split(',')[0] === 'true' ? true : false};
        changeUserState(id, value).then(res =>{
            if(res.payload && res.payload.status && res.payload.status === 200) {
                this.doRequest();
            }
        })
    };

    userDeletion = (id) => {
        const { deleteUser } = this.props;

        deleteUser(id).then(res =>{
            if(res.payload && res.payload.status && res.payload.status === 204) {
                this.doRequest();
            }
        })
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({setOpen: false});
    };


    render(){

    const {formOpen, table_cols, setOpen, deleteText} = this.state;
    const { handleSubmit, activeArr, unactiveArr, history, errorPostUser } = this.props;
        return (
            <TransitionedBlock>
                <div className='users_wrapper'>
                    <div className='breadcrumbs'>
                        <Link to='/main/dashboard'>Home</Link>
                        &nbsp; / &nbsp; Legal users
                    </div>
                    <SummaryTable
                        active_users={activeArr.length}
                        unactive_users={unactiveArr.length}
                    />
                    <button className='green_button' onClick={this.openForm}>Add a legal user</button>
                    {formOpen ?
                    <form className='add_form' onSubmit={handleSubmit(this.submitForm)}>
                        <div className='add_form_names'>
                            <div className='names relative_position'>
                                <span>Name</span>
                                <span className="back_error">{errorPostUser.first_name}</span>
                            <Field
                                name="first_name"
                                className="add_input"
                                type="text"
                                component={RenderField_pass}
                                placeholder=""
                                autoComplete='off'
                            />
                            </div>
                            <div className='names relative_position'>
                            <span>Surname</span>
                                <span className="back_error">{errorPostUser.last_name}</span>
                            <Field
                                name="last_name"
                                className="add_input"
                                type="text"
                                component={RenderField_pass}
                                placeholder=""
                                autoComplete='off'
                            />
                            </div>
                        </div>
                        <div className='relative_position'>
                            <span>Contact e-mail</span>
                        <span className="back_error">{errorPostUser.email}</span>
                            <Field
                                name="email"
                                className="add_input"
                                type="text"
                                component={RenderField_mail}
                                placeholder=""
                                autoComplete='off'
                            />
                        </div>
                        <div className='relative_position'>
                        <span>Phone number</span>
                        <span className="back_error">{errorPostUser.phone_number}</span>
                        <Field
                            name="phone_number"
                            className="add_input"
                            type="text"
                            component={RenderField_pass}
                            placeholder=""
                            autoComplete='off'
                        />
                        </div>

                        <button>Submit form</button>
                    </form>
                        :
                        null}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={setOpen}
                        autoHideDuration={1000}
                        onClose={this.handleClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">User created</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleClose}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                        <DataCard table_cols={table_cols}
                                  history={history}
                                  items={unactiveArr}
                                  delete_action={this.userDeletion}
                                  bodyText={deleteText}
                                  stateChanger={this.userChangeState}
                                  card_title='Unactive'
                        />
                        <DataCard table_cols={table_cols}
                                  items={activeArr}
                                  history={history}
                                  stateChanger={this.userChangeState}
                                  card_title='Active'
                        />
                </div>

            </TransitionedBlock>
        );
    }
}



Users = reduxForm({
    form: 'Users',
})(Users);

function  mapStateToProps(state) {
    return{
        all_users_list: state.users.all_users_list,
        unactiveArr: state.users.unactiveArr,
        activeArr: state.users.activeArr,
        errorPostUser: state.users.error,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllUsersList,
        createUser,
        changeUserState,
        deleteUser,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);