import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { disconnectCompany } from '../../actions/disconnectCompanyAction'
import {
    postDisconnectLawyer,
    changeLawyerState,
} from '../../actions/LawyersAction'
import { connectToUser } from '../../actions/connectToUserAction'
import { connectCompanyToUser } from '../../actions/connectToCompanyAction'
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import RenderField_pass from '../HelperComponents/RenderField/RenderField_pass'
import RenderField_mail from '../HelperComponents/RenderField/renderField_mail'
import ControlDisconnect from '../HelperComponents/ControlButtons/ControlDisconnect'
import ConnectLawyerForm from './Froms/ConnectLawyerForm'
import ConnectCompanyForm from './Froms/ConnectCompanyForm'
import { certainProfile } from '../../actions/certainProfileActions'
import { changeCompaniesState } from '../../actions/changeCompanyStateAction'
import { patchCurrentUser } from '../../actions/UserAction'
import ControlChangeCompany from './ChageControls/ControlChangeCompany'
import ControlChangeLawyer from './ChageControls/ControlChangeLawyer'
import edit from '../../assets/image/edit.svg'
import SelectComponent from '../HelperComponents/SelectComponentDefault/SelectComponent'
import FormControl from '@material-ui/core/FormControl'
import { getStates } from '../../actions/StatesAction'

import './UserProfile.scss'
import { getOption } from '../../helpers/functions'

class UserProfile extends Component {
    state = {
        loading: true,
        page: 0,
        nextPage: 1,
        lastPage: 0,
        lawyerOpen: false,
        companiesOpen: false,
        deleteText: [
            `This action will permenently delete this position from the legal user.`,
            <br />,
            `The alternative is to disable the position then recovery is possible.`,
        ],
        state_option: null,
        state_options: [],
    }

    componentDidMount() {
        const { getStates } = this.props
        getStates().then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                const states_list = res.payload.data
                let state_options = []
                states_list.forEach(e =>
                    state_options.push({
                        label: getOption(e.name),
                        value: e.id,
                    })
                )
                this.setState({ state_options: state_options })
            }
        })
        this.doRequest()
    }

    doRequest = () => {
        const {
            certainProfile,
            match: { params },
        } = this.props
        certainProfile(params.id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState(({ state_option, state_options }) => ({
                    state_option: res.payload.data.state
                        ? state_options.filter(
                              el => el.value === res.payload.data.state
                          )
                        : state_option,
                    loading: false,
                }))
            }
        })
    }

    openLawyers = () => {
        const { lawyerOpen } = this.state
        this.setState({ lawyerOpen: !lawyerOpen })
    }
    openCompanies = () => {
        const { companiesOpen } = this.state
        this.setState({ companiesOpen: !companiesOpen })
    }

    disconnectCompany = id => {
        const { disconnectCompany } = this.props

        disconnectCompany(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }
    disconnectLawyer = id => {
        const { postDisconnectLawyer } = this.props

        postDisconnectLawyer(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    lawyerChangeState = data => {
        const { changeLawyerState } = this.props
        let id = data.is_active.split(',')[1]
        let value = {
            is_active:
                (data.is_active && data.is_active.split(',')[0]) === 'true'
                    ? true
                    : false,
        }
        changeLawyerState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    connectionToUser = data => {
        const { connectToUser, user } = this.props
        let id = user.id

        connectToUser(id, data).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 202
            ) {
                this.doRequest()
                this.setState({
                    lawyerOpen: false,
                    companiesOpen: false,
                })
            }
        })
    }

    connectionToCompany = data => {
        const {
            connectCompanyToUser,
            match: { params },
        } = this.props
        connectCompanyToUser(params.id, data).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 202
            ) {
                this.doRequest()
                this.setState({
                    lawyerOpen: false,
                    companiesOpen: false,
                })
            }
        })
    }

    companyChangeState = data => {
        const { changeCompaniesState } = this.props
        let id = data.is_active && data.is_active.split(',')[1]
        let value = {
            is_active:
                (data.is_active && data.is_active.split(',')[0]) === 'true'
                    ? true
                    : false,
        }
        changeCompaniesState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    moveToCompanyPage = id => {
        const { history } = this.props
        history.push(`/main/company-profile/${id}`)
    }

    moveToLawyerPage = id => {
        const { history } = this.props
        history.push(`/main/lawyer-profile/${id}`)
    }

    patchUser = value => {
        const {
            patchCurrentUser,
            match: { params },
        } = this.props
        const { state_option } = this.state
        let dataToPost = {
            ...value,
            state: state_option ? state_option.value : null,
        }
        patchCurrentUser(params.id, dataToPost).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({ loading: false })
                this.doRequest()
            }
        })
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    render() {
        const {
            lawyerOpen,
            companiesOpen,
            loading,
            state_option,
            state_options,
            initial_state,
        } = this.state
        const { user, connection_error, handleSubmit } = this.props

        if (loading) return null

        return (
            <TransitionedBlock>
                <div className='user_profile_wrapper'>
                    <div className='breadcrumbs'>
                        <Link to='/main/dashboard'>Home</Link>
                        &nbsp; / &nbsp;{' '}
                        <Link to='/main/legal-users'>Legal users</Link> &nbsp; /
                        &nbsp; {user.first_name + ` ` + user.last_name}
                    </div>
                    <span className='user_name'>
                        {user.first_name + ` ` + user.last_name}
                    </span>
                    <div className='profile_block'>
                        <div className='profile_block_title'>
                            Conctact information
                        </div>
                        <form
                            className='add_form'
                            onSubmit={handleSubmit(this.patchUser)}>
                            <div className='add_form_names'>
                                <div className='names'>
                                    <span>Name</span>
                                    <Field
                                        name='first_name'
                                        className='add_input'
                                        type='text'
                                        component={RenderField_pass}
                                        placeholder={user.first_name}
                                        autoComplete='off'
                                    />
                                </div>
                                <div className='names'>
                                    <span>Surname</span>
                                    <Field
                                        name='last_name'
                                        className='add_input'
                                        type='text'
                                        component={RenderField_pass}
                                        placeholder={user.last_name}
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            <div className='add_form_names'>
                                <div className='names'>
                                    <span>Email</span>
                                    <Field
                                        name='email'
                                        className='add_input'
                                        type='text'
                                        component={RenderField_mail}
                                        placeholder={user.email}
                                        autoComplete='off'
                                    />
                                </div>
                                <div className='names'>
                                    <span>Phone</span>
                                    <Field
                                        name='phone_number'
                                        className='add_input'
                                        type='text'
                                        component={RenderField_pass}
                                        placeholder={user.phone_number}
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            <div className='add_form_names'>
                                <div className='names'>
                                    <span>Address</span>
                                    <Field
                                        name='address'
                                        className='add_input'
                                        type='text'
                                        component={RenderField_pass}
                                        placeholder={user.address}
                                        autoComplete='off'
                                    />
                                </div>
                                <div className='names'>
                                    <span>Website</span>
                                    <Field
                                        name='website'
                                        className='add_input'
                                        type='text'
                                        component={RenderField_pass}
                                        placeholder={user.website}
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            <div className='add_form_city'>
                                <div className='names first'>
                                    <span>City</span>
                                    <Field
                                        name='city'
                                        className='add_input'
                                        type='text'
                                        component={RenderField_pass}
                                        placeholder={user.city}
                                        autoComplete='off'
                                    />
                                </div>
                                <div className='names'>
                                    <span>State</span>
                                    <FormControl
                                        className='select_wrapper'
                                        style={{ width: '100%' }}>
                                        <SelectComponent
                                            value={state_option}
                                            options={state_options}
                                            change={e =>
                                                this.changeOption(
                                                    'state_option',
                                                    e
                                                )
                                            }
                                            isClearable='false'
                                            isSearchable={true}
                                            classname='state_selector'
                                            // placeholder={`${state_options.filter(el => el.value === user.state)[0].label.props.children.props.children}`}
                                        />
                                    </FormControl>
                                </div>
                                <div className='names'>
                                    <span>Zip</span>
                                    <Field
                                        name='zip_code'
                                        className='add_input'
                                        type='text'
                                        component={RenderField_pass}
                                        placeholder={user.zip_code}
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            <button>Save</button>
                        </form>
                    </div>
                    <div className='profile_block'>
                        <div className='profile_block_title'>
                            Lawyer
                            <button
                                className='add_button'
                                onClick={this.openLawyers}>
                                <div>
                                    <svg
                                        aria-hidden='true'
                                        focusable='false'
                                        data-prefix='fas'
                                        data-icon='plus-circle'
                                        className='svg-inline--fa fa-plus-circle fa-w-16'
                                        role='img'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 512 512'>
                                        <path
                                            fill='currentColor'
                                            d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z'></path>
                                    </svg>
                                </div>
                                <span>Connect a lawyer</span>
                            </button>
                        </div>
                        <div className='card_body'>
                            {lawyerOpen ? (
                                <ConnectLawyerForm
                                    connection_error={connection_error}
                                    connectionToUser={this.connectionToUser}
                                />
                            ) : null}
                            {user.lawyer !== null ? (
                                <div className='card_table'>
                                    <div className='card_items_row header_row'>
                                        <div>ID</div>
                                        <div>Name, surname</div>
                                        <div>Access level</div>
                                        <div>Connected companies</div>
                                        <div>State</div>
                                        <div>Controls</div>
                                    </div>

                                    <div className='card_items_row default_row lawyers'>
                                        <div className='table_div'>
                                            {user.lawyer.id}
                                        </div>
                                        <div className='table_div'>
                                            {user.lawyer.name}
                                        </div>
                                        <div className='table_div'>
                                            {user.lawyer.access_level.toUpperCase()}
                                        </div>
                                        <div className='table_div'>
                                            {user.lawyer.companies.length > 0
                                                ? user.lawyer.companies.map(
                                                      (company, idx) => (
                                                          <div key={idx}>
                                                              {company}
                                                          </div>
                                                      )
                                                  )
                                                : 0}
                                        </div>
                                        <div className='table_div'>
                                            <div
                                                className={
                                                    user.lawyer.is_active
                                                        ? 'table_circle active'
                                                        : 'table_circle'
                                                }
                                            />
                                            {user.lawyer.is_active
                                                ? 'Active'
                                                : 'Disabled'}
                                        </div>
                                        <div className='card_controls'>
                                            <div className='control_wrapper'>
                                                <div
                                                    onClick={() =>
                                                        this.moveToLawyerPage(
                                                            user.lawyer.id
                                                        )
                                                    }
                                                    className='control_btn link'>
                                                    <img
                                                        src={edit}
                                                        alt='edit icon'
                                                    />
                                                </div>
                                            </div>
                                            <ControlChangeLawyer
                                                itemId={user.lawyer.id}
                                                itemStatus={
                                                    user.lawyer.is_active
                                                }
                                                stateChanger={
                                                    this.lawyerChangeState
                                                }
                                            />
                                            <ControlDisconnect
                                                item={'lawyer'}
                                                disconnect_action={
                                                    this.disconnectLawyer
                                                }
                                                itemId={user.lawyer.id}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <span style={{ color: '#858796' }}>
                                    Connect a lawyer
                                </span>
                            )}
                        </div>
                    </div>
                    <div className='blocks_wrapper'>
                        <div className='profile_block'>
                            <div className='profile_block_title'>
                                Companies
                                <button
                                    className='add_button'
                                    onClick={this.openCompanies}>
                                    <div>
                                        <svg
                                            aria-hidden='true'
                                            focusable='false'
                                            data-prefix='fas'
                                            data-icon='plus-circle'
                                            className='svg-inline--fa fa-plus-circle fa-w-16'
                                            role='img'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'>
                                            <path
                                                fill='currentColor'
                                                d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z'></path>
                                        </svg>
                                    </div>
                                    <span>Connect a company</span>
                                </button>
                            </div>
                            <div className='card_body'>
                                {companiesOpen ? (
                                    <ConnectCompanyForm
                                        connectionToCompany={
                                            this.connectionToCompany
                                        }
                                    />
                                ) : null}
                                {user.companies.length !== 0 ? (
                                    <div className='card_table'>
                                        <div className='card_items_row header_row companies '>
                                            <div>ID</div>
                                            <div>Name</div>
                                            <div>State</div>
                                            <div>Controls</div>
                                        </div>

                                        {user.companies.map((company, idx) => (
                                            <div
                                                className='card_items_row default_row companies'
                                                key={idx}>
                                                <div className='table_div'>
                                                    {company.id}
                                                </div>
                                                <div className='table_div'>
                                                    {company.name}
                                                </div>
                                                <div className='table_div'>
                                                    <div
                                                        className={
                                                            company.is_active
                                                                ? 'table_circle active'
                                                                : 'table_circle'
                                                        }
                                                    />
                                                    {company.is_active
                                                        ? 'Active'
                                                        : 'Disabled'}
                                                </div>
                                                <div className='card_controls'>
                                                    <div className='control_wrapper'>
                                                        <div
                                                            onClick={() =>
                                                                this.moveToCompanyPage(
                                                                    company.id
                                                                )
                                                            }
                                                            className='control_btn link'>
                                                            <img
                                                                src={edit}
                                                                alt='edit icon'
                                                            />
                                                        </div>
                                                    </div>
                                                    <ControlChangeCompany
                                                        itemId={company.id}
                                                        itemStatus={
                                                            company.is_active
                                                        }
                                                        stateChanger={
                                                            this
                                                                .companyChangeState
                                                        }
                                                    />
                                                    <ControlDisconnect
                                                        disconnect_action={
                                                            this
                                                                .disconnectCompany
                                                        }
                                                        item={'company'}
                                                        itemId={company.id}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <span style={{ color: '#858796' }}>
                                        Connect a company
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionedBlock>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Required field'
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)
    ) {
        errors.email = 'Incorrect email'
    }
    if (!values.password) {
        errors.password = 'Required field'
    }
    if (!values.first_name) {
        errors.first_name = 'Required field'
    }
    if (!values.last_name) {
        errors.last_name = 'Required field'
    }
    return errors
}

UserProfile = reduxForm({
    form: 'UserProfile',
    validate,
    enableReinitialize: true,
})(UserProfile)

function mapStateToProps(state, props) {
    return {
        states: state.states.states,
        user: state.certainUser.user,
        connection_error: state.certainUser.error,
        initialValues: {
            first_name: state.certainUser.user.first_name,
            last_name: state.certainUser.user.last_name,
            email: state.certainUser.user.email,
            address: state.certainUser.user.address,
            phone_number: state.certainUser.user.phone_number,
            city: state.certainUser.user.city,
            zip_code: state.certainUser.user.zip_code,
            state: state.certainUser.user.state,
        },
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            disconnectCompany,
            connectToUser,
            connectCompanyToUser,
            certainProfile,
            changeCompaniesState,
            postDisconnectLawyer,
            changeLawyerState,
            patchCurrentUser,
            getStates,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
