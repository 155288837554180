import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import Data1Card from './DataCard/Data1Card'
import SummaryTable from './SummaryTable'
import FlagIcon from '../../FlagIcon'
import Snackbar from '@material-ui/core/Snackbar'
import {
    getAllCategoriesList,
    postNewCategory,
    deleteCategory,
    changeCategoryState,
} from '../../actions/CategoriesAction'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/core/SvgIcon/SvgIcon'
import renderField_add from '../HelperComponents/RenderField/RenderField_add_bluePages'
import RenderField_seo_description from '../HelperComponents/RenderField/RenderField_seo_description'
import arrow from '../../assets/image/new_arrow.svg'
import SeoForm from '../HelperComponents/SeoForm/SeoForm'

import './Categories.scss'

class Categories extends Component {
    state = {
        loading: true,
        formOpen: false,
        setOpen: false,
        controlLang: 'English',
        clicked: [],
        table_cols: [
            'Title',
            'Access Level',
            'News',
            'News Ref.',
            'Publications',
            'Publications Ref.',
            'Connected lawyers',
            'Average €/Hour',
            'Average €/Fixed',
            'State',
            'Controls',
        ],
    }

    componentDidMount() {
        this.doRequest()
    }

    doRequest = () => {
        const { getAllCategoriesList } = this.props
        getAllCategoriesList().then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({ loading: false })
            }
        })
    }

    openForm = () => {
        const formOpen = this.state.formOpen
        this.setState({ formOpen: !formOpen })
    }

    submitForm = data => {
        const { postNewCategory, reset } = this.props
        postNewCategory(data).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 201
            ) {
                this.doRequest()
                reset('Categories')
                this.openForm()
                this.setState({ setOpen: true })
            } else {
                this.doRequest()
            }
        })
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        this.setState({ setOpen: false })
    }

    deletionCategory = id => {
        const { deleteCategory } = this.props
        deleteCategory(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    categoryChangeState = data => {
        const { changeCategoryState } = this.props
        let id = data.is_active.split(',')[1]
        let value = {
            is_active: data.is_active.split(',')[0] === 'true' ? true : false,
        }
        changeCategoryState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    tabs = ['English', 'Estonian']

    forms = [
        {
            title: '',
            slug: 'categories',
            link: '',
            pageName: 'Categories',
        },
        {
            title: '',
            slug: 'all-categories',
            link: '',
            pageName: 'All categories',
        },
    ]

    changeLang = el => {
        this.setState({ controlLang: el })
    }

    chooseForms = idx => {
        const { clicked: oldClicked } = this.state
        const elIdx = oldClicked.findIndex(el => el === idx)
        this.setState(({ clicked }) => ({
            clicked:
                elIdx === -1
                    ? [...clicked, idx]
                    : [...clicked.slice(0, elIdx), ...clicked.slice(elIdx + 1)],
        }))
    }

    changeTab = tab => {
        this.setState({ tab });
    };

    render() {
        const { formOpen, table_cols, loading, setOpen, controlLang, clicked } =
            this.state
        const {
            handleSubmit,
            history,
            activeArr,
            unactiveArr,
            noDataArr,
            err_message,
        } = this.props
        if (loading) return null
        return (
            <TransitionedBlock>
                <div className='categories_wrapper'>
                    <div className='breadcrumbs'>
                        <Link to='/main/dashboard'>Home</Link>
                        &nbsp; / &nbsp; Categories
                    </div>
                    <SummaryTable
                        active_categories={activeArr.length}
                        no_data_categories={noDataArr.length}
                        unactive_categories={unactiveArr.length}
                    />

                    <div className='forms_items'>
                        {this.forms.map(
                            ({ title, slug, link, pageName }, idx) => (
                                <div
                                    key={idx}
                                    className={`choose_form ${
                                        clicked.includes(idx) ? 'clicked' : null
                                    }`}>
                                    <div
                                        className='forms_title'
                                        onClick={() => this.chooseForms(idx)}>
                                        <span>
                                            <b>{pageName}</b> titles and
                                            subtitles / SEO tags
                                        </span>
                                        <img src={arrow} alt='arrow' />
                                    </div>
                                    {clicked.includes(idx) ? (
                                        <SeoForm
                                            {...{ title, slug, link, idx }}
                                        />
                                    ) : null}
                                </div>
                            )
                        )}
                    </div>

                    <button className='green_button' onClick={this.openForm}>
                        Add category
                    </button>
                    {formOpen ? (
                        <form
                            className='add_form'
                            onSubmit={handleSubmit(this.submitForm)}>
                            <div className='add_form-wrapper'>
                                <div className='create_info'>
                                    <div className='relative_position'>
                                        <FlagIcon
                                            code='gb'
                                            squared={true}
                                            size={'lg'}
                                        />
                                        <span>Title of category</span>
                                        <span className='back_error'>{}</span>
                                        <Field
                                            name='title_english'
                                            className='add_input'
                                            type='text'
                                            component={renderField_add}
                                            placeholder=''
                                            autoComplete='off'
                                        />
                                    </div>
                                    <div className='relative_position'>
                                        <FlagIcon
                                            code='ee'
                                            squared={true}
                                            size={'lg'}
                                        />
                                        <span>Title of category</span>
                                        <span className='back_error'>{}</span>
                                        <Field
                                            name='title_estonian'
                                            className='add_input'
                                            type='text'
                                            component={renderField_add}
                                            placeholder=''
                                            autoComplete='off'
                                        />
                                    </div>
                                    <div className='level_option relative_position'>
                                        <label>
                                            <Field
                                                name='access_level'
                                                component='input'
                                                type='radio'
                                                value='at'
                                            />
                                            <span className='at'>at</span>
                                        </label>
                                        <label>
                                            <Field
                                                name='access_level'
                                                component='input'
                                                type='radio'
                                                value='ma'
                                            />
                                            <span className='ma'>ma</span>
                                        </label>
                                        <label>
                                            <Field
                                                name='access_level'
                                                component='input'
                                                type='radio'
                                                value='ba'
                                            />
                                            <span className='ba'>ba</span>
                                        </label>
                                        <span className='back_error'>
                                            {err_message.access_level}
                                        </span>
                                    </div>
                                </div>
                                <div className='add_form_seo'>
                                    <div className='tabs'>
                                        {this.tabs.map((el, idx) => (
                                            <div
                                                key={idx}
                                                onClick={() =>
                                                    this.changeLang(el)
                                                }
                                                className={`control_lang-btn${
                                                    controlLang === el
                                                        ? ' active'
                                                        : ''
                                                }`}>
                                                {el}
                                            </div>
                                        ))}
                                    </div>


                                    <div
                                        className={`seo_form${
                                            controlLang === 'English'
                                                ? ' visible'
                                                : ''
                                        }`}>
                                        <div className='names'>
                                            <span>Meta-title</span>

                                            <Field
                                                name='seo_title_english'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-description</span>

                                            <Field
                                                name='seo_description_english'
                                                className='add_description'
                                                type='text'
                                                component={
                                                    RenderField_seo_description
                                                }
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-keywords</span>

                                            <Field
                                                name='seo_keywords_english'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className={`seo_form${
                                            controlLang === 'Estonian'
                                                ? ' visible'
                                                : ''
                                        }`}>
                                        <div className='names'>
                                            <span>Meta-title</span>

                                            <Field
                                                name='seo_title_estonian'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-description</span>

                                            <Field
                                                name='seo_description_estonian'
                                                className='add_description'
                                                type='text'
                                                component={
                                                    RenderField_seo_description
                                                }
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-keywords</span>

                                            <Field
                                                name='seo_keywords_estonian'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='add_form-button-wrapper'>
                                <button>Create a new category</button>
                            </div>
                        </form>
                    ) : null}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={setOpen}
                        autoHideDuration={1000}
                        onClose={this.handleClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id='message-id'>Category created</span>}
                        action={[
                            <IconButton
                                key='close'
                                aria-label='close'
                                color='inherit'
                                onClick={this.handleClose}>
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                    <Data1Card
                        table_cols={table_cols}
                        items={unactiveArr}
                        delete_action={this.deletionCategory}
                        stateChanger={this.categoryChangeState}
                        card_title='Unactive'
                        history={history}
                    />
                    <Data1Card
                        table_cols={table_cols}
                        items={noDataArr}
                        stateChanger={this.categoryChangeState}
                        card_title='No Data'
                        history={history}
                    />
                    <Data1Card
                        table_cols={table_cols}
                        items={activeArr}
                        stateChanger={this.categoryChangeState}
                        card_title='Active'
                        history={history}
                    />
                </div>
            </TransitionedBlock>
        )
    }
}

export const validate = values => {
    const errors = {}
    if (!values.title_english) {
        errors.title_english = 'Required field.'
    }
    if (!values.title_estonian) {
        errors.title_estonian = 'Required field.'
    }
    return errors
}

Categories = reduxForm({
    form: 'Categories',
    validate,
})(Categories)

function mapStateToProps(state) {
    return {
        all_categories_list: state.categories.all_categories_list,
        noDataArr: state.categories.noDataArr,
        unactiveArr: state.categories.unactiveArr,
        activeArr: state.categories.activeArr,
        err_message: state.categories.error,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllCategoriesList,
            postNewCategory,
            deleteCategory,
            changeCategoryState,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
