import * as types from './constants.jsx';


export function getStates() {
    return {
        type: types.GET_STATES,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/states/`,
                method: 'get'
            }
        }
    };
}