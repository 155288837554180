import React from 'react'

export function getOption(label) {
    return (
        <div className='status_select'>
            <div>{label}</div>
        </div>
    )
}

export function dataCreator(el) {
    let row = []
    for (let [, value] of Object.entries(el)) {
        row.push(
            <div className='table_div'>
                {typeof value === 'boolean'
                    ? value
                        ? 'Active'
                        : 'Disabled'
                    : value}
            </div>
        )
    }
    return row
}

export function sortValues(arr, sort_by) {
    console.log(sort_by)
    switch (sort_by) {
        case 'ID_up':
            return arr.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
        case 'ID_down':
            return arr.sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
        case 'Name_up':
            return arr.sort((a, b) => a.name.localeCompare(b.name))
        case 'Name_down':
            return arr.sort((a, b) => b.name.localeCompare(a.name))
        case 'Name, surname_down':
            return arr.sort((a, b) => b.name.localeCompare(a.name))
        case 'Name, surname_up':
            return arr.sort((a, b) => a.name.localeCompare(b.name))
        case 'Title_up':
            return arr.sort((a, b) =>
                (a.title_english ? a.title_english : a.title).localeCompare(
                    b.title_english ? b.title_english : b.title
                )
            )
        case 'Title_down':
            return arr.sort((a, b) =>
                (b.title_english ? b.title_english : b.title).localeCompare(
                    a.title_english ? a.title_english : a.title
                )
            )
        case 'Access level_up':
            return arr.sort((a, b) =>
                a.access_level.localeCompare(b.access_level)
            )
        case 'Access level_down':
            return arr.sort((a, b) =>
                b.access_level.localeCompare(a.access_level)
            )
        case 'Access Level_up':
            return arr.sort((a, b) =>
                a.access_level.localeCompare(b.access_level)
            )
        case 'Access Level_down':
            return arr.sort((a, b) =>
                b.access_level.localeCompare(a.access_level)
            )
        case 'Services_up':
            return arr.sort(
                (a, b) => parseFloat(a.services) - parseFloat(b.services)
            )
        case 'Services_down':
            return arr.sort(
                (a, b) => parseFloat(b.services) - parseFloat(a.services)
            )
        case 'Cases_up':
            return arr.sort((a, b) => parseFloat(a.cases) - parseFloat(b.cases))
        case 'Cases_down':
            return arr.sort((a, b) => parseFloat(b.cases) - parseFloat(a.cases))
        case 'News_up':
            return arr.sort((a, b) => parseFloat(a.news) - parseFloat(b.news))
        case 'News_down':
            return arr.sort((a, b) => parseFloat(b.news) - parseFloat(a.news))
        case 'News Ref._up':
            return arr.sort(
                (a, b) => parseFloat(a.news_ref) - parseFloat(b.news_ref)
            )
        case 'News Ref._down':
            return arr.sort(
                (a, b) => parseFloat(b.news_ref) - parseFloat(a.news_ref)
            )
        case 'Publications_up':
            return arr.sort(
                (a, b) =>
                    parseFloat(a.publications) - parseFloat(b.publications)
            )
        case 'Publications_down':
            return arr.sort(
                (a, b) =>
                    parseFloat(b.publications) - parseFloat(a.publications)
            )
        case 'Publications Ref._up':
            return arr.sort(
                (a, b) =>
                    parseFloat(a.publications_ref) -
                    parseFloat(b.publications_ref)
            )
        case 'Publications Ref._down':
            return arr.sort(
                (a, b) =>
                    parseFloat(b.publications_ref) -
                    parseFloat(a.publications_ref)
            )
        case 'Connected lawyers_up':
            return arr.sort(
                (a, b) => parseFloat(a.lawyers) - parseFloat(b.lawyers)
            )
        case 'Connected lawyers_down':
            return arr.sort(
                (a, b) => parseFloat(b.lawyers) - parseFloat(a.lawyers)
            )
        case 'Connected companies_up':
            return arr.sort(
                (a, b) => parseFloat(a.companies) - parseFloat(b.companies)
            )
        case 'Connected companies_down':
            return arr.sort(
                (a, b) => parseFloat(b.companies) - parseFloat(a.companies)
            )
        case 'State_up':
            return arr.sort((a, b) =>
                a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1
            )
        case 'State_down':
            return arr.sort((a, b) =>
                a.is_active === b.is_active ? 0 : a.is_active ? 1 : -1
            )
        case 'Published by_up':
            return arr.sort((a, b) =>
                a.published_by.localeCompare(b.published_by)
            )
        case 'Published by_down':
            return arr.sort((a, b) =>
                b.published_by.localeCompare(a.published_by)
            )
        case 'Date_up':
            return arr.sort((a, b) => a.date.localeCompare(b.date))
        case 'Date_down':
            return arr.sort((a, b) => b.date.localeCompare(a.date))
        case 'Updated on_up':
            return arr.sort((a, b) => a.updated.localeCompare(b.updated))
        case 'Updated on_down':
            return arr.sort((a, b) => b.updated.localeCompare(a.updated))
        case 'Author_up':
            return arr.sort((a, b) => {
                typeof a.authors[0] === 'string' &&
                    typeof b.authors[0] === 'string' &&
                    a.authors[0].localeCompare(b.authors[0])
            })
        case 'Author_down':
            return arr.sort((a, b) => {
                typeof b.authors[0] === 'string' &&
                    typeof a.authors[0] === 'string' &&
                    b.authors[0].localeCompare(a.authors[0])
            })
        case 'Co-author_up':
            return arr.sort((a, b) => {
                return (
                    a.co_authors[0] &&
                    b.co_authors[0] &&
                    (a.co_authors[0].name < b.co_authors[0].name
                        ? -1
                        : a.co_authors[0].name > b.co_authors[0].name
                        ? 1
                        : 0)
                )
            })
        case 'Co-author_up':
            return arr.sort((a, b) => {
                return b.co_authors[0].name < a.co_authors[0].name
                    ? -1
                    : b.co_authors[0].name > a.co_authors[0].name
                    ? 1
                    : 0
            })
        case 'Linked company_up':
            return arr.sort((a, b) => {
                return a.companies[0].name < b.companies[0].name
                    ? -1
                    : a.companies[0].name > b.companies[0].name
                    ? 1
                    : 0
            })
        case 'Linked company_down':
            return arr.sort((a, b) => {
                return b.companies[0].name < a.companies[0].name
                    ? -1
                    : b.companies[0].name > a.companies[0].name
                    ? 1
                    : 0
            })
        case 'Status_up':
            return arr.sort((a, b) => a.status.localeCompare(b.status))
        case 'Status_down':
            return arr.sort((a, b) => b.status.localeCompare(a.status))
        default:
            return arr
    }
}

export function scrollTop() {
    let t
    let top = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
    )
    if (top > 0) {
        window.scrollBy(0, -100)
        t = setTimeout('scrollTop()', 20)
    } else clearTimeout(t)
    return false
}
