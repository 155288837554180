import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loader from "../HelperComponents/Loader/Loader";
import { patchCurrentCategory } from "../../actions/CategoriesAction";

class Presentation extends Component {

    state = {
        loading: false,
        description_title: 'english',
    };

    submitForm = (data) => {
        const { patchCurrentCategory, id } = this.props;
        this.setState({ loading: true });
        patchCurrentCategory( id, data ).then(res => {
            this.setState({ loading: false });
            if(res.payload && res.payload.status && res.payload.status === 200) {
            }
        });
    };

    languageHandler = (state) => {
        this.setState({description_title : state})
    };

    render() {
        const {
            handleSubmit,
            errorCategory,
            submitting,
            pristine,
            valid
        } = this.props;
        const { loading, description_title } = this.state;
        return (
            <div className='card_body'>
                <form className='card_form' onSubmit={handleSubmit(this.submitForm)}>
                    <div className='field_wrapper'>
                        <div className='lang_switcher'>
                            <span className={`item ${description_title === 'english' ? 'active' : ''}`} onClick={() => this.languageHandler('english')}>English</span>
                            <span className={`item ${description_title === 'estonian' ? 'active' : ''}`} onClick={() => this.languageHandler('estonian')}>Estonian</span>
                        </div>
                        <div className='textarea_block'>
                            <span className="back_error">{errorCategory.name}</span>
                            { description_title === 'english' ?
                                <Field
                                    name="description_english"
                                    className="textarea"
                                    component="textarea"
                                    placeholder="Your text in English"
                                /> :
                                <Field
                                    name="description_estonian"
                                    className="textarea"
                                    component="textarea"
                                    placeholder="Your text in Estonian"
                                />
                            }
                        </div>
                    </div>
                    <button className='button' disabled={submitting || pristine || !valid}>
                        {loading ? <Loader size={30} color="white"/> : "Save"}
                    </button>
                </form>
            </div>
        );
    };
}

export const validate = values => {
    const errors = {};
    if (values.description_english && !/^\W*(\w+(\W+|$)){1,250}$/.test(values.description_english)) {
        errors.description_english = 'Maximum 250 words'
    }
    if (values.description_estonian && !/^\W*(\w+(\W+|$)){1,250}$/.test(values.description_estonian)) {
        errors.description_estonian = 'Maximum 250 words'
    }
    return errors
};

Presentation = reduxForm({
    form: 'Presentation',
    enableReinitialize: true,
    validate
})(Presentation);

function mapStateToProps(state, props) {
    return{
        initialValues: {
            description_english: props.current_category.description_english,
            description_estonian: props.current_category.description_estonian
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        patchCurrentCategory
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);