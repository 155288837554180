let BASE_URL, SECOND_URL;

const host = window.location.host;

if (host === 'lawthor.4-com.pro') {
    BASE_URL = 'http://api.lawthor.4-com.pro/';
} else if (host === 'localhost:3000') {
    BASE_URL = 'http://api.lawthor.4-com.pro/';
} else {
    BASE_URL = 'https://api.lawthor.com/';
}

SECOND_URL = '';
export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
