import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import './MultiSelector.scss'

const useStyles = makeStyles(theme => ({
    chip: {
        margin: 2,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, names, theme) {
    return {
        fontWeight:
            names.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export const MultipleSelect = ({ search_array, handleChange, names, label, error }) => {
    const classes = useStyles();
    const theme = useTheme();


    return (
            <FormControl className='multi_selector' >
                <div className='title_wrapper'><span className='text_row'>{label}</span><span className='back_error'>{error ? error : ''}</span></div>
                <Select
                    multiple
                    value={names}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={selected => {
                        return(
                        <div>
                            {selected.map((value) => (
                                <Chip key={value} label={value} className={classes.chip}/>
                            ))}
                        </div>)
                    }}
                    MenuProps={MenuProps}
                >
                    {search_array.map(item => {
                        return (
                            <MenuItem key={item.id} value={item.name ? item.name : item.title_english} style={getStyles((item.name ? item.name : item.title_english), names, theme)}>
                                {item.name ? item.name : item.title_english}
                            </MenuItem>)
                    })}
                </Select>
            </FormControl>
    );
}
