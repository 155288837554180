import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import InputMUI from './../InputMUI'
import linkSvg from './../../../assets/image/link_seo.svg'

import './SeoForm.scss'

import { seoFormInfoManipulates } from '../../../actions/SeoAction'

const SeoForm = ({ title, slug, link, idx }) => {
    const dispatch = useDispatch()
    const [controlLang, setControlLang] = useState('English')
    const tabs = ['English', 'Estonian']
    const [btnLoading, setBtnLoading] = useState(false)

    const schema = yup.object().shape({
        title_english: yup
            .string()
            .test('test-title', 'This field is required', function (value) {
                if (title === 'Main page') {
                    if (value) {
                        return true
                    }
                } else {
                    return true
                }
            }),
        subtitle_english: yup
            .string()
            .test('test-title', 'This field is required', function (value) {
                if (title === 'Main page') {
                    if (value) {
                        return true
                    }
                } else {
                    return true
                }
            }),
        title_estonian: yup
            .string()
            .test('test-title', 'This field is required', function (value) {
                if (title === 'Main page') {
                    if (value) {
                        return true
                    }
                } else {
                    return true
                }
            }),
        subtitle_estonian: yup
            .string()
            .test('test-title', 'This field is required', function (value) {
                if (title === 'Main page') {
                    if (value) {
                        return true
                    }
                } else {
                    return true
                }
            }),
        seo_title_english: yup
            .string()
            .required('This field is required')
            .max(
                70,
                'The Meta-title shoud be between 1 and 70 characters long'
            ),
        seo_description_english: yup
            .string()
            .required('This field is required')
            .min(
                70,
                'The Meta-description shoud be between 70 and 155 characters long'
            )
            .max(
                155,
                'The Meta-description shoud be between 70 and 155 characters long'
            ),
        seo_keywords_english: yup
            .string()
            .required('This field is required')
            .test('test-name', 'Must be exactly 20 words', function (value) {
                var len = value.split(/[^a-zA-Z0-9]/).length - 1
                if (len < 20) {
                    return true
                }
            }),
        seo_title_estonian: yup
            .string()
            .required('This field is required')
            .max(
                70,
                'The Meta-description shoud be between 1 and 70 characters long'
            ),
        seo_description_estonian: yup
            .string()
            .required('This field is required')
            .min(
                70,
                'The Meta-description shoud be between 70 and 155 characters long'
            )
            .max(
                155,
                'The Meta-description shoud be between 70 and 155 characters long'
            ),
        seo_keywords_estonian: yup
            .string()
            .required('This field is required')
            .test('test-name', 'Must be exactly 20 words', function (value) {
                var len = value.split(/[^a-zA-Z0-9]/).length - 1
                if (len < 20) {
                    return true
                }
            }),
    })

    const {
        register,
        control,
        handleSubmit,
        setError,
        setValue,
        formState: { errors, isValid },
    } = useForm({
        defaultValues: {
            title_english: '',
            title_estonian: '',
            subtitle_english: '',
            subtitle_estonian: '',
            seo_title_english: '',
            seo_keywords_english: '',
            seo_description_english: '',
            seo_title_estonian: '',
            seo_keywords_estonian: '',
            seo_description_estonian: '',
        },
        mode: 'onTouched',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        dispatch(seoFormInfoManipulates(slug, 'GET')).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                Object.keys(res.payload.data).forEach(
                    el =>
                        el !== 'id' &&
                        el !== 'slug' &&
                        setValue(el, res.payload.data[el])
                )
            }
        })
    }, [])

    const onSubmit = data => {
        setBtnLoading(true)
        dispatch(seoFormInfoManipulates(slug, 'PATCH', data)).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                setBtnLoading(false)
            }
        })
    }

    return (
        <div key={idx} className={`seo-edit_wrapper`}>
            {title ? (
                <div className='seo-edit_title'>
                    <h3>{title}</h3>
                    {link ? (
                        <a href={link}>
                            <img src={linkSvg} alt='' />
                        </a>
                    ) : null}
                </div>
            ) : null}

            <div className='tabs'>
                {tabs.map((el, idx) => (
                    <button
                        key={idx}
                        onClick={() => setControlLang(el)}
                        className={`${controlLang === el ? 'active' : ''}${
                            Object.keys(errors).some(elem =>
                                elem.toLowerCase().includes(el.toLowerCase())
                            )
                                ? ' error'
                                : ''
                        }`}>
                        {el}
                    </button>
                ))}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div
                    className={`form_wrapper${
                        controlLang === 'English' ? ' visible' : ''
                    }`}>
                    {title === 'Main page' || !title ? (
                        <div className='home_fields'>
                            <div className='seo_field'>
                                <span>Title</span>
                                <Controller
                                    name='title_english'
                                    control={control}
                                    render={({ field }) => (
                                        <InputMUI
                                            type='string'
                                            fullWidth
                                            error={
                                                errors.title_english &&
                                                errors.title_english.message
                                            }
                                            inputProps={field}
                                        />
                                    )}
                                />
                            </div>
                            <div className='seo_field'>
                                <span>Subtitle</span>
                                <Controller
                                    name='subtitle_english'
                                    control={control}
                                    render={({ field }) => (
                                        <InputMUI
                                            type='string'
                                            fullWidth
                                            error={
                                                errors.subtitle_english &&
                                                errors.subtitle_english.message
                                            }
                                            inputProps={field}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className='meta_fields'>
                        <div className='seo_field'>
                            <span>Meta-title</span>
                            <Controller
                                name='seo_title_english'
                                control={control}
                                render={({ field }) => (
                                    <InputMUI
                                        type='string'
                                        fullWidth
                                        error={
                                            errors.seo_title_english &&
                                            errors.seo_title_english.message
                                        }
                                        inputProps={field}
                                    />
                                )}
                            />
                        </div>
                        <div className='seo_field'>
                            <span>Meta-description</span>
                            <Controller
                                name='seo_description_english'
                                control={control}
                                render={({ field }) => (
                                    <InputMUI
                                        type='string'
                                        fullWidth
                                        multiline={true}
                                        rows={'3'}
                                        error={
                                            errors.seo_description_english &&
                                            errors.seo_description_english
                                                .message
                                        }
                                        inputProps={field}
                                    />
                                )}
                            />
                        </div>

                        <div className='seo_field'>
                            <span>Meta-keywords</span>
                            <Controller
                                name='seo_keywords_english'
                                control={control}
                                render={({ field }) => (
                                    <InputMUI
                                        type='string'
                                        fullWidth
                                        error={
                                            errors.seo_keywords_english &&
                                            errors.seo_keywords_english.message
                                        }
                                        inputProps={field}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className={`form_wrapper${
                        controlLang === 'Estonian' ? ' visible' : ''
                    }`}>
                    {title === 'Main page' || !title ? (
                        <div className='home_fields'>
                            <div className='seo_field'>
                                <span>Title</span>
                                <Controller
                                    name='title_estonian'
                                    control={control}
                                    render={({ field }) => (
                                        <InputMUI
                                            type='string'
                                            fullWidth
                                            error={
                                                errors.title_estonian &&
                                                errors.title_estonian.message
                                            }
                                            inputProps={field}
                                        />
                                    )}
                                />
                            </div>
                            <div className='seo_field'>
                                <span>Subtitle</span>
                                <Controller
                                    name='subtitle_estonian'
                                    control={control}
                                    render={({ field }) => (
                                        <InputMUI
                                            type='string'
                                            fullWidth
                                            error={
                                                errors.subtitle_estonian &&
                                                errors.subtitle_estonian.message
                                            }
                                            inputProps={field}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className='meta_fields'>
                        <div className='seo_field'>
                            <span>Meta-title</span>
                            <Controller
                                name='seo_title_estonian'
                                control={control}
                                render={({ field }) => (
                                    <InputMUI
                                        type='string'
                                        fullWidth
                                        error={
                                            errors.seo_title_estonian &&
                                            errors.seo_title_estonian.message
                                        }
                                        inputProps={field}
                                    />
                                )}
                            />
                        </div>
                        <div className='seo_field'>
                            <span>Meta-description</span>
                            <Controller
                                name='seo_description_estonian'
                                control={control}
                                render={({ field }) => (
                                    <InputMUI
                                        type='string'
                                        fullWidth
                                        multiline={true}
                                        rows={'3'}
                                        error={
                                            errors.seo_description_estonian &&
                                            errors.seo_description_estonian
                                                .message
                                        }
                                        inputProps={field}
                                    />
                                )}
                            />
                        </div>

                        <div className='seo_field'>
                            <span>Meta-keywords</span>
                            <Controller
                                name='seo_keywords_estonian'
                                control={control}
                                render={({ field }) => (
                                    <InputMUI
                                        type='string'
                                        fullWidth
                                        error={
                                            errors.seo_keywords_estonian &&
                                            errors.seo_keywords_estonian.message
                                        }
                                        inputProps={field}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div className='seo-save-btn-wrapper'>
                    <button type='submit'>
                        {!btnLoading ? (
                            'Save'
                        ) : (
                            <span className='btn_loader'></span>
                        )}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SeoForm
