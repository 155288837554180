import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import DataBlock from './DataBlock/DataBlock'
import arrow from '../../assets/image/arrow.png'
import {getAllCompaniesList} from "../../actions/companiesAction";
import {getLawyersList} from "../../actions/LawyersAction";
import {getAllCategoriesList} from "../../actions/CategoriesAction";
import {getAllServicesList} from "../../actions/ServicesAction";
import {getAllNewsList} from "../../actions/NewsAction";
import { getAllPublicationsList } from "../../actions/PublicationsAction";
import './Dashboard.scss'



class Dashboard extends Component {

    state = {
        loading : true,
    };

    componentDidMount() {
        this.doRequest();
    };

    doRequest = () => {
        const { getAllCompaniesList,getLawyersList,getAllCategoriesList,getAllServicesList, getAllNewsList, getAllPublicationsList } = this.props;
        Promise.all([
            getAllCompaniesList(),
            getLawyersList(),
            getAllCategoriesList(),
            getAllServicesList(),
            getAllNewsList(),
            getAllPublicationsList(),
        ]).then( res => {
            res.forEach(el => {
                if(el.payload && el.payload.status && el.payload.status === 200) {
                    this.setState({ loading: false });
                }
            })
        });
    };
    render(){
        const {
            companies_noDataArr,
            companies_unactiveArr,
            companies_activeArr,
            lawyers_noDataArr,
            lawyers_unactiveArr,
            lawyers_activeArr,
            categories_noDataArr,
            categories_unactiveArr,
            categories_activeArr,
            services_noDataArr,
            services_unactiveArr,
            services_activeArr,
            news_in_review,
            news_published,
            publications_published,
            publications_in_review
        } = this.props;
        const { loading } = this.state;
        if (loading) return null;
        return (
            <div className='dashboard_wrapper'>
                <div className='top_wrapper'>
                    <span className='title'>Dashboard</span>
                    <button className='report'><img src={arrow} alt='arrow' />Generate Report</button>
                </div>
                <div className='row'>
                    <DataBlock title='Companies'
                               active_title='active'
                               active_amount={companies_activeArr.length}
                               no_data_title='no data'
                               no_data_amount={companies_noDataArr.length}
                               unactive_title='unactive'
                               unactive_amount={companies_unactiveArr.length}
                    />
                    <DataBlock title='Lawyers'
                               active_title='active'
                               active_amount={lawyers_activeArr.length}
                               no_data_title='no data'
                               no_data_amount={lawyers_noDataArr.length}
                               unactive_title='unactive'
                               unactive_amount={lawyers_unactiveArr.length}
                    />
                    <DataBlock title='Categories'
                               active_title='active'
                               active_amount={categories_activeArr.length}
                               no_data_title='no data'
                               no_data_amount={categories_noDataArr.length}
                               unactive_title='unactive'
                               unactive_amount={categories_unactiveArr.length}
                    />
                    <DataBlock title='Services'
                               active_title='active'
                               active_amount={services_activeArr.length}
                               no_data_title='no data'
                               no_data_amount={services_noDataArr.length}
                               unactive_title='unactive'
                               unactive_amount={services_unactiveArr.length}
                    />
                </div>
                <div className='row'>
                    <DataBlock title='News'
                               active_title='published'
                               active_amount={news_published.length}
                               no_data_title='in review'
                               no_data_amount={news_in_review.length}
                    />
                    <DataBlock title='Publications'
                               active_title='published'
                               active_amount={publications_published.length}
                               no_data_title='in review'
                               no_data_amount={publications_in_review.length}
                    />

                </div>

            </div>

        );
    }
}



Dashboard = reduxForm({
    form: 'Dashboard',

})(Dashboard);

function  mapStateToProps(state) {
    return{
        companies_noDataArr: state.companies.noDataArr,
        companies_unactiveArr: state.companies.unactiveArr,
        companies_activeArr: state.companies.activeArr,
        lawyers_noDataArr: state.lawyers.noDataArr,
        lawyers_unactiveArr: state.lawyers.unactiveArr,
        lawyers_activeArr: state.lawyers.activeArr,
        categories_noDataArr: state.categories.noDataArr,
        categories_unactiveArr: state.categories.unactiveArr,
        categories_activeArr: state.categories.activeArr,
        services_noDataArr: state.services.noDataArr,
        services_unactiveArr: state.services.unactiveArr,
        services_activeArr: state.services.activeArr,
        news_in_review: state.news.in_review,
        news_published: state.news.published,
        publications_in_review: state.publications.in_review,
        publications_published: state.publications.published,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllCompaniesList,
        getLawyersList,
        getAllCategoriesList,
        getAllServicesList,
        getAllNewsList,
        getAllPublicationsList
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);