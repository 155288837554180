import * as types from './constants.jsx';

export function changeCompaniesState(id,data) {
    return {
        type: types.CHANGE_COMPANY_STATUS,
        payload: {
            client: 'default',
            request: {
                url: `/api/companies/${id}/active/`,
                method: 'PUT',
                data
            }
        }
    };
}




