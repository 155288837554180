import React from 'react';
import Select from 'react-select';

import './SelectComponent.scss';

const SelectComponent = ({ value, name, options, loading, change, placeholder, isClearable, isSearchable = false, classname}) => (
      <Select
          className={`select_component ${classname}`}
          classNamePrefix="select"
          isDisabled={false}
          isLoading={loading}
          isClearable={!isClearable}
          isSearchable={isSearchable}
          name={name}
          value={value}
          options={options}
          onChange={change}
          loadingMessage={()=>'Loading filters...'}
          placeholder={placeholder}
          components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
      />
);

export default SelectComponent;