import * as types from './constants.jsx';

export function getAllNewsList() {
    return {
        type: types.GET_ALL_NEWS_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/api/news/`,
                method: 'get',
            }
        }
    };
}

export function postNewNews(data) {
    return {
        type: types.POST_NEW_NEWS,
        payload: {
            client: 'default',
            request: {
                url: `/api/news/new/`,
                method: 'post',
                data
            }
        }
    };
}

export function deleteNews(id) {
    return {
        type: types.DELETE_NEWS,
        payload: {
            client: 'default',
            request: {
                url: `/api/news/${id}/delete/`,
                method: 'delete',

            }
        }
    };
}


export function changeNewsState(id, data) {
    return {
        type: types.CHANGE_NEWS_STATUS,
        payload: {
            client: 'default',
            request: {
                url: `/api/news/${id}/status/`,
                method: 'PUT',
                data
            }
        }
    };
}

export function getCurrentNews(id) {
    return {
        type: types.GET_CURRENT_NEWS,
        payload: {
            client: 'default',
            request: {
                url: `/api/news/${id}/`,
                method: 'get'
            }
        }
    };
}

export function patchCurrentNews(id, data) {
    return {
        type: types.PATCH_CURRENT_NEWS,
        payload: {
            client: 'default',
            request: {
                url: `/api/news/${id}/`,
                method: 'patch',
                data
            }
        }
    };
}

export function resetErrors() {
    return {
        type: types.RESET_ERRORS
    };
}

export function getAuthorsForNews() {
    return {
        type: types.GET_AUTHORS_FOR_NEWS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/lawyers/all/`,
                method: 'get',
            }
        }
    };
}

export function getCategoriesForNews() {
    return {
        type: types.GET_CATEGORIES_FOR_NEWS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/categories/`,
                method: 'get',
            }
        }
    };
}

export function getServicesForNews() {
    return {
        type: types.GET_SERVICES_FOR_NEWS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/services/available/`,
                method: 'get',
            }
        }
    };
}

export function getCompaniesForNews() {
    return {
        type: types.GET_COMPANIES_FOR_NEWS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/companies/all/`,
                method: 'get',
            }
        }
    };
}
