import * as types from "../actions/constants";

const INITIAL_STATE = {
    states: [],
    error: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_STATES_SUCCESS :
            return { ...state, states: action.payload.data};
        default:
            return state;
    }
}