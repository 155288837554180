import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import AccessLevelForm from './Froms/AccessLevelForm'
import FlagIcon from '../../FlagIcon'
import Loader from '../HelperComponents/Loader/Loader'
import Presentation from './Presentation'
import EmptyIcon from '../../assets/image/no-image.svg'
import edit from '../../assets/image/edit.svg'
import {
    getCurrentService,
    patchCurrentService,
    resetErrors,
} from '../../actions/ServicesAction'
import TitleDialog from '../ServicesProfile/TitleDialog/TitleDialog'
import DataPublicationsCard from './DataCard/DataPublicationsCard'
import DataNewsCard from './DataCard/DataNewsCard'
import {
    changePublicationsState,
    deletePublications,
} from '../../actions/PublicationsAction'
import { deleteNews, changeNewsState } from '../../actions/NewsAction'
import SeoFormProfile from './../HelperComponents/SeoForm/SeoFormProfile'

import './ServicesProfile.scss'

class ServicesProfile extends Component {
    state = {
        loading: true,
        languagesOpen: false,
        access_level: '',
        companiesOpen: false,
        positionOpen: false,
        inputValue: '',
        changeable_input: false,
        openPopper: false,
        imageUploading: false,
        openChangeDialog: false,
        openDisconnectDialog: false,
        selected_id: null,
        languages_option: null,
        languages_options: [],
        pub_news_cols: [
            'Title',
            'Published by',
            'Date',
            'Updated on',
            'Author',
            'Co-author',
            'Linked company',
            'Status',
            'Controls',
        ],
    }

    componentDidMount() {
        this.doRequest()
    }

    doRequest = () => {
        const {
            getCurrentService,
            match: { params },
        } = this.props
        getCurrentService(params.id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({
                    loading: false,
                    access_level: res.payload.data.access_level,
                })
            }
        })
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    patchService = value => {
        const {
            patchCurrentService,
            match: { params },
        } = this.props
        patchCurrentService(params.id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({ loading: false, languages_option: null })
                this.doRequest()
            }
        })
    }

    imageSelectedHandler = e => {
        e.persist()
        this.setState({ image: e.target.files[0] })
    }

    imageUploadHandler = () => {
        const {
            patchCurrentService,
            match: { params },
            resetErrors,
        } = this.props
        const { image } = this.state
        const fd = new FormData()
        fd.append('pic', image)
        patchCurrentService(params.id, fd).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                resetErrors()
                this.doRequest()
            }
        })
    }

    toggleTitleDialog = () => {
        this.setState(({ titleDialogIsOpen }) => ({
            titleDialogIsOpen: !titleDialogIsOpen,
        }))
    }

    publicationsStateChanger = data => {
        const { changePublicationsState } = this.props
        let id = data.status.split(' ')[1]
        let value = {
            status:
                data.status.split(' ')[0] === 'review' ? 'review' : 'published',
        }
        changePublicationsState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    publicationsDeletion = id => {
        const { deletePublications } = this.props
        deletePublications(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    newsStateChanger = data => {
        const { changeNewsState } = this.props
        let id = data.status.split(' ')[1]
        let value = {
            status:
                data.status.split(' ')[0] === 'review' ? 'review' : 'published',
        }
        changeNewsState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    newsDeletion = id => {
        const { deleteNews } = this.props
        deleteNews(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    render() {
        const {
            access_level,
            loading,
            image,
            imageUploading,
            titleDialogIsOpen,
            pub_news_cols,
        } = this.state
        const { current_service, errServices, history } = this.props

        if (loading) return null
        return (
            <TransitionedBlock>
                <div className='category_profile_wrapper'>
                    <div className='breadcrumbs'>
                        <Link to='/main/dashboard'>Home</Link>
                        &nbsp; / &nbsp;{' '}
                        <Link to='/main/services'>Services</Link> &nbsp; /
                        &nbsp; {current_service.title_english}
                    </div>
                    <div className='connection_row'>
                        <span className='connection_title'>
                            To public page:
                        </span>
                        <input
                            className='connection_input'
                            disabled
                            placeholder={`https://www.lawthor.com/service/${current_service.slug}`}
                        />
                    </div>
                    <span className='category_name'>
                        {current_service.title_english}
                    </span>
                    <div className='blocks_wrapper'>
                        <div className='profile_block service_sm'>
                            <div
                                className={`profile_block_title ${access_level}`}>
                                <span className='level'>{access_level}</span>
                            </div>
                            <div className='card_body'>
                                <img
                                    className='category_banner'
                                    src={
                                        current_service.pic
                                            ? current_service.pic
                                            : EmptyIcon
                                    }
                                    alt='lawyer'
                                />
                            </div>
                            <div className='card_footer'>
                                <span className='pic_err'>
                                    {errServices && errServices.pic}
                                </span>
                                <div className='upload_file'>
                                    <input
                                        name='image'
                                        type='file'
                                        className='upload_input'
                                        onChange={e =>
                                            this.imageSelectedHandler(e)
                                        }
                                    />
                                    <label className='upload_label'>
                                        {image ? image.name : 'Choose file'}
                                    </label>
                                </div>
                                <button
                                    onClick={this.imageUploadHandler}
                                    className='button'
                                    disabled={!image}>
                                    {imageUploading ? (
                                        <Loader size={30} color='white' />
                                    ) : (
                                        'Update picture'
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className='column'>
                            <div className='profile_block access_level'>
                                <div className='profile_block_title'>
                                    Access level
                                </div>
                                <AccessLevelForm
                                    accessLevelSubmit={this.patchService}
                                />
                            </div>
                            <div className='profile_block resume'>
                                <div className='profile_block_title'>
                                    Service description
                                </div>
                                <div className='card_body'>
                                    <span>
                                        Your description can be max 250 words
                                        long.
                                    </span>
                                    <Presentation
                                        current_service={current_service}
                                        errorService={errServices}
                                        id={current_service.id}
                                        doRequest={this.doRequest}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='blocks_wrapper'>
                        <div className='profile_block langs'>
                            <div className='profile_block_title'>
                                Service title
                            </div>
                            <div className='card_body '>
                                <span>
                                    Languages displayed on your public profile
                                </span>
                                <div className='language_box'>
                                    <div>{current_service.title_english}</div>
                                    <FlagIcon
                                        code='gb'
                                        squared={true}
                                        size={'lg'}
                                    />
                                    <div className='control_wrapper'>
                                        <button
                                            onClick={() =>
                                                this.toggleTitleDialog()
                                            }
                                            className='control_btn change'>
                                            <img src={edit} alt='edit icon' />
                                        </button>
                                    </div>
                                </div>
                                {/*OPTIONAL*/}
                                <div className='language_box'>
                                    <div>{current_service.title_estonian}</div>
                                    <FlagIcon
                                        code='ee'
                                        squared={true}
                                        size={'lg'}
                                    />
                                    <div className='control_wrapper'>
                                        <button
                                            onClick={() =>
                                                this.toggleTitleDialog()
                                            }
                                            className='control_btn change'>
                                            <img src={edit} alt='edit icon' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SeoFormProfile
                        title='SEO tags'
                        id={current_service.id}
                        type='services'
                        link=''
                        idx='1'
                        info={current_service}
                    />{' '}
                    <DataPublicationsCard
                        table_cols={pub_news_cols}
                        items={current_service.publications}
                        delete_action={this.publicationsDeletion}
                        stateChanger={this.publicationsStateChanger}
                        card_title='Publications'
                        history={history}
                    />
                    <DataNewsCard
                        table_cols={pub_news_cols}
                        items={current_service.news}
                        delete_action={this.newsDeletion}
                        stateChanger={this.newsStateChanger}
                        card_title='News'
                        history={history}
                    />
                    <TitleDialog
                        initialValues={current_service}
                        toggler={this.toggleTitleDialog}
                        state={titleDialogIsOpen}
                        doRequest={this.doRequest}
                    />
                </div>
            </TransitionedBlock>
        )
    }
}

ServicesProfile = reduxForm({
    form: 'ServicesProfile',
})(ServicesProfile)

function mapStateToProps(state, props) {
    return {
        current_service: state.services.current_service,
        errServices: state.services.error,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCurrentService,
            patchCurrentService,
            resetErrors,
            changePublicationsState,
            deletePublications,
            deleteNews,
            changeNewsState,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesProfile)
