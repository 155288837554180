import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';

import '../LawyerProfile.scss'
import RenderField_mail from "../../HelperComponents/RenderField/renderField_mail";
import RenderField_pass from "../../HelperComponents/RenderField/RenderField_pass";
import {connect} from "react-redux";

class ContactForm extends Component {

    render(){
        const { handleSubmit, contactFormSubmit,submitting, pristine, valid  } = this.props;
        return (
            <form className='card_form' onSubmit={handleSubmit(contactFormSubmit)}>
                <div className='card_body'>
                    <span className='input_title'>Email address</span>
                    <Field
                        name="email"
                        className="add_input"
                        type="text"
                        component={RenderField_mail}
                        placeholder=""
                        autoComplete='off'
                    />
                    <span className='input_title'>Mobile number</span>
                    <Field
                        name="phone_number"
                        className="add_input"
                        type="tel"
                        component={RenderField_pass}
                        placeholder=""
                        autoComplete='off'
                    />
                    <span className='input_title'>Phone number</span>
                    <Field
                        name="phone_number_2"
                        className="add_input"
                        type="text"
                        component={RenderField_pass}
                        placeholder=""
                        autoComplete='off'
                    />
                </div>
                    <div className='card_footer'><button disabled={submitting || pristine || !valid} className='button'>Save</button></div>

            </form>
        );
    }
}


ContactForm = reduxForm({
    form: 'ContactForm',
    enableReinitialize: true,
})(ContactForm);

function  mapStateToProps(state, props) {
    return{
        lawyers: state.lawyers,
        initialValues: {
            phone_number: state.lawyers.lawyer_info.phone_number ,
            phone_number_2: state.lawyers.lawyer_info.phone_number_2 ,
            email: state.lawyers.lawyer_info.email ,
        },

    }
}

export default connect(mapStateToProps, null)(ContactForm);

