import * as types from './constants.jsx';

export function postRegister(data) {
    return {
        type: types.REGISTER,
        payload: {
            client: 'default',
            request: {
                url: `/auth/signup/`,
                method: "post",
                data
            }
        }
    };
}

export function postLogin(data) {
    return {
        type: types.LOGIN,
        payload: {
            client: 'default',
            request: {
                url: `/api/signin/`,
                method: "post",
                data
            }
        }
    };
}

export function postResetPassword(data) {
    return {
        type: types.RESET_PASSWORD,
        payload: {
            client: 'default',
            request: {
                url: `/auth/reset-password/`,
                method: "post",
                data
            }
        }
    };
}

export function postChangePassword(data) {
    return {
        type: types.CHANGE_PASSWORD,
        payload: {
            client: 'default',
            request: {
                url: `/auth/reset-password/approve`,
                method: "post",
                data
            }
        }
    };
}

export function googleAuth(data) {
    return {
        type: types.GOOGLE_AUTH,
        payload: {
            client: 'default',
            request: {
                url: `/google/`,
                method: "post",
                data
            }
        }
    };
}