import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import FormControl from '@material-ui/core/FormControl'
import { reduxForm } from 'redux-form'
import SelectComponent from '../HelperComponents/SelectComponentDefault/SelectComponent'
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import ControlDisconnect from '../HelperComponents/ControlButtons/ControlDisconnect'
import ContactInformation from './ContactInformation'
import LegalInformation from './LegalInformation'
import Presentation from './Presentation'
import DataCard from './DataCard/DataCard'
import Loader from '../HelperComponents/Loader/Loader'
import { getOption } from '../../helpers/functions'

import {
    getCurrentCompany,
    patchCurrentCompany,
    connectLawyerToCompany,
    disconnectLawyerFromCompany,
    getLawyersForCompanies,
    resetErrors,
} from '../../actions/companiesAction'
import { getUsersIds } from '../../actions/UserAction'
import { connectToUser } from '../../actions/connectToUserAction'
import { disconnectCompany } from '../../actions/disconnectCompanyAction'
import { changeLawyerState } from '../../actions/LawyersAction'
import {
    patchServiceForCompany,
    disconnectServiceFromCompany,
} from '../../actions/ServicesAction'
import {
    changePublicationsState,
    deletePublications,
} from '../../actions/PublicationsAction'
import { deleteNews, changeNewsState } from '../../actions/NewsAction'
import plusCircleIcon from '../../assets/image/plus-circle.svg'
import edit from '../../assets/image/edit.svg'
import EmptyIcon from '../../assets/image/no-image.svg'
import AddServiceForm from './AddServiceForm'
import Pagination from '@material-ui/lab/Pagination'
import MyServicesForm from './MyServicesForm'
import DataPublicationsCard from './DataCard/DataPublicationsCard'
import DataNewsCard from './DataCard/DataNewsCard'
import SeoFormProfile from './../HelperComponents/SeoForm/SeoFormProfile'

import './CompanyProfile.scss'

class CompanyProfile extends Component {
    state = {
        loading: true,
        lawyerOpen: false,
        changeable_input: false,
        openPopper: false,
        inputValue: '',
        imageUploading: false,
        lawyer_option: null,
        lawyer_options: [],
        table_cols: [
            'ID',
            'Name, surname',
            'Access level',
            'Position(s)',
            'State',
            'Controls',
        ],
        pub_news_cols: [
            'Title',
            'Published by',
            'Date',
            'Updated on',
            'Author',
            'Co-author',
            'Linked company',
            'Status',
            'Controls',
        ],
        servicesIsOpen: false,
        total_service_pages: 1,
        current_service_page: 1,
        displayed_service: [],
    }

    componentDidMount() {
        const { getUsersIds } = this.props
        getUsersIds().then(() => {
            this.doRequest(true)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.companies.company_info.services !==
            this.props.companies.company_info.services
        ) {
            this.doRequest(true)
        }
        if (prevState.total_service_pages !== this.state.total_service_pages) {
        }
    }

    componentWillUnmount() {
        const { resetErrors } = this.props
        resetErrors()
    }

    doRequest = pagin => {
        const {
            getCurrentCompany,
            match: { params },
        } = this.props
        getCurrentCompany(params.id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({
                    loading: false,
                    inputValue:
                        res.payload.data.owner && res.payload.data.owner.name
                            ? res.payload.data.owner.name
                            : '',
                    displayed_service: res.payload.data.services,
                })
                if (res.payload.data.services.length % 10 === 0) {
                    this.setState({
                        total_services_pages:
                            res.payload.data.services.length / 10,
                    })
                } else {
                    this.setState({
                        total_services_pages: Math.ceil(
                            res.payload.data.services.length / 10
                        ),
                    })
                }
                if (pagin) {
                    this.setState({
                        displayed_service: res.payload.data.services.slice(
                            0,
                            10
                        ),
                    })
                }
            }
        })
    }

    openLawyers = () => {
        const { getLawyersForCompanies, resetErrors } = this.props
        const { lawyer_options } = this.state
        if (lawyer_options.length === 0) {
            getLawyersForCompanies().then(res => {
                if (
                    res.payload &&
                    res.payload.status &&
                    res.payload.status === 200
                ) {
                    let lawyer_options = []
                    res.payload.data.forEach(e =>
                        lawyer_options.push({
                            label: getOption(e.name),
                            value: e.name,
                        })
                    )
                    this.setState(({ lawyerOpen }) => ({
                        lawyerOpen: !lawyerOpen,
                        lawyer_options,
                    }))
                }
            })
        } else {
            this.setState(({ lawyerOpen }) => ({
                lawyerOpen: !lawyerOpen,
                lawyer_option: null,
            }))
        }
        resetErrors()
    }

    handleInput = () => {
        this.setState(({ changeable_input }) => ({
            changeable_input: !changeable_input,
        }))
    }

    changeInput = e => {
        this.setState({ inputValue: e.target.value })
    }

    handlePopper = () => {
        this.setState(({ openPopper }) => ({
            openPopper: !openPopper,
        }))
    }

    onChange = e => {
        this.setState({ inputValue: e.target.value })
    }

    onKeyUp = e => {
        const { openPopper } = this.state
        if (
            e.target.value.length >= 1 &&
            e.target.value.trim() &&
            !openPopper
        ) {
            this.setState({ openPopper: true })
        }
        if (e.target.value.length === 0) {
            this.setState({ openPopper: false })
        }
    }

    selectUser = item => {
        const {
            connectToUser,
            match: { params },
        } = this.props
        const data = { company: Number(params.id) }
        connectToUser(item.id, data).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 202
            ) {
                this.doRequest()
                this.setState({
                    inputValue: item.name,
                    openPopper: false,
                    changeable_input: false,
                })
            }
        })
    }

    handleImageChange = e => {
        e.persist()
        this.setState({ image: e.target.files[0] })
    }

    uploadImage = () => {
        const {
            patchCurrentCompany,
            match: { params },
            resetErrors,
        } = this.props
        const { image } = this.state
        this.setState({ imageUploading: true })
        const formData = new FormData()
        formData.append('pic', image)
        patchCurrentCompany(params.id, formData).then(res => {
            this.setState({ imageUploading: false })
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                resetErrors()
                this.doRequest()
            }
        })
    }

    disconnectCompany = id => {
        const { disconnectCompany } = this.props
        disconnectCompany(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    connectLawyer = () => {
        const {
            connectLawyerToCompany,
            match: { params },
            all_lawyers,
        } = this.props
        const { lawyer_option } = this.state
        const data = {
            company: Number(params.id),
            lawyer: all_lawyers.find(el => el.name === lawyer_option.value).id,
        }
        connectLawyerToCompany(data).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
                this.setState({ lawyerOpen: false })
            }
        })
    }

    disconnectLawyer = id => {
        const {
            disconnectLawyerFromCompany,
            match: { params },
        } = this.props
        const data = { company: Number(params.id), lawyer: id }
        disconnectLawyerFromCompany(data).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    lawyerChangeState = data => {
        const { changeLawyerState } = this.props
        let id = data.is_active.split(',')[1]
        let value = {
            is_active:
                (data.is_active && data.is_active.split(',')[0]) === 'true'
                    ? true
                    : false,
        }
        changeLawyerState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    publicationsStateChanger = data => {
        const { changePublicationsState } = this.props
        let id = data.status.split(' ')[1]
        let value = {
            status:
                data.status.split(' ')[0] === 'review' ? 'review' : 'published',
        }
        changePublicationsState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    publicationsDeletion = id => {
        const { deletePublications } = this.props
        deletePublications(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    newsStateChanger = data => {
        const { changeNewsState } = this.props
        let id = data.status.split(' ')[1]
        let value = {
            status:
                data.status.split(' ')[0] === 'review' ? 'review' : 'published',
        }
        changeNewsState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    newsDeletion = id => {
        const { deleteNews } = this.props
        deleteNews(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    openServices = () => {
        const { servicesIsOpen } = this.state
        const { resetErrors } = this.props
        resetErrors()
        this.setState({ servicesIsOpen: !servicesIsOpen })
    }

    serviceHandler = (service, idx) => {
        this.setState({ chosenService: service, reloading: true, clicked: idx })
        setTimeout(() => {
            this.setState({ reloading: false })
        }, 0)
    }

    disconnectCompanyFromService = id => {
        const { disconnectServiceFromCompany } = this.props
        disconnectServiceFromCompany(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
                this.serviceHandler()
            }
        })
    }

    patchService = data => {
        const { patchServiceForCompany } = this.props
        let obj = {
            description_english: data.description_english
                ? data.description_english
                : '',
            description_estonian: data.description_estonian
                ? data.description_estonian
                : '',
            fixed_rate: data.fixed_rate ? data.fixed_rate : 0,
            hourly_rate: data.hourly_rate ? data.hourly_rate : 0,
        }
        let id = data.id
        patchServiceForCompany(obj, id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
                this.serviceHandler()
            }
        })
    }

    render() {
        const {
            lawyerOpen,
            loading,
            changeable_input,
            inputValue,
            openPopper,
            imageUploading,
            image,
            lawyer_option,
            lawyer_options,
            table_cols,
            servicesIsOpen,
            chosenService,
            reloading,
            clicked,
            total_service_pages,
            displayed_service,
            pub_news_cols,
        } = this.state
        console.log(lawyer_options)
        const {
            companies: { company_info },
            all_users_list,
            errorCompany,
            match: { params },
            history,
            errorService,
        } = this.props

        if (loading) return null
        let users = [...all_users_list]
        users = users.filter(el =>
            el.name.toLowerCase().includes(inputValue.toLowerCase())
        )
        return (
            <TransitionedBlock>
                <div className='company_profile_wrapper'>
                    <div className='breadcrumbs'>
                        <Link to='/main/dashboard'>Home</Link>
                        &nbsp; / &nbsp; Law office &nbsp; / &nbsp;{' '}
                        {company_info.name}
                    </div>
                    <div className='connection_row'>
                        <span className='connection_title'>
                            Connected to user
                        </span>
                        <input
                            autoFocus
                            tabIndex='1'
                            id='connection_input'
                            className='connection_input'
                            disabled={!changeable_input}
                            onChange={this.onChange}
                            onKeyUp={this.onKeyUp}
                            value={inputValue}
                        />
                        {openPopper && inputValue && (
                            <ClickAwayListener onClickAway={this.handlePopper}>
                                <div
                                    className={
                                        company_info.owner &&
                                        company_info.owner.id
                                            ? 'popper_wrapper'
                                            : 'popper_wrapper smaller_popper'
                                    }>
                                    <ul>
                                        {users.length > 0 ? (
                                            users.map(el => (
                                                <li
                                                    onClick={() =>
                                                        this.selectUser(el)
                                                    }
                                                    key={el.id}>
                                                    <div>{el.name}</div>
                                                </li>
                                            ))
                                        ) : (
                                            <li>No users</li>
                                        )}
                                    </ul>
                                </div>
                            </ClickAwayListener>
                        )}
                        <div className='control_wrapper'>
                            <div
                                onClick={this.handleInput}
                                className='control_btn link'>
                                <img src={edit} alt='edit icon' />
                            </div>
                        </div>
                        {company_info.owner && company_info.owner.id && (
                            <ControlDisconnect
                                disconnect_action={this.disconnectCompany}
                                item={'company'}
                                itemId={company_info.id}
                            />
                        )}
                    </div>
                    <div className='connection_row'>
                        <span className='connection_title'>
                            To your public profile:
                        </span>
                        <input
                            className='connection_input'
                            disabled
                            value={`https://www.lawthor.com/company/${company_info.slug}`}
                        />
                    </div>
                    <span className='company_name'>{company_info.name}</span>
                    <div className='blocks_wrapper'>
                        <div className='profile_block'>
                            <div className='profile_block_title'>Your logo</div>
                            <div className='image_wrapper'>
                                <img
                                    className='company_banner'
                                    src={
                                        company_info.pic
                                            ? company_info.pic
                                            : EmptyIcon
                                    }
                                    alt='company'
                                />
                            </div>
                            <div className='card_body'>
                                <div className='upload_file'>
                                    <input
                                        type='file'
                                        className='upload_input'
                                        onChange={e =>
                                            this.handleImageChange(e)
                                        }
                                    />
                                    <label className='upload_label'>
                                        {image ? image.name : 'Choose file'}
                                    </label>
                                    <span className='back_error upload_pic_error'>
                                        {errorCompany.pic &&
                                            errorCompany.pic[0]}
                                    </span>
                                </div>
                                <button
                                    className='button upload_button'
                                    onClick={this.uploadImage}
                                    disabled={!image}>
                                    {imageUploading ? (
                                        <Loader size={30} color='white' />
                                    ) : (
                                        'Update logo'
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className='profile_block'>
                            <div className='profile_block_title'>
                                Presentation
                            </div>
                            <Presentation
                                company_info={company_info}
                                companyId={params.id}
                                errorCompany={errorCompany}
                                doRequest={this.doRequest}
                            />
                        </div>
                    </div>
                    <div className='blocks_wrapper'>
                        <div className='profile_block'>
                            <div className='profile_block_title'>
                                Contact information
                            </div>
                            <ContactInformation
                                company_info={company_info}
                                errorCompany={errorCompany}
                                companyId={params.id}
                                doRequest={this.doRequest}
                            />
                        </div>
                        <div className='profile_block'>
                            <div className='profile_block_title'>
                                Legal information
                            </div>
                            <LegalInformation
                                company_info={company_info}
                                errorCompany={errorCompany}
                                companyId={params.id}
                                doRequest={this.doRequest}
                            />
                        </div>
                    </div>
                    <SeoFormProfile
                        title='SEO tags'
                        id={params.id}
                        type='companies'
                        link=''
                        idx='1'
                        info={company_info}
                    />
                    <div className='profile_block'>
                        <div className='profile_block_title'>
                            Lawyer
                            <button
                                className='add_button'
                                onClick={this.openLawyers}>
                                <div>
                                    <img src={plusCircleIcon} alt='add' />
                                </div>
                                <span>Connect a lawyer</span>
                            </button>
                        </div>
                        <div className='card_body'>
                            {lawyerOpen && (
                                <div className='collapse_form'>
                                    <span className='back_error lawyer_error'>
                                        {errorCompany && errorCompany.detail}
                                    </span>
                                    <FormControl style={{ width: '50%' }}>
                                        <SelectComponent
                                            value={lawyer_option}
                                            options={lawyer_options}
                                            change={e =>
                                                this.changeOption(
                                                    'lawyer_option',
                                                    e
                                                )
                                            }
                                            isClearable='false'
                                            isSearchable={true}
                                            classname='state_selector'
                                            placeholder={'Search from database'}
                                        />
                                    </FormControl>
                                    <button
                                        onClick={this.connectLawyer}
                                        disabled={!lawyer_option}>
                                        Connect lawyer
                                    </button>
                                </div>
                            )}
                            <DataCard
                                table_cols={table_cols}
                                items={company_info.staff}
                                history={history}
                                disconnectLawyer={this.disconnectLawyer}
                                stateChanger={this.lawyerChangeState}
                            />
                        </div>
                    </div>
                    <div className='blocks_wrapper column'>
                        <span className='field_name'>Services</span>
                        <div className='profile_block services'>
                            <div className='collapse_wrapper'>
                                <div className='profile_block_title services'>
                                    <button
                                        className='add_button'
                                        onClick={this.openServices}>
                                        <div>
                                            <img src={plusCircleIcon} />{' '}
                                        </div>
                                        <span>Add a service</span>
                                    </button>
                                </div>
                                {servicesIsOpen ? (
                                    <div className='collapse_form'>
                                        <span className='back_error'>
                                            {errorService && errorService[0]}
                                        </span>
                                        <AddServiceForm
                                            toggler={this.openServices}
                                            companyId={params.id}
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <div className='card_body'>
                                <ul className='card_body_services'>
                                    {displayed_service.map((service, idx) => (
                                        <li
                                            onClick={() =>
                                                this.serviceHandler(
                                                    service,
                                                    idx
                                                )
                                            }
                                            className={`service ${
                                                clicked === idx ? 'blue' : ''
                                            }`}
                                            id={idx}>
                                            {service.title}
                                        </li>
                                    ))}
                                </ul>
                                <div className='card_body_fields'>
                                    {!reloading && (
                                        <MyServicesForm
                                            submitFunc={this.patchService}
                                            disabler={chosenService}
                                            initialValues={chosenService}
                                            deleteFunc={
                                                this
                                                    .disconnectCompanyFromService
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <Pagination
                                style={{ margin: '0 0 20px 20px' }}
                                count={total_service_pages}
                                color='primary'
                                variant='outlined'
                                shape='rounded'
                                onChange={this.handlePagination}
                                defaultPage={1}
                            />
                        </div>
                    </div>
                    <DataPublicationsCard
                        table_cols={pub_news_cols}
                        items={company_info.publications}
                        delete_action={this.publicationsDeletion}
                        stateChanger={this.publicationsStateChanger}
                        card_title='Publications'
                        history={history}
                    />
                    <DataNewsCard
                        table_cols={pub_news_cols}
                        items={company_info.news}
                        delete_action={this.newsDeletion}
                        stateChanger={this.newsStateChanger}
                        card_title='News'
                        history={history}
                    />
                </div>
            </TransitionedBlock>
        )
    }
}

CompanyProfile = reduxForm({
    form: 'CompanyProfile',
})(CompanyProfile)

function mapStateToProps(state, props) {
    return {
        companies: state.companies,
        all_users_list: state.users.users_ids,
        errorCompany: state.companies.error,
        all_lawyers: state.companies.all_lawyers,
        errorService: state.services.error,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getUsersIds,
            getCurrentCompany,
            patchCurrentCompany,
            connectToUser,
            disconnectCompany,
            connectLawyerToCompany,
            disconnectLawyerFromCompany,
            getLawyersForCompanies,
            changeLawyerState,
            resetErrors,
            patchServiceForCompany,
            deleteNews,
            changeNewsState,
            changePublicationsState,
            deletePublications,
            disconnectServiceFromCompany,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile)
