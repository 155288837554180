import React from 'react'
import SeoForm from '../HelperComponents/SeoForm/SeoForm'
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import { Link } from 'react-router-dom'
import './SeoPages.scss'

const SeoPages = () => {
    const forms = [
        {
            title: 'Main page',
            slug: 'home',
            link: 'http://lawthor-ssr.4-com.pro/',
        },
        {
            title: 'About us page',
            slug: 'about-us',
            link: 'http://lawthor-ssr.4-com.pro/about-us',
        },
        {
            title: 'Onboarding page',
            slug: 'onboarding',
            link: 'http://lawthor-ssr.4-com.pro/onboarding',
        },
        {
            title: 'Contacts page',
            slug: 'contact',
            link: 'http://lawthor-ssr.4-com.pro/contact',
        },
        {
            title: 'FAQ page',
            slug: 'faq',
            link: 'http://lawthor-ssr.4-com.pro/faq',
        },
        {
            title: 'Terms and conditions page',
            slug: 'terms-and-conditions',
            link: 'http://lawthor-ssr.4-com.pro/terms-and-conditions',
        },
        {
            title: 'Privacy policy page',
            slug: 'privacy-policy',
            link: 'http://lawthor-ssr.4-com.pro/privacy-policy',
        },
    ]

    return (
        <TransitionedBlock>
            <div className='seo_wrapper'>
                <div className='breadcrumbs'>
                    <Link to='/main/dashboard'>Home</Link>
                    &nbsp; / &nbsp; SEO for static pages
                </div>

                {forms.map(({ title, slug, link }, idx) => (
                    <SeoForm {...{ title, slug, link, idx }} />
                ))}
            </div>
        </TransitionedBlock>
    )
}

export default SeoPages
