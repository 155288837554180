import * as types from './constants.jsx'

export function postNewLawyer(data) {
    return {
        type: types.POST_NEW_LAWYER,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/new/`,
                method: 'post',
                data,
            },
        },
    }
}

export function getLawyersList() {
    return {
        type: types.GET_LAWYERS_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/`,
                method: 'get',
            },
        },
    }
}
export function getCompaniesForLawyers() {
    return {
        type: types.GET_COMPANIES_FOR_LAWYERS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/companies_for_lawyer/`,
                method: 'get',
            },
        },
    }
}
export function changeLawyerState(id, data) {
    return {
        type: types.CHANGE_LAWYER_STATUS,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/${id}/active/`,
                method: 'PUT',
                data,
            },
        },
    }
}
export function getCurrentLawyer(id) {
    return {
        type: types.GET_CURRENT_LAWYER,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/${id}/`,
                method: 'get',
            },
        },
    }
}

export function patchCurrentLawyer(id, data) {
    return {
        type: types.PATCH_CURRENT_LAWYER,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/${id}/`,
                method: 'patch',
                data,
            },
        },
    }
}

export function postLanguage(lawyer_id, language_code) {
    return {
        type: types.POST_LANGUAGE,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/${lawyer_id}/languages/${language_code}/add/`,
                method: 'post',
            },
        },
    }
}

export function deleteLanguage(lawyer_id, language_code) {
    return {
        type: types.DELETE_LANGUAGE,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/${lawyer_id}/languages/${language_code}/delete/`,
                method: 'delete',
            },
        },
    }
}

export function postDisconnectLawyer(id) {
    return {
        type: types.POST_DISCONNECT_LAWYER,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/${id}/disconnect/`,
                method: 'post',
            },
        },
    }
}
export function getAvailableLawyersList() {
    return {
        type: types.GET_AVAILABLE_LAWYERS_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/lawyers/`,
                method: 'get',
            },
        },
    }
}
export function postNewPosition(id, data) {
    return {
        type: types.POST_NEW_POSITION,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/${id}/positions/new/`,
                method: 'post',
                data,
            },
        },
    }
}
export function changePositionState(id, data) {
    return {
        type: types.CHANGE_POSITION_STATUS,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/positions/${id}/active/`,
                method: 'PUT',
                data,
            },
        },
    }
}
export function deletePosition(id) {
    return {
        type: types.DELETE_POSITION,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/delete-position/${id}/`,
                method: 'delete',
            },
        },
    }
}
export function deleteLawyer(id) {
    return {
        type: types.DELETE_LAWYER,
        payload: {
            client: 'default',
            request: {
                url: `/api/lawyers/${id}/delete/`,
                method: 'delete',
            },
        },
    }
}
