import React, { useState } from 'react'
import SeoFormProfile from '../../HelperComponents/SeoForm/SeoFormProfile'
import { ReactComponent as ArrowIcon } from '../../../assets/image/new_arrow.svg'
import './SeoPublication.scss'

export default function SeoPublication({ id, info }) {
    const [open, setOpen] = useState(false)
    const handleClick = () => setOpen(prevState => !prevState)

    return (
        <div className='seo-publication'>
            <button className='seo-publication__btn' type='button' onClick={handleClick}>
                <span className='seo-publication__title'>SEO tags</span>
                <ArrowIcon className={`seo-publication__arrow${open ? ' seo-publication__arrow--open' : ''}`} />
            </button>

            <div className={`seo-publication__content${open ? ' seo-publication__content--open' : ''}`}>
                <SeoFormProfile id={id} type='publications' info={info} />
            </div>
        </div>
    )
}
