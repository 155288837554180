import * as types from "../actions/constants";

const INITIAL_STATE = {
    available_categories: [],
    current_category: [],
    all_categories_list: [],
    activeArr: [],
    unactiveArr: [],
    noDataArr: [],
    error: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_ALL_CATEGORIES_LIST_SUCCESS :
            let activeArr = [];
            let unactiveArr = [];
            let noDataArr = [];
            action.payload.data.forEach(el => {
                el.is_active ? activeArr.push(el) : unactiveArr.push(el);
                !el.has_data && noDataArr.push(el);
            });
            return {
                ...state,
                all_companies_list: action.payload.data,
                activeArr: activeArr,
                unactiveArr: unactiveArr,
                noDataArr: noDataArr
            };
        case types.POST_NEW_CATEGORY_FAIL :
            return {...state, error: action.error.response.data};
        case types.RESET_ERRORS :
            return {...state, error: {}};
        case types.GET_CURRENT_CATEGORY_SUCCESS :
            return {...state, current_category: action.payload.data};
        case types.PATCH_CURRENT_CATEGORY_FAIL :
            return { ...state, error: action.error.response.data };
        case types.PATCH_CURRENT_CATEGORY_SUCCESS :
            return { ...state, error: {}};
        case types.GET_AVAILABLE_CATEGORIES_SUCCESS :
            return {...state, available_categories: action.payload.data};
        case types.CONNECT_CATEGORY_TO_LAWYER_FAIL :
            return { ...state, error: action.error.response.data };
        case types.CONNECT_CATEGORY_TO_LAWYER_SUCCESS :
            return { ...state, error: {}};
        case types.PATCH_CATEGORY_FOR_LAWYER_FAIL :
            return { ...state, error: action.error.response.data };
        case types.PATCH_CATEGORY_FOR_LAWYER_SUCCESS :
            return { ...state, error: {}};
        default:
            return state;
    }
}