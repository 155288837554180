import React from 'react';

import './ControlButtons.scss'
import DialogComponent from "../DialogComponent/DialogComponent";


class ControlDisconnect extends React.Component {

    state = {
      open: false,
    };

     ucFirst = (str) => {
        if (!str) return str;

        return str[0].toUpperCase() + str.slice(1);
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };

    render() {
        const { disconnect_action, itemId, item } = this.props;
        const { open } = this.state;
        return (
            <div className='control_wrapper'>
                <button onClick={this.toggleDialog} className='control_btn disconnect'>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle"
                         className="svg-inline--fa fa-times-circle fa-w-16" role="img"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
                    </svg></button>

                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                    classes='disconnect_dialog'
                >
                    <div className="dialog_header">
                        Are you sure that you want to disconnect this <br/> {item} profile from the user?
                    </div>
                    <div className="dialog_body">
                        This action will send the {item} profile to the "{item} without a user" table in the {this.ucFirst(item)} page.<br/>
                        The deletation of the {item} profile can be done only there.<br/>
                        The alternative is to disable the profile to hide it from public view.
                    </div>
                    <div className="dialog_footer">
                        <button onClick={() => {disconnect_action(itemId); this.toggleDialog()}} >Disconnect</button>
                        <button onClick={this.toggleDialog} >Do nothing</button>
                    </div>
                </DialogComponent>
            </div>
        )
    }
}

export default ControlDisconnect;