import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Field, reduxForm} from 'redux-form';

import { getAvailableLawyersList } from "../../../actions/LawyersAction";

class ConnectLawyerForm extends Component {

    state={
        inputValue: null,
    };

    componentDidMount() {
        this.doRequest();
    }
    doRequest = () => {
        const { getAvailableLawyersList } = this.props;
        this.setState({inputValue: null});
        getAvailableLawyersList()
    };
    handleSelect = (e) => {
        this.setState({inputValue: e.target.value})
    };

    render(){
        const { handleSubmit, connectionToUser, available_lawyers, connection_error } = this.props;
        return (
            <form onSubmit={handleSubmit(connectionToUser)}>
                <div className='collapse_form relative_position' >
                        <span className='back_error' style={{right: '20px'}}>{connection_error.details}</span>
                    <Field onChange={(e) => this.handleSelect(e)} name='lawyer' component='select' className='form_select'>
                        <option value='' disabled defaultValue>Search from database</option>
                        {available_lawyers.map((lawyer, key) => {
                            return(
                                <option key={key} value={lawyer.id}>{lawyer.name}</option>
                            )
                        })}
                    </Field>

                    <button disabled={!this.state.inputValue}>Connect</button>
                </div>
            </form>
        );
    }
}


ConnectLawyerForm = reduxForm({
    form: 'ConnectLawyerForm',
})(ConnectLawyerForm);

function  mapStateToProps(state, props) {
    return{
        available_lawyers: state.available_lawyers.available_lawyers,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAvailableLawyersList,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectLawyerForm);