import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import SelectComponent from '../../HelperComponents/SelectComponentDefault/SelectComponent'
import { bindActionCreators } from 'redux'
import {
    getAvailableCategoriesList,
    connectCategoryToLawyer,
} from '../../../actions/CategoriesAction'
import { getCurrentLawyer } from '../../../actions/LawyersAction'
import { getOption } from '../../../helpers/functions'

class AddCategoryForm extends Component {
    state = {
        category_option: null,
        category_options: [],
        placeholderValue: true,
        description_title: 'english',
    }

    componentDidMount() {
        const { getAvailableCategoriesList, access_level } = this.props
        getAvailableCategoriesList(access_level).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                let category_options = res.payload.data.map(el => ({
                    value: el.id,
                    label: el.title_english,
                }))
                this.setState({ category_options })
            }
        })
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    submitForm = data => {
        const {
            connectCategoryToLawyer,
            getCurrentLawyer,
            lawyerId,
            reset,
            toggler,
        } = this.props
        const { category_option } = this.state
        let obj = { ...data, category: category_option.value, lawyer: lawyerId }
        connectCategoryToLawyer(obj).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 201
            ) {
                reset('AddCategoryForm')
                getCurrentLawyer(lawyerId)
                toggler()
            }
        })
    }

    languageHandler = state => {
        this.setState({ description_title: state })
    }

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props
        const {
            category_option,
            category_options,
            placeholderValue,
            description_title,
        } = this.state

        return (
            <form onSubmit={handleSubmit(this.submitForm)}>
                <div className='block_field'>
                    <span className='field_title'>Search for a category</span>
                    <FormControl style={{ width: '35%' }}>
                        <SelectComponent
                            value={category_option}
                            options={category_options}
                            change={e =>
                                this.changeOption('category_option', e)
                            }
                            isClearable='false'
                            isSearchable={true}
                            classname='state_selector'
                            placeholder={'Search from categories'}
                        />
                    </FormControl>
                </div>
                <div className='collapse_row'>
                    <div className='block_field'>
                        <span className='field_title big'>Fixed rate</span>
                        <Field
                            name='fixed_rate'
                            className='add_input'
                            type='number'
                            component='input'
                            placeholder={
                                placeholderValue ? 'Add rate in euros. ' : ''
                            }
                            autoComplete='off'
                        />
                    </div>
                    <div className='block_field'>
                        <span className='field_title big'>Hourly rate</span>
                        <Field
                            name='hourly_rate'
                            className='add_input'
                            type='number'
                            component='input'
                            placeholder='Add rate in euros.'
                            autoComplete='off'
                        />
                    </div>
                </div>
                <div className='collapse_row'>
                    <div className='card_body'>
                        <span className='field_title'>Description</span>
                        <div className='textarea_block'>
                            <div className='lang_switcher'>
                                <span
                                    className={`item ${
                                        description_title === 'english'
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        this.languageHandler('english')
                                    }>
                                    English
                                </span>
                                <span
                                    className={`item ${
                                        description_title === 'estonian'
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        this.languageHandler('estonian')
                                    }>
                                    Estonian
                                </span>
                            </div>
                            {description_title === 'english' ? (
                                <Field
                                    name='description_english'
                                    className='textarea'
                                    component='textarea'
                                    placeholder='Your text in English'
                                />
                            ) : (
                                <Field
                                    name='description_estonian'
                                    className='textarea'
                                    component='textarea'
                                    placeholder='Your text in Estonian'
                                />
                            )}
                        </div>
                    </div>
                </div>
                <button disabled={!category_option || !valid}>Add</button>
            </form>
        )
    }
}

export const validate = values => {
    const errors = {}
    if (
        values.description_english &&
        !/^\W*(\w+(\W+|$)){1,250}$/.test(values.description_english)
    ) {
        errors.description_english = 'Maximum 250 words'
    }
    if (
        values.description_estonian &&
        !/^\W*(\w+(\W+|$)){1,250}$/.test(values.description_estonian)
    ) {
        errors.description_estonian = 'Maximum 250 words'
    }
    return errors
}

AddCategoryForm = reduxForm({
    form: 'AddCategoryForm',
    validate,
})(AddCategoryForm)

function mapStateToProps(state, props) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAvailableCategoriesList,
            connectCategoryToLawyer,
            getCurrentLawyer,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCategoryForm)
