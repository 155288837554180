import React from 'react';
import App from '../containers/App';
import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import Container from '../containers/Container/Container';
import SignIn from "../components/Authentication/SignIn/SignIn";

export default (
    <App>
        <Switch>
            <Route path='/' exact render={() => !!localStorage.token ? <Redirect to="/main"/> : <Redirect to="/auth"/>}/>
            <Route path='/auth' exact component={SignIn} />
            <Route path='/main' component={Container} />
            <Route render={()=>(<p>Not found</p>)}/>
        </Switch>
    </App>
)
