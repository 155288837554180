import * as types from './constants.jsx';

export function changeUserState(id, data) {
    return {
        type: types.CHANGE_USER_STATUS,
        payload: {
            client: 'default',
            request: {
                url: `/api/users/${id}/active/`,
                method: 'PUT',
                data
            }
        }
    };
}
export function getAllUsersList() {
    return {
        type: types.GET_ALL_USERS_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/api/users/`,
                method: 'get',

            }
        }
    };
}

export function getUsersIds() {
    return {
        type: types.GET_USERS_IDS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/users/`,
                method: 'get'
            }
        }
    };
}

export function deleteUser(id) {
    return {
        type: types.DELETE_USER,
        payload: {
            client: 'default',
            request: {
                url: `/api/users/${id}/delete/`,
                method: 'delete',

            }
        }
    };
}

export function createUser(data) {
    return {
        type: types.POST_CREATE_USER,
        payload: {
            client: 'default',
            request: {
                url: `/api/users/new/`,
                method: 'post',
                data
            }
        }
    };
}

export function patchCurrentUser(id, data) {
    return {
        type: types.PATCH_CURRENT_USER,
        payload: {
            client: 'default',
            request: {
                url: `/api/users/${id}/`,
                method: 'patch',
                data
            }
        }
    };
}

export function getUsersForLawyers() {
    return {
        type: types.GET_USERS_FOR_LAWYERS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/users_for_lawyers/`,
                method: 'get',
            }
        }
    };
}






