import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import FormControl from '@material-ui/core/FormControl'
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import SelectComponent from '../HelperComponents/SelectComponentDefault/SelectComponent'
import SummaryTable from './SummaryTable'
import { getOption } from '../../helpers/functions'
import {
    getCompaniesForLawyers,
    getLawyersList,
    postNewLawyer,
    changeLawyerState,
    deleteLawyer,
} from '../../actions/LawyersAction'
import Data1Card from './DataCard/Data1Card'
import RenderField_pass from '../HelperComponents/RenderField/RenderField_pass'
import RenderField_mail from '../HelperComponents/RenderField/renderField_mail'
import renderField_add from '../HelperComponents/RenderField/RenderField_add_bluePages'
import RenderField_seo_description from '../HelperComponents/RenderField/RenderField_seo_description'
import SeoForm from '../HelperComponents/SeoForm/SeoForm'
import arrow from '../../assets/image/new_arrow.svg'

import './Lawyers.scss'

class Lawyers extends Component {
    state = {
        formOpen: false,
        controlLang: 'English',
        err_message: '',
        clicked: [],
        table_cols: [
            'ID',
            'Name, surname',
            'Access level',
            'Categories',
            'News',
            'News Ref.',
            'Publications',
            'Publications Ref.',
            'Connected companies',
            'State',
            'Controls',
        ],
        loading: true,
        connected_company_option: null,
        connected_company_options: [],
        access_lvl_option: null,
        access_lvl_options: [
            { label: getOption('AT'), value: 'at' },
            { label: getOption('MA'), value: 'ma' },
            { label: getOption('BA'), value: 'ba' },
        ],
    }

    componentDidMount() {
        this.doRequest()
    }

    doRequest = () => {
        const { getLawyersList } = this.props
        getLawyersList().then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({ loading: false })
            }
        })
    }

    openForm = () => {
        const formOpen = this.state.formOpen
        const { getCompaniesForLawyers } = this.props

        getCompaniesForLawyers().then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({
                    loading: false,
                    formOpen: !formOpen,
                    connected_company_options: res.payload.data.map(e => ({
                        label: getOption(e.name),
                        value: e.id,
                    })),
                })
            }
        })
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }
    changeState = data => {
        const { changeLawyerState } = this.props
        let id = data.is_active.split(',')[1]
        let value = {
            is_active: data.is_active.split(',')[0] === 'true' ? true : false,
        }
        changeLawyerState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    deletionLawyer = id => {
        const { deleteLawyer } = this.props
        deleteLawyer(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    submitForm = data => {
        const { connected_company_option, access_lvl_option } = this.state
        const { postNewLawyer, reset } = this.props
        if (access_lvl_option) {
            if (connected_company_option !== null) {
                data.works_in = [connected_company_option.value]
            }
            data.access_level = access_lvl_option.value
            postNewLawyer(data).then(res => {
                if (
                    res.payload &&
                    res.payload.status &&
                    res.payload.status === 201
                ) {
                    this.doRequest()
                    this.setState({
                        formOpen: false,
                        connected_company_option: null,
                        access_lvl_option: null,
                        err_message: null,
                    })
                    reset('Lawyers')
                }
            })
        } else {
            this.setState({ err_message: 'Choose option' })
        }
    }

    tabs = ['English', 'Estonian']

    forms = [
        {
            title: '',
            slug: 'lawyers',
            link: '',
            pageName: 'Lawyers',
        },
        {
            title: '',
            slug: 'all-lawyers',
            link: '',
            pageName: 'All lawyers',
        },
    ]

    changeLang = el => {
        this.setState({ controlLang: el })
    }

    chooseForms = idx => {
        const { clicked: oldClicked } = this.state
        const elIdx = oldClicked.findIndex(el => el === idx)
        this.setState(({ clicked }) => ({
            clicked:
                elIdx === -1
                    ? [...clicked, idx]
                    : [...clicked.slice(0, elIdx), ...clicked.slice(elIdx + 1)],
        }))
    }

    render() {
        const {
            clicked,
            controlLang,
            formOpen,
            table_cols,
            connected_company_option,
            connected_company_options,
            access_lvl_option,
            access_lvl_options,
            err_message,
        } = this.state
        const { noDataArr, unactiveArr, activeArr, handleSubmit, history } =
            this.props

        return (
            <TransitionedBlock>
                <div className='lawyers_wrapper'>
                    <div className='breadcrumbs'>
                        <Link to='/main/dashboard'>Home</Link>
                        &nbsp; / &nbsp; Lawyers
                    </div>
                    <SummaryTable
                        active_lawyers={activeArr.length}
                        no_data_lawyers={noDataArr.length}
                        unactive_lawyers={unactiveArr.length}
                    />

                    <div className='forms_items'>
                        {this.forms.map(
                            ({ title, slug, link, pageName }, idx) => (
                                <div
                                    key={idx}
                                    className={`choose_form ${
                                        clicked.includes(idx) ? 'clicked' : null
                                    }`}>
                                    <div
                                        className='forms_title'
                                        onClick={() => this.chooseForms(idx)}>
                                        <span>
                                            <b>{pageName}</b> titles and
                                            subtitles / SEO tags{' '}
                                        </span>
                                        <img src={arrow} alt='arrow' />
                                    </div>
                                    {clicked.includes(idx) ? (
                                        <SeoForm
                                            {...{ title, slug, link, idx }}
                                        />
                                    ) : null}
                                </div>
                            )
                        )}
                    </div>

                    <button className='green_button' onClick={this.openForm}>
                        Add a lawyer
                    </button>
                    {formOpen ? (
                        <form
                            className='add_form'
                            onSubmit={handleSubmit(this.submitForm)}>
                            <div className='add_form-wrapper'>
                                <div className='create_info'>
                                    <div className='add_form_names'>
                                        <div className='names'>
                                            <span>Name</span>
                                            <Field
                                                name='first_name'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Surname</span>
                                            <Field
                                                name='last_name'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                            />
                                        </div>
                                    </div>
                                    <span>Contact e-mail</span>
                                    <Field
                                        name='email'
                                        className='add_input'
                                        type='text'
                                        component={RenderField_mail}
                                        placeholder=''
                                        autoComplete='off'
                                    />

                                    <span>Mobile number</span>
                                    <Field
                                        name='phone_number'
                                        className='add_input'
                                        type='text'
                                        component={RenderField_pass}
                                        placeholder=''
                                        autoComplete='off'
                                    />
                                    <div className='relative_position'>
                                        <span>Connected company</span>
                                    </div>
                                    <FormControl style={{ width: '100%' }}>
                                        <SelectComponent
                                            value={connected_company_option}
                                            options={connected_company_options}
                                            change={e =>
                                                this.changeOption(
                                                    'connected_company_option',
                                                    e
                                                )
                                            }
                                            isClearable='false'
                                            isSearchable={false}
                                            classname='state_selector'
                                            placeholder={'Choose...'}
                                        />
                                    </FormControl>
                                    <div className='relative_position'>
                                        <span>Access level</span>
                                        <span className='back_error'>
                                            {err_message}
                                        </span>
                                    </div>
                                    <FormControl style={{ width: '100%' }}>
                                        <SelectComponent
                                            value={access_lvl_option}
                                            options={access_lvl_options}
                                            change={e =>
                                                this.changeOption(
                                                    'access_lvl_option',
                                                    e
                                                )
                                            }
                                            isClearable='false'
                                            isSearchable={false}
                                            classname='state_selector'
                                            placeholder={'Choose...'}
                                        />
                                    </FormControl>
                                </div>
                                <div className='add_form_seo'>
                                    <div className='tabs'>
                                        {this.tabs.map((el, idx) => (
                                            <div
                                                key={idx}
                                                onClick={() =>
                                                    this.changeLang(el)
                                                }
                                                className={`control_lang-btn${
                                                    controlLang === el
                                                        ? ' active'
                                                        : ''
                                                }`}>
                                                {el}
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className={`seo_form${
                                            controlLang === 'English'
                                                ? ' visible'
                                                : ''
                                        }`}>
                                        <div className='names'>
                                            <span>Meta-title</span>

                                            <Field
                                                name='seo_title_english'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-description</span>

                                            <Field
                                                name='seo_description_english'
                                                className='add_description'
                                                type='text'
                                                component={
                                                    RenderField_seo_description
                                                }
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-keywords</span>

                                            <Field
                                                name='seo_keywords_english'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className={`seo_form${
                                            controlLang === 'Estonian'
                                                ? ' visible'
                                                : ''
                                        }`}>
                                        <div className='names'>
                                            <span>Meta-title</span>

                                            <Field
                                                name='seo_title_estonian'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-description</span>

                                            <Field
                                                name='seo_description_estonian'
                                                className='add_description'
                                                type='text'
                                                component={
                                                    RenderField_seo_description
                                                }
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-keywords</span>

                                            <Field
                                                name='seo_keywords_estonian'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='add_form-button-wrapper'>
                                <button>Submit form</button>
                            </div>
                        </form>
                    ) : null}

                    <Data1Card
                        table_cols={table_cols}
                        items={unactiveArr}
                        delete_action={this.deletionLawyer}
                        stateChanger={this.changeState}
                        card_title='Unactive'
                        history={history}
                    />
                    <Data1Card
                        table_cols={table_cols}
                        items={noDataArr}
                        stateChanger={this.changeState}
                        card_title='No Data'
                        history={history}
                    />
                    <Data1Card
                        table_cols={table_cols}
                        items={activeArr}
                        stateChanger={this.changeState}
                        card_title='Active'
                        history={history}
                    />
                </div>
            </TransitionedBlock>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Required field'
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)
    ) {
        errors.email = 'Incorrect email'
    }
    if (!values.name) {
        errors.name = 'Required field'
    }
    if (!values.first_name) {
        errors.first_name = 'Required field'
    }
    if (!values.last_name) {
        errors.last_name = 'Required field'
    }
    if (values.zip_code && values.zip_code.length > 5) {
        errors.zip_code = 'Less than 5 symbols'
    }
    return errors
}

Lawyers = reduxForm({
    form: 'Lawyers',
    validate,
})(Lawyers)

function mapStateToProps(state) {
    return {
        companies_for_lawyers: state.lawyers.companies_for_lawyers,
        noDataArr: state.lawyers.noDataArr,
        unactiveArr: state.lawyers.unactiveArr,
        activeArr: state.lawyers.activeArr,
        all_lawyers_list: state.lawyers.all_lawyers_list,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCompaniesForLawyers,
            getLawyersList,
            postNewLawyer,
            changeLawyerState,
            deleteLawyer,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Lawyers)
