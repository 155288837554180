import * as types from "../actions/constants";

const INITIAL_STATE = {
    all_lawyers_list: [],
    activeArr: [],
    unactiveArr: [],
    noDataArr: [],
    companies_for_lawyers: [],
    lawyer_info: {},
    error: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_LAWYERS_LIST_SUCCESS :
            let activeArr = [];
            let unactiveArr = [];
            let noDataArr = [];
            action.payload.data.forEach(el => {
                el.is_active ? activeArr.push(el) : unactiveArr.push(el);
                !el.has_data && noDataArr.push(el);
            });
            return {
                ...state,
                all_lawyers_list: action.payload.data,
                activeArr: activeArr,
                unactiveArr: unactiveArr,
                noDataArr: noDataArr
            };
        case types.GET_CURRENT_LAWYER_SUCCESS :
            return { ...state, lawyer_info: action.payload.data, error: {}};

        case types.GET_COMPANIES_FOR_LAWYERS_SUCCESS :
            return {
                ...state,
                companies_for_lawyers: action.payload.data
            };

        default:
            return state;
    }
}