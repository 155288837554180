import * as types from './constants.jsx';

export function getAllCategoriesList() {
    return {
        type: types.GET_ALL_CATEGORIES_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/api/categories/`,
                method: 'get',
            }
        }
    };
}

export function postNewCategory(data) {
    return {
        type: types.POST_NEW_CATEGORY,
        payload: {
            client: 'default',
            request: {
                url: `/api/categories/new/`,
                method: 'post',
                data
            }
        }
    };
}

export function getCurrentCategory(id) {
    return {
        type: types.GET_CURRENT_CATEGORY,
        payload: {
            client: 'default',
            request: {
                url: `/api/categories/${id}/ `,
                method: 'get'
            }
        }
    };
}

export function patchCurrentCategory(id, data) {
    return {
        type: types.PATCH_CURRENT_CATEGORY,
        payload: {
            client: 'default',
            request: {
                url: `/api/categories/${id}/`,
                method: 'patch',
                data
            }
        }
    };
}
export function deleteCategory(id) {
    return {
        type: types.DELETE_CATEGORY,
        payload: {
            client: 'default',
            request: {
                url: `/api/categories/${id}/delete/`,
                method: 'delete',

            }
        }
    };
}


export function changeCategoryState(id, data) {
    return {
        type: types.CHANGE_CATEGORY_STATUS,
        payload: {
            client: 'default',
            request: {
                url: `/api/categories/${id}/active/`,
                method: 'PUT',
                data
            }
        }
    };
}

export function getAvailableCategoriesList(access_level) {
    return {
        type: types.GET_AVAILABLE_CATEGORIES,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/categories/${access_level}/`,
                method: 'get',

            }
        }
    };
}

export function connectCategoryToLawyer(data) {
    return {
        type: types.CONNECT_CATEGORY_TO_LAWYER,
        payload: {
            client: 'default',
            request: {
                url: `/api/categories/connect/`,
                method: 'post',
                data
            }
        }
    };
}

export function patchCategoryForLawyer(data,id) {
    return {
        type: types.PATCH_CATEGORY_FOR_LAWYER,
        payload: {
            client: 'default',
            request: {
                url: `/api/categories/connect/${id}/`,
                method: 'patch',
                data
            }
        }
    };
}

export function disconnectLawyerFromCategory(id) {
    return {
        type: types.DISCONNECT_LAWYER_FROM_CATEGORY,
        payload: {
            client: 'default',
            request: {
                url: `/api/categories/connect/${id}/delete/`,
                method: 'delete',
            }
        }
    };
}

//
// export function getLawyersForCompanies() {
//     return {
//         type: types.GET_LAWYERS_FOR_COMPANIES,
//         payload: {
//             client: 'default',
//             request: {
//                 url: `/api/extra/lawyers_for_company/`,
//                 method: 'get',
//             }
//         }
//     };
// }
//
export function resetErrors() {
    return {
        type: types.RESET_ERRORS
    };
}