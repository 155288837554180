import * as types from './constants.jsx';

export function getAllCompaniesList() {
    return {
        type: types.GET_ALL_COMPANIES_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/api/companies/`,
                method: 'get',
            }
        }
    };
}

export function postNewCompany(data) {
    return {
        type: types.POST_NEW_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/api/companies/new/`,
                method: 'post',
                data
            }
        }
    };
}

export function getCurrentCompany(id) {
    return {
        type: types.GET_CURRENT_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/api/companies/${id}/`,
                method: 'get'
            }
        }
    };
}

export function patchCurrentCompany(id, data) {
    return {
        type: types.PATCH_CURRENT_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/api/companies/${id}/`,
                method: 'patch',
                data
            }
        }
    };
}

export function connectLawyerToCompany(data) {
    return {
        type: types.CONNECT_LAWYER_TO_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/api/companies/connect-lawyer/`,
                method: 'post',
                data
            }
        }
    };
}

export function disconnectLawyerFromCompany(data) {
    return {
        type: types.DISCONNECT_LAWYER_FROM_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/api/companies/disconnect-lawyer/`,
                method: 'delete',
                data
            }
        }
    };
}

export function getLawyersForCompanies() {
    return {
        type: types.GET_LAWYERS_FOR_COMPANIES,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/lawyers_for_company/`,
                method: 'get',
            }
        }
    };
}

export function resetErrors() {
    return {
        type: types.RESET_ERRORS
    };
}