import React from 'react'
import { NavLink } from 'react-router-dom'

import './Sidebar.scss'

const Sidebar = () => (
    <div className='sidebar_wrapper'>
        <ul className='sidebar'>
            <li className='sidebar_tab lawthor'>
                <NavLink to='/main/dashboard'>
                    <img src='/images/LawThor_logo_blue.png' /> LAWTHOR
                </NavLink>
            </li>
            <li className='sidebar_tab dashboard'>
                <NavLink to='/main/dashboard'>
                    <svg
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='fas'
                        data-icon='tachometer-alt'
                        className='svg-inline--fa fa-tachometer-alt fa-w-18'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 576 512'>
                        <path
                            fill='currentColor'
                            d='M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67l-9.22 27.67c-5.13 3.49-10.97 6.01-17.64 6.01-17.67 0-32-14.33-32-32S270.33 96 288 96zM96 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm48-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm246.77-72.41l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36zm14.66 57.2l15.52-46.55c3.47-1.29 7.13-2.23 11.05-2.23 17.67 0 32 14.33 32 32s-14.33 32-32 32c-11.38-.01-20.89-6.28-26.57-15.22zM480 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z'></path>
                    </svg>
                    Dashboard
                </NavLink>
            </li>
            <li className='sidebar_tab'>
                users
                <NavLink to={`/main/legal-users`}>
                    <svg
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='fas'
                        data-icon='user'
                        className='svg-inline--fa fa-user fa-w-14'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'>
                        <path
                            fill='currentColor'
                            d='M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z'></path>
                    </svg>
                    Legal Users
                </NavLink>
            </li>
            <li className='sidebar_tab'>
                Legal accounts
                <NavLink to={`/main/companies`}>
                    <svg
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='fas'
                        data-icon='building'
                        className='svg-inline--fa fa-building fa-w-14'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'>
                        <path
                            fill='currentColor'
                            d='M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z'></path>
                    </svg>
                    Companies
                </NavLink>
                <NavLink to='/main/lawyers'>
                    <svg
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='fas'
                        data-icon='users'
                        className='svg-inline--fa fa-users fa-w-20'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 640 512'>
                        <path
                            fill='currentColor'
                            d='M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z'></path>
                    </svg>
                    Lawyers
                </NavLink>
            </li>
            <li className='sidebar_tab'>
                Data classification
                <NavLink to='/main/categories'>
                    <svg
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='fas'
                        data-icon='book'
                        className='svg-inline--fa fa-book fa-w-14'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'>
                        <path
                            fill='currentColor'
                            d='M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z'></path>
                    </svg>
                    Categories
                </NavLink>
                <NavLink to='/main/services'>
                    <svg
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='fas'
                        data-icon='book'
                        className='svg-inline--fa fa-book fa-w-14'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'>
                        <path
                            fill='currentColor'
                            d='M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z'></path>
                    </svg>
                    Services
                </NavLink>
            </li>
            <li className='sidebar_tab'>
                Articles
                <NavLink to='/main/news'>
                    <svg
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='fas'
                        data-icon='newspaper'
                        className='svg-inline--fa fa-newspaper fa-w-18'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 576 512'>
                        <path
                            fill='currentColor'
                            d='M552 64H88c-13.255 0-24 10.745-24 24v8H24c-13.255 0-24 10.745-24 24v272c0 30.928 25.072 56 56 56h472c26.51 0 48-21.49 48-48V88c0-13.255-10.745-24-24-24zM56 400a8 8 0 0 1-8-8V144h16v248a8 8 0 0 1-8 8zm236-16H140c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm208 0H348c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm-208-96H140c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm208 0H348c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h152c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12zm0-96H140c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h360c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12z'></path>
                    </svg>
                    News
                </NavLink>
                <NavLink to='/main/publications'>
                    <svg
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='far'
                        data-icon='sticky-note'
                        className='svg-inline--fa fa-sticky-note fa-w-14'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 448 512'>
                        <path
                            fill='currentColor'
                            d='M448 348.106V80c0-26.51-21.49-48-48-48H48C21.49 32 0 53.49 0 80v351.988c0 26.51 21.49 48 48 48h268.118a48 48 0 0 0 33.941-14.059l83.882-83.882A48 48 0 0 0 448 348.106zm-128 80v-76.118h76.118L320 428.106zM400 80v223.988H296c-13.255 0-24 10.745-24 24v104H48V80h352z'></path>
                    </svg>
                    Publications
                </NavLink>
            </li>
            <li className='sidebar_tab'>
                <NavLink to='/main/seo-pages'>
                    <svg
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='far'
                        data-icon='paper-teg'
                        className='svg-inline--fa fa-paper-teg fa-w-14'
                        viewBox='0 0 12 14'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M8.59243 0L11.8332 3.5V13.3056C11.833 13.4898 11.7651 13.6665 11.6444 13.7967C11.5238 13.9269 11.3601 14 11.1896 14H0.810115C0.639897 13.9987 0.476978 13.9252 0.356552 13.7952C0.236127 13.6653 0.167862 13.4894 0.166504 13.3056V0.6944C0.166504 0.3108 0.45493 0 0.810115 0H8.59243ZM9.66641 7L7.37391 4.5248L6.45808 5.516L7.8328 7L6.45873 8.4847L7.37456 9.4752L9.66641 7ZM2.33326 7L4.62512 9.4752L5.5416 8.4847L4.16687 7L5.54095 5.5153L4.62576 4.5248L2.33326 7Z'
                            fill='currentColor'></path>
                    </svg>
                    SEO for static pages
                </NavLink>
            </li>
        </ul>
    </div>
)

export default Sidebar
