import React from 'react';

import change from '../../../assets/image/change.svg'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import {Field, reduxForm} from "redux-form";

import '../../HelperComponents/ControlButtons/ControlButtons.scss'



class ControlChangePosition extends React.Component {

    state = {
        open: false,
    };

    componentDidMount() {
        const { itemStatus } = this.props;
        this.setState({status: itemStatus})
    }

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open,
        }));
    };

    render() {
        const { itemId, stateChanger, handleSubmit, submitting, pristine, valid } = this.props;
        const { open, status } = this.state;
         return (
            <div className='control_wrapper' >
                <button onClick={(e) => {e.preventDefault(); this.toggleDialog()}} className='control_btn change'><img src={change} alt='change icon' /></button>

                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                    classes='delete_dialog'
                >
                    <form onSubmit={handleSubmit(stateChanger)}>
                        <div className="dialog_header">
                            Change Position status
                        </div>

                        <div className='dialog_body' style={{display:'flex', justifyContent:'space-around'}}>
                            <label>
                                <Field  onClick={() => {this.setState({status: true})}}
                                        checked={status}
                                        name="is_active"
                                        component="input"
                                        type="radio"
                                        value={`${[true, itemId]}`}
                                />
                                Active
                            </label>
                            <label>
                                <Field
                                    onClick={() => {this.setState({status: false})}}
                                    checked={status ? false : true}
                                    name="is_active"
                                    component="input"
                                    type="radio"
                                    value={`${[false, itemId]}`}
                                />
                                Disabled
                            </label>
                        </div>

                        <div className="dialog_footer">
                            <button disabled={submitting || pristine || !valid} onClick={() => {this.toggleDialog()}}>Yes</button>
                            <button onClick={(e) => {e.preventDefault(); this.toggleDialog()}} >No</button>
                        </div>
                    </form>
                </DialogComponent>
            </div>
        )
    }
}

ControlChangePosition = reduxForm({
    form: 'ControlChangePosition',

})(ControlChangePosition);

function  mapStateToProps(state) {
    return{

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ControlChangePosition);