import * as types from './constants.jsx';

export function connectToUser(id, data) {
    return {
        type: types.CONNECT_TO_USER,
        payload: {
            client: 'default',
            request: {
                url: `/api/users/${id}/connect/`,
                method: 'post',
                data
            }
        }
    };
}




