import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import RenderField_mail from "../HelperComponents/RenderField/renderField_mail";
import RenderField_pass from "../HelperComponents/RenderField/RenderField_pass";
import Loader from "../HelperComponents/Loader/Loader";

import { patchCurrentCompany } from "../../actions/companiesAction";

class LegalInformation extends Component {

    state = {
        loading: false
    };

    submitForm = (data) => {
        const { patchCurrentCompany, companyId, doRequest } = this.props;
        this.setState({ loading: true });
        patchCurrentCompany(companyId, data).then(res => {
            this.setState({ loading: false });
            if(res.payload && res.payload.status && res.payload.status === 200) {
                doRequest();
            }
        });
    };

    render() {
        const {
            handleSubmit,
            errorCompany,
            submitting,
            pristine,
            valid
        } = this.props;
        const { loading } = this.state;
        return (
            <div className='card_body'>
                <form className='card_form' onSubmit={handleSubmit(this.submitForm)}>
                    <div className="relative_position" style={{margin: "-8px 0 8px"}}>
                        <span className='input_title'>Legal Name:</span>
                        <span className="back_error">{errorCompany.name}</span>
                        <Field
                            name="name"
                            className="add_input"
                            type="text"
                            component={RenderField_mail}
                            placeholder=""
                            autoComplete='off'
                        />
                    </div>
                    <div className="relative_position" style={{marginBottom: "8px"}}>
                        <span className='input_title'>Company registration number:</span>
                        <span className="back_error">{errorCompany.reg_number}</span>
                        <Field
                            name="reg_number"
                            className="add_input"
                            type="text"
                            component={RenderField_pass}
                            placeholder=""
                            autoComplete='off'
                        />
                    </div>
                    <div className="relative_position">
                        <span className='input_title'>KMKR:</span>
                        <span className="back_error">{errorCompany.kmkr}</span>
                        <Field
                            name="kmkr"
                            className="add_input"
                            type="text"
                            component={RenderField_pass}
                            placeholder=""
                            autoComplete='off'
                        />
                    </div>
                    <button className='button' disabled={submitting || pristine || !valid}>
                        {loading ? <Loader size={30} color="white"/> : "Save"}
                    </button>
                </form>
            </div>
        );
    };
}

const validate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Required field'
    }
    if (!values.reg_number) {
        errors.reg_number = 'Required field'
    }
    if (!values.kmkr) {
        errors.kmkr = 'Required field'
    }
    return errors
};

LegalInformation = reduxForm({
    form: 'LegalInformation',
    validate,
    enableReinitialize: true
})(LegalInformation);

function mapStateToProps(state, props) {
    return{
        initialValues: {
            name: props.company_info.name,
            reg_number: props.company_info.reg_number,
            kmkr: props.company_info.kmkr
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        patchCurrentCompany
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LegalInformation);