import * as types from "../actions/constants";

const INITIAL_STATE = {
    all_users_list: [],
    activeArr: [],
    unactiveArr: [],
    users_ids: [],
    users_for_lawyers: [],
    all_users_list_error: {},
    error: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_ALL_USERS_LIST_SUCCESS :
            let activeArr = [];
            let unactiveArr = [];
            action.payload.data.forEach(el => {
                el.is_active ? activeArr.push(el) : unactiveArr.push(el);
            });
            return {
                ...state,
                all_users_list: action.payload.data,
                activeArr: activeArr,
                unactiveArr: unactiveArr,
            };
        case types.GET_USERS_IDS_SUCCESS :
            return { ...state, users_ids: action.payload.data};
        case types.GET_USERS_FOR_LAWYERS_SUCCESS :
            return { ...state, users_for_lawyers: action.payload.data};
        case types.POST_CREATE_USER_FAIL :
            return {
                ...state,
                error: action.error.response.data
            };
        default:
            return state;
    }
}