import * as types from "../actions/constants";

const INITIAL_STATE = {
    available_companies: [],
    error: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_AVAILABLE_COMPANIES_LIST_SUCCESS :
            return {...state, available_companies: action.payload.data};
        default:
            return state;
    }
}