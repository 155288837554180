import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FormControl from '@material-ui/core/FormControl'
import SelectComponent from '../HelperComponents/SelectComponentDefault/SelectComponent'
import { getOption } from '../../helpers/functions'

import RenderField_mail from '../HelperComponents/RenderField/renderField_mail'
import RenderField_pass from '../HelperComponents/RenderField/RenderField_pass'
import Loader from '../HelperComponents/Loader/Loader'
import { patchCurrentCompany } from '../../actions/companiesAction'
import { getStates } from '../../actions/StatesAction'

class ContactInformation extends Component {
    state = {
        loading: false,
        state_option: null,
        state_options: [],
    }
    componentDidMount() {
        const { getStates } = this.props
        getStates().then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                const states_list = res.payload.data
                let state_options = []
                states_list.forEach(e =>
                    state_options.push({
                        label: getOption(e.name),
                        value: e.id,
                    })
                )
                this.setState({
                    state_options,
                    state_option: this.props.company_info.state
                        ? {
                              label: states_list.find(
                                  el => el.id === this.props.company_info.state
                              ).name,
                              value: this.props.company_info.state,
                          }
                        : null,
                })
            }
        })
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    submitForm = data => {
        const { patchCurrentCompany, companyId, doRequest } = this.props
        this.setState({ loading: true })
        patchCurrentCompany(companyId, {
            ...data,
            state: this.state.state_option.value,
        }).then(res => {
            this.setState({ loading: false })
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                doRequest()
            }
        })
    }

    render() {
        const {
            handleSubmit,
            errorCompany,
            submitting,
            pristine,
            valid,
        } = this.props
        const { loading, state_option, state_options } = this.state

        return (
            <div className='card_body'>
                <form
                    className='card_form'
                    onSubmit={handleSubmit(this.submitForm)}>
                    <div className='card_field'>
                        <span className='input_name'>Email</span>
                        <span className='back_error'>{errorCompany.email}</span>
                        <Field
                            name='email'
                            className='add_input'
                            type='email'
                            component={RenderField_mail}
                            placeholder=''
                            autoComplete='off'
                        />
                    </div>
                    <div className='card_field'>
                        <span className='input_name'>Website</span>
                        <span className='back_error'>
                            {errorCompany.website}
                        </span>
                        <Field
                            name='website'
                            className='add_input'
                            type='text'
                            component={RenderField_pass}
                            placeholder=''
                            autoComplete='off'
                        />
                    </div>
                    <div className='card_field'>
                        <span className='input_name'>Phone</span>
                        <span className='back_error'>{errorCompany.phone}</span>
                        <Field
                            name='phone'
                            className='add_input'
                            type='tel'
                            component={RenderField_pass}
                            placeholder=''
                            autoComplete='off'
                        />
                    </div>
                    <div className='card_field'>
                        <span className='input_name'>Address</span>
                        <span className='back_error'>
                            {errorCompany.address}
                        </span>
                        <Field
                            name='address'
                            className='add_input'
                            type='text'
                            component={RenderField_pass}
                            placeholder=''
                            autoComplete='off'
                        />
                    </div>
                    <div className='add_form_city'>
                        <div className='names first'>
                            <span className='input_title'>City</span>
                            <span className='back_error'>
                                {errorCompany.city}
                            </span>
                            <Field
                                name='city'
                                className='add_input'
                                type='text'
                                component={RenderField_pass}
                                placeholder=''
                                autoComplete='off'
                            />
                        </div>
                        <div className='names'>
                            <span className='input_title no_margins'>
                                State
                            </span>
                            <span className='back_error'>
                                {errorCompany.state}
                            </span>
                            {/* <Field
                                name="state"
                                className="add_input"
                                type="text"
                                component={RenderField_pass}
                                placeholder=""
                                autoComplete='off'
                            /> */}
                            <FormControl
                                className='select_wrapper'
                                style={{ width: '100%' }}>
                                <SelectComponent
                                    value={state_option}
                                    options={state_options}
                                    change={e =>
                                        this.changeOption('state_option', e)
                                    }
                                    isClearable='false'
                                    isSearchable={false}
                                    classname='state_selector'
                                    placeholder={'Choose...'}
                                />
                            </FormControl>
                        </div>
                        <div className='names'>
                            <span className='input_title'>Zip</span>
                            <span className='back_error'>
                                {errorCompany.zip_code}
                            </span>
                            <Field
                                name='zip_code'
                                className='add_input'
                                type='text'
                                component={RenderField_pass}
                                placeholder=''
                                autoComplete='off'
                            />
                        </div>
                    </div>
                    <button
                        className='button'
                        onClick={e => !valid && e.preventDefault()}>
                        {loading ? <Loader size={30} color='white' /> : 'Save'}
                    </button>
                </form>
            </div>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Required field'
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)
    ) {
        errors.email = 'Incorrect email'
    }
    if (values.zip_code && values.zip_code.length > 5) {
        errors.zip_code = 'Less than 5 symbols'
    }
    if (
        values.website &&
        !/http(s)?:\/\/.(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/g.test(
            values.website
        )
    ) {
        errors.website = 'Invalid URL'
    }
    return errors
}

ContactInformation = reduxForm({
    form: 'ContactInformation',
    validate,
    enableReinitialize: true,
})(ContactInformation)

function mapStateToProps(state, props) {
    return {
        initialValues: {
            email: props.company_info.email,
            website: props.company_info.website,
            phone: props.company_info.phone,
            address: props.company_info.address,
            city: props.company_info.city,
            state: props.company_info.state,
            zip_code: props.company_info.zip_code,
        },
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            patchCurrentCompany,
            getStates,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation)
