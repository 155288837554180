import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Field, reduxForm} from 'redux-form';

import { getAvailableCompaniesList } from "../../../actions/getAvailableCompaniesAction";
import RenderField_text from "../../HelperComponents/RenderField/RenderField_text";

class CreatePositionForLawyerForm extends Component {

    state={
        inputValue: null,
    };

    componentDidMount() {
        this.setState({inputValue: null});

    }

    handleSelect = (e) => {
        this.setState({inputValue: e.target.value})
    };

    render(){
        const { handleSubmit, connected_companies, postPosition } = this.props;
        return (
            <form onSubmit={handleSubmit(postPosition)}>
                <div className='language_row'>
                    <span className='language_title'>Title English</span>
                    <Field
                        name='name'
                        className='language_input'
                        component={RenderField_text}
                        type='text'
                    />
                </div>
                <Field onChange={(e) => this.handleSelect(e)} name='company' component='select' className='form_select'>
                    <option value='' disabled defaultValue>Search from database</option>
                    {connected_companies.map((company, key) => {
                        if(company.is_active){
                        return(
                            <option value={company.id} key={key}>{company.name}</option>
                        )}else{
                            return null
                        }
                    } )}
                </Field>
                <button disabled={!this.state.inputValue}>Add</button>
            </form>
        );
    }
}

CreatePositionForLawyerForm = reduxForm({
    form: 'CreatePositionForLawyerForm',
})(CreatePositionForLawyerForm);

function  mapStateToProps(state, props) {
    return{
        connected_companies: state.lawyers.lawyer_info.works_in,

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAvailableCompaniesList,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePositionForLawyerForm);