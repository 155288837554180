import * as types from "../actions/constants";

const INITIAL_STATE = {
    user: {},
    error: [],
};

export default function(state = INITIAL_STATE, action) {
    switch(action.type) {
        case types.CERTAIN_PROFILE_SUCCESS:
            return {...state, user: action.payload.data};
        case types.CONNECT_TO_USER_FAIL:
            return {...state, error: action.error.response.data};
        default:
            return state;
    }
}