import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl'
import DialogComponent from '../../HelperComponents/DialogComponent/DialogComponent'
import SelectComponent from '../../HelperComponents/SelectComponentDefault/SelectComponent'
import { getOption, sortValues } from '../../../helpers/functions'

import change from '../../../assets/image/change.svg'
import trash from '../../../assets/image/trash.svg'
import edit from '../../../assets/image/edit.svg'
import arrow_up from '../../../assets/image/arrow_up.svg'
import arrow_down from '../../../assets/image/arrow_down.svg'

import '../../HelperComponents/ControlButtons/ControlButtons.scss'
import './DataPublicationsCard.scss'

class DataNewsCard extends Component {
    state = {
        input_value: '',
        openDeleteDialog: false,
        openChangeDialog: false,
        active_sort: null,
        selected_id: null,
        page: 0,
        nextPage: 1,
        lastPage: 0,
        sort_option: { label: getOption('10'), value: '10' },
        sort_options: [
            { label: getOption('10'), value: '10' },
            { label: getOption('25'), value: '25' },
            { label: getOption('50'), value: '50' },
            { label: getOption('100'), value: '100' },
        ],
    }

    componentDidMount() {
        const { items } = this.props
        const lastPage = Math.ceil(items.length / 10)
        this.setState({
            nextPage: 1,
            lastPage,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { items } = this.props
        const { sort_option } = this.state
        if (
            prevProps.items !== this.props.items ||
            prevState.sort_option !== this.state.sort_option
        ) {
            const lastPage = Math.ceil(items.length / Number(sort_option.value))
            this.setState({
                page: 0,
                nextPage: 1,
                lastPage,
            })
        }
    }

    toggleDialog = (type, el = 0, status) => {
        type === 'delete'
            ? this.setState(({ openDeleteDialog }) => ({
                  openDeleteDialog: !openDeleteDialog,
                  selected_id: el,
              }))
            : this.setState(({ openChangeDialog }) => ({
                  openChangeDialog: !openChangeDialog,
                  selected_id: el,
                  status: status,
              }))
    }

    handleSort = item => {
        const { active_sort } = this.state
        active_sort &&
        active_sort.slice(-2) === 'up' &&
        active_sort.includes(item)
            ? this.setState({ active_sort: `${item}_down` })
            : this.setState({ active_sort: `${item}_up` })
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    onChange = e => {
        let input_value = e.target.value
        this.setState({ input_value })
    }

    moveToNewsPage = id => {
        const { history } = this.props
        history.push(`/main/news-profile/${id}`)
    }

    handlePage = index => {
        if (index === 1) {
            this.setState(({ page, lastPage }) => ({
                page: page + index < lastPage ? page + index : lastPage,
                nextPage:
                    page + index < lastPage ? page + index + 1 : lastPage + 1,
            }))
        } else {
            this.setState(({ page }) => ({
                page: page + index > 0 ? page + index : 0,
                nextPage: page + index > 0 ? page + index + 1 : 1,
            }))
        }
    }

    render() {
        const {
            items,
            card_title,
            table_cols,
            stateChanger,
            delete_action,
            handleSubmit,
            submitting,
            pristine,
            valid,
        } = this.props
        const {
            openDeleteDialog,
            openChangeDialog,
            active_sort,
            selected_id,
            sort_option,
            sort_options,
            input_value,
            page,
            lastPage,
            nextPage,
            status,
        } = this.state
        let array = [...items]
        array = array.filter(
            el =>
                el.title.toLowerCase().includes(input_value.toLowerCase()) ||
                (el.authors ? el.authors : '').some(author =>
                    author.name
                        .toLowerCase()
                        .includes(input_value.toLowerCase())
                ) ||
                (el.co_authors ? el.co_authors : '').some(author =>
                    author.name
                        .toLowerCase()
                        .includes(input_value.toLowerCase())
                ) ||
                el.date.toString().indexOf(input_value.toLowerCase()) !== -1 ||
                el.updated.toString().indexOf(input_value.toLowerCase()) !==
                    -1 ||
                (el.companies ? el.companies : '').some(company =>
                    company.name
                        .toLowerCase()
                        .includes(input_value.toLowerCase())
                )
        )

        sortValues(array, active_sort)

        if (items.length === 0)
            return (
                <div className='data_card_wrapper_table'>
                    <div className={`card_title`}>{card_title}</div>
                    <div className='card_body'>
                        <div className='card_buttons_row'>
                            <Link to='/main/add-news' className='green_button'>
                                Add a news
                            </Link>
                            <Link
                                to='/main/add-news-link'
                                className='green_button'>
                                Link a news
                            </Link>
                            <Link
                                to='/main/add-news-video'
                                className='green_button'>
                                Link a news video
                            </Link>
                        </div>
                    </div>
                </div>
            )
        return (
            <div className='data_card_wrapper_table'>
                <div className={`card_title`}>{card_title}</div>
                <div className='card_body'>
                    <div className='card_buttons_row'>
                        <Link to='/main/add-news' className='green_button'>
                            Add a news
                        </Link>
                        <Link to='/main/add-news-link' className='green_button'>
                            Link a news
                        </Link>
                        <Link
                            to='/main/add-news-video'
                            className='green_button'>
                            Link a news video
                        </Link>
                    </div>
                    <div className='card_select_row'>
                        <span>Show</span>
                        <FormControl style={{ width: '50px' }}>
                            <SelectComponent
                                value={sort_option}
                                options={sort_options}
                                change={e =>
                                    this.changeOption('sort_option', e)
                                }
                                isClearable='false'
                                isSearchable={false}
                                classname='state_selector'
                            />
                        </FormControl>
                        <span>entries</span>
                    </div>
                    <div className='card_search_row'>
                        <span>Search:</span>
                        <div className='search_form'>
                            <input
                                className='search_input'
                                type='text'
                                value={input_value}
                                onChange={this.onChange}
                                autoComplete='off'
                            />
                        </div>
                    </div>
                    <div className='card_table'>
                        <div className='card_items_row row header_row'>
                            {table_cols.map(el => (
                                <div
                                    key={el}
                                    onClick={() => this.handleSort(el)}
                                    className='no_select'>
                                    <div>{el}</div>
                                    {el !== 'Controls' && (
                                        <div className='arrows'>
                                            <img
                                                src={arrow_up}
                                                alt='up'
                                                className={
                                                    active_sort === `${el}_up`
                                                        ? 'active_arrow'
                                                        : ''
                                                }
                                            />
                                            <img
                                                src={arrow_down}
                                                alt='up'
                                                className={
                                                    active_sort === `${el}_down`
                                                        ? 'active_arrow'
                                                        : ''
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        {array.length > 0 ? (
                            array
                                .slice(
                                    Number(sort_option.value * page),
                                    Number(sort_option.value * nextPage)
                                )
                                .map((el, key) => (
                                    <div
                                        className='card_items_row row default_row'
                                        key={key}>
                                        <div className='table_div'>
                                            {el.title}
                                        </div>
                                        <div className='table_div'>
                                            {el.published_by}
                                        </div>
                                        <div className='table_div'>
                                            {el.date}
                                        </div>
                                        <div className='table_div'>
                                            {el.updated}
                                        </div>
                                        <div className='table_div'>
                                            {el.authors.map((author, key) => (
                                                <div key={key}>
                                                    {author.name}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='table_div'>
                                            {el.co_authors.map(
                                                (co_author, key) => (
                                                    <div key={key}>
                                                        {co_author.name}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className='table_div'>
                                            {el.companies.map(
                                                (company, key) => (
                                                    <div key={key}>
                                                        {company.name}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className='table_div'>
                                            <div
                                                className={
                                                    el.status === 'published'
                                                        ? 'table_circle active'
                                                        : 'table_circle'
                                                }
                                            />
                                            {el.status === 'published'
                                                ? 'Published'
                                                : 'In review'}
                                        </div>
                                        <div className='card_controls'>
                                            <div className='control_wrapper'>
                                                <div
                                                    onClick={() =>
                                                        this.moveToNewsPage(
                                                            el.id
                                                        )
                                                    }
                                                    className='control_btn link'>
                                                    <img
                                                        src={edit}
                                                        alt='edit icon'
                                                    />
                                                </div>
                                            </div>
                                            <div className='control_wrapper'>
                                                <button
                                                    className='control_btn change'
                                                    onClick={() =>
                                                        this.toggleDialog(
                                                            'change',
                                                            el.id,
                                                            el.status
                                                        )
                                                    }>
                                                    <img
                                                        src={change}
                                                        alt='change icon'
                                                    />
                                                </button>
                                            </div>
                                            <div className='control_wrapper'>
                                                <button
                                                    className='control_btn trash'
                                                    onClick={() =>
                                                        this.toggleDialog(
                                                            'delete',
                                                            el.id
                                                        )
                                                    }>
                                                    <img
                                                        src={trash}
                                                        alt='trash icon'
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                        ) : (
                            <div
                                className='card_items_row default_row'
                                style={{ height: '38px' }}
                            />
                        )}
                        <div className='card_items_row row footer_row'>
                            {table_cols.map((el, key) => (
                                <div key={`${el}${key}`}>
                                    <div>{el}</div>
                                </div>
                            ))}
                        </div>
                        <div className='pagination_block'>
                            <div className='card_entries_count'>
                                Showing {Number(sort_option.value * page + 1)}{' '}
                                to{' '}
                                {Number(sort_option.value * nextPage) >
                                array.length
                                    ? array.length
                                    : Number(sort_option.value * nextPage)}{' '}
                                of {array.length} entries
                            </div>
                            <div className='no_select'>
                                {page !== 0 && (
                                    <span
                                        className='pagination_arrows'
                                        onClick={() => this.handlePage(-1)}>
                                        Previous{' '}
                                    </span>
                                )}
                                {nextPage !== lastPage &&
                                    array.length >
                                        Number(sort_option.value) && (
                                        <span
                                            className='pagination_arrows'
                                            onClick={() => this.handlePage(1)}>
                                            Next
                                        </span>
                                    )}
                            </div>
                        </div>
                        <DialogComponent
                            open={openChangeDialog}
                            onClose={e => {
                                e.preventDefault()
                                this.toggleDialog('change')
                            }}
                            classes='delete_dialog'>
                            <form onSubmit={handleSubmit(stateChanger)}>
                                <div className='dialog_header'>
                                    Change News status
                                </div>

                                <div
                                    className='dialog_body'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                    }}>
                                    <label>
                                        <Field
                                            onClick={() => {
                                                this.setState({
                                                    status: 'published',
                                                })
                                            }}
                                            checked={status}
                                            name='status'
                                            component='input'
                                            type='radio'
                                            value={`published ${selected_id}`}
                                        />
                                        Published
                                    </label>
                                    <label>
                                        <Field
                                            onClick={() => {
                                                this.setState({
                                                    status: 'review',
                                                })
                                            }}
                                            checked={
                                                status === 'published'
                                                    ? false
                                                    : true
                                            }
                                            name='status'
                                            component='input'
                                            type='radio'
                                            value={`review ${selected_id}`}
                                        />
                                        In review
                                    </label>
                                </div>

                                <div className='dialog_footer'>
                                    <button
                                        disabled={
                                            submitting || pristine || !valid
                                        }
                                        onClick={() => {
                                            this.toggleDialog('change')
                                        }}>
                                        Yes
                                    </button>
                                    <button
                                        onClick={e => {
                                            e.preventDefault()
                                            this.toggleDialog('change')
                                        }}>
                                        No
                                    </button>
                                </div>
                            </form>
                        </DialogComponent>
                        <DialogComponent
                            open={openDeleteDialog}
                            onClose={() => this.toggleDialog('delete')}
                            classes='delete_dialog'>
                            <div className='dialog_header'>
                                Are you sure that you want to delete this <br />{' '}
                                News?
                            </div>
                            <div className='dialog_body'>
                                This action will permanently delete this News
                                and the links attached to it? The alternative is
                                to disable the account then recovery is
                                possible.
                            </div>
                            <div className='dialog_footer'>
                                <button
                                    onClick={() => {
                                        delete_action(selected_id)
                                        this.toggleDialog('delete')
                                    }}>
                                    Yes
                                </button>
                                <button
                                    onClick={() => this.toggleDialog('delete')}>
                                    No
                                </button>
                            </div>
                        </DialogComponent>
                    </div>
                </div>
            </div>
        )
    }
}

DataNewsCard = reduxForm({
    form: 'DataNewsCard',
})(DataNewsCard)

export default DataNewsCard
