import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Field, reduxForm} from 'redux-form';

import { getCompaniesForLawyers } from "../../../actions/LawyersAction";

class ConnectCompanyForm extends Component {
    state={
        inputValue: null,
    };

    componentDidMount() {
        this.doRequest();
    }
    doRequest = () => {
        const { getCompaniesForLawyers } = this.props;
        this.setState({inputValue: null});
        getCompaniesForLawyers()
    };
    handleSelect = (e) => {
        this.setState({inputValue: e.target.value})
    };

    render(){
        const { handleSubmit, companies_for_lawyers, connectionToCompany,connectError } = this.props;
        return (
            <form onSubmit={handleSubmit(connectionToCompany)}>
                <div className='collapse_form'>
                    <span className='back_error'>{connectError.detail}</span>
                    <Field onChange={(e) => this.handleSelect(e)} name='company' component='select' className='form_select'>
                        <option value='' disabled defaultValue>Search from database</option>
                        {companies_for_lawyers.map(company => {
                            return(
                                <option value={company.id}>{company.name}</option>
                            )
                        })}
                    </Field>
                    <button disabled={!this.state.inputValue}>Connect</button>
                </div>
            </form>
        );
    }
}

ConnectCompanyForm = reduxForm({
    form: 'ConnectCompanyForm',
})(ConnectCompanyForm);

function  mapStateToProps(state, props) {
    return{
        companies_for_lawyers: state.lawyers.companies_for_lawyers,
        connectError: state.companies.error,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCompaniesForLawyers,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCompanyForm);