import React, { Component } from 'react';

import './Footer.scss'

class Footer extends Component {
    render(){
        return (
            <div className='footer_wrapper'>
                <span>Copyright © LawThor 2019</span>
            </div>
        );
    }
}

export default Footer;