import * as types from './constants.jsx';

export function disconnectCompany(id) {
    return {
        type: types.DISCONNECT_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/api/companies/${id}/disconnect/`,
                method: 'post',

            }
        }
    };
}




