import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import EmptyIcon from '../../assets/image/no-image.svg'
import {
    postNewNews,
    patchCurrentNews,
    resetErrors,
    getAuthorsForNews,
    getCategoriesForNews,
    getServicesForNews,
    getCompaniesForNews,
} from '../../actions/NewsAction'
import RenderField_pass from '../HelperComponents/RenderField/RenderField_pass'
import { MultipleSelect } from './MultiSelector/MultiSelector'
import './NewsProfile.scss'
import DialogComponent from '../HelperComponents/DialogComponent/DialogComponent'
import { scrollTop } from '../../helpers/functions'

class AddNewsLinkPage extends Component {
    state = {
        loading: false,
        imageUploading: false,
        language: 'english',
        authors_name: [],
        authors_id: [],
        co_authors_id: [],
        co_authors_name: [],
        categories_name: [],
        categories_id: [],
        services_name: [],
        services_id: [],
        companies_name: [],
        companies_id: [],
        content_english: '',
        content_estonian: '',
        dialogOpen: false,
    }

    componentDidMount() {
        this.doRequest()
        scrollTop()
    }

    doRequest = () => {
        const {
            getAuthorsForNews,
            getCategoriesForNews,
            getServicesForNews,
            getCompaniesForNews,
        } = this.props
        getAuthorsForNews()
        getCategoriesForNews()
        getServicesForNews()
        getCompaniesForNews()
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    imageSelectedHandler = e => {
        e.persist()
        this.setState({ image: e.target.files[0] })
    }

    languageHandler = state => {
        this.setState({ language: state })
    }

    handleChangeAuthors = event => {
        const { authors_for_news } = this.props
        this.setState({ authors_name: event.target.value })
        let obj = []
        event.target.value.filter(el =>
            authors_for_news.forEach(element =>
                element.name.includes(el) ? obj.push(element.id) : null
            )
        )
        this.setState({ authors_id: obj })
    }

    handleChangeCoAuthors = event => {
        const { authors_for_news } = this.props
        this.setState({ co_authors_name: event.target.value })
        let obj = []
        event.target.value.filter(el =>
            authors_for_news.forEach(element =>
                element.name.includes(el) ? obj.push(element.id) : null
            )
        )
        this.setState({ co_authors_id: obj })
    }

    handleChangeCategories = event => {
        const { categories_for_news } = this.props
        this.setState({ categories_name: event.target.value })
        let obj = []
        event.target.value.filter(el =>
            categories_for_news.forEach(element =>
                element.title_english.includes(el) ? obj.push(element.id) : null
            )
        )
        this.setState({ categories_id: obj })
    }

    handleChangeServices = event => {
        const { services_for_news } = this.props
        this.setState({ services_name: event.target.value })
        let obj = []
        event.target.value.filter(el =>
            services_for_news.forEach(element =>
                element.title_english.includes(el) ? obj.push(element.id) : null
            )
        )
        this.setState({ services_id: obj })
    }

    handleChangeCompanies = event => {
        const { companies_for_news } = this.props
        this.setState({ companies_name: event.target.value })
        let obj = []
        event.target.value.filter(el =>
            companies_for_news.forEach(element =>
                element.name.includes(el) ? obj.push(element.id) : null
            )
        )
        this.setState({ companies_id: obj })
    }

    toggleDialog = () => {
        this.setState(({ dialogOpen }) => ({
            dialogOpen: !dialogOpen,
        }))
    }

    goToNews = () => {
        const { history } = this.props
        history.push(`/main/news/`)
    }

    submitForm = value => {
        const {
            postNewNews,
            patchCurrentNews,
            history,
            resetErrors,
        } = this.props
        const {
            authors_id,
            co_authors_id,
            categories_id,
            services_id,
            companies_id,
        } = this.state
        const { image } = this.state
        const pic = new FormData()
        pic.append('pic', image)
        let data = {
            authors: authors_id,
            co_authors: co_authors_id,
            categories: categories_id,
            services: services_id,
            companies: companies_id,
            news_type: 'link',
            published_by: Number(localStorage.getItem('user_id')),
            ...value,
        }
        postNewNews(data).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 201
            ) {
                if (image !== null && image !== undefined) {
                    patchCurrentNews(res.payload.data.id, pic).then(res => {
                        if (
                            res.payload &&
                            res.payload.status &&
                            res.payload.status === 200
                        ) {
                            resetErrors()
                            history.push(
                                `/main/news-profile/${res.payload.data.id}`
                            )
                        }
                    })
                } else {
                    resetErrors()
                    history.push(`/main/news-profile/${res.payload.data.id}`)
                }
            }
        })
    }

    render() {
        const {
            loading,
            image,
            language,
            authors_name,
            co_authors_name,
            categories_name,
            services_name,
            companies_name,
            dialogOpen,
        } = this.state
        const {
            errNews,
            authors_for_news,
            categories_for_news,
            services_for_news,
            companies_for_news,
            handleSubmit,
        } = this.props
        if (loading) return null
        return (
            <TransitionedBlock>
                <div className='news_profile_wrapper'>
                    <div className='profile_title'>Create a link to a news</div>
                    <form onSubmit={handleSubmit(this.submitForm)}>
                        <div className='profile_block'>
                            <div className='profile_name'>
                                Linked news details
                            </div>
                            <div style={{ display: 'flex' }}>
                                {/*  BLOCK WITH IMAGE   */}
                                <div className='profile_block picture'>
                                    <div className='card_body'>
                                        <img
                                            className='profile_banner'
                                            src={EmptyIcon}
                                            alt='profile picture'
                                        />
                                    </div>
                                    <div className='card_footer'>
                                        <span className='pic_err'>
                                            {errNews && errNews.pic}
                                        </span>
                                        <div className='upload_file'>
                                            <input
                                                name='image'
                                                type='file'
                                                className='upload_input'
                                                onChange={e =>
                                                    this.imageSelectedHandler(e)
                                                }
                                            />
                                            <label className='upload_label'>
                                                {image
                                                    ? image.name
                                                    : 'Choose file'}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/*          BLOCK WITH INPUTS        */}
                                <div className='title_information_block'>
                                    <span className='text_row'>
                                        News language selection
                                    </span>
                                    <div className='lang_switcher'>
                                        <span
                                            className={`item ${
                                                language === 'english'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                this.languageHandler('english')
                                            }>
                                            English
                                        </span>
                                        <span
                                            className={`item ${
                                                language === 'estonian'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                this.languageHandler('estonian')
                                            }>
                                            Estonian
                                        </span>
                                    </div>
                                    {language === 'english' ? (
                                        <div className='lang_block'>
                                            <span className='text_row'>
                                                Link to news source
                                            </span>
                                            <Field
                                                name='source_link_english'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder='Add link'
                                                autoComplete='off'
                                            />
                                            <div
                                                style={{
                                                    position: 'relative',
                                                }}>
                                                <span className='back_error'>
                                                    {errNews.details}
                                                </span>
                                                <span className='text_row'>
                                                    Title of news (Max 25 words)
                                                </span>
                                            </div>
                                            <Field
                                                name='title_english'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder='Title english'
                                                autoComplete='off'
                                            />
                                        </div>
                                    ) : (
                                        <div className='lang_block'>
                                            <span className='text_row'>
                                                Link to news source
                                            </span>
                                            <Field
                                                name='source_link_estonian'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder='Add link'
                                                autoComplete='off'
                                            />
                                            <div
                                                style={{
                                                    position: 'relative',
                                                }}>
                                                <span className='back_error'>
                                                    {errNews.details}
                                                </span>
                                                <span className='text_row'>
                                                    Title of news (Max 25 words)
                                                </span>
                                            </div>
                                            <Field
                                                name='title_estonian'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder='Title estonian'
                                                autoComplete='off'
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='input_row'>
                                <MultipleSelect
                                    error={errNews.authors}
                                    label='Author(s)'
                                    handleChange={this.handleChangeAuthors}
                                    names={authors_name}
                                    search_array={authors_for_news}
                                />
                                <MultipleSelect
                                    label='Co-author(s)'
                                    handleChange={this.handleChangeCoAuthors}
                                    names={co_authors_name}
                                    search_array={authors_for_news}
                                />
                            </div>
                            <div className='input_row'>
                                <MultipleSelect
                                    label='Categories'
                                    handleChange={this.handleChangeCategories}
                                    names={categories_name}
                                    search_array={categories_for_news}
                                />
                                <MultipleSelect
                                    label='Services'
                                    handleChange={this.handleChangeServices}
                                    names={services_name}
                                    search_array={services_for_news}
                                />
                            </div>
                            <div className='input_row'>
                                <MultipleSelect
                                    label='Companies'
                                    handleChange={this.handleChangeCompanies}
                                    names={companies_name}
                                    search_array={companies_for_news}
                                />
                            </div>
                            <div
                                className='input_row'
                                style={{ flexDirection: 'column' }}>
                                <span className='text_row'>
                                    This text will be shown in the header as
                                    part of the news. You can copy the first
                                    parapgraph or writte an SEO friendly
                                    introduction.
                                </span>
                                {language === 'english' ? (
                                    <Field
                                        name='public_header_english'
                                        component='textarea'
                                        placeholder='Add your description here'
                                        className='textarea'
                                        maxLengh='10'
                                    />
                                ) : (
                                    <Field
                                        name='public_header_estonian'
                                        component='textarea'
                                        placeholder='Add your description here'
                                        className='textarea'
                                        maxLengh='10'
                                    />
                                )}
                            </div>
                            <div
                                className='buttons'
                                style={{ padding: '20px 20px' }}>
                                <button className='green_button'>Update</button>
                                <button
                                    className='red_button'
                                    onClick={e => {
                                        e.preventDefault()
                                        this.toggleDialog()
                                    }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <DialogComponent
                    open={dialogOpen}
                    onClose={this.toggleDialog}
                    classes='delete_dialog'>
                    <div className='dialog_header'>
                        Are you sure that you want to cancel?
                    </div>
                    <div className='dialog_body'>
                        If you chose yes all your modifications will be lost.
                    </div>
                    <div className='dialog_footer'>
                        <button
                            onClick={e => {
                                e.preventDefault()
                                this.goToNews()
                                this.toggleDialog()
                            }}>
                            Yes, back to News page
                        </button>
                        <button
                            onClick={e => {
                                e.preventDefault()
                                this.toggleDialog()
                            }}>
                            No, I want to continue
                        </button>
                    </div>
                </DialogComponent>
            </TransitionedBlock>
        )
    }
}

AddNewsLinkPage = reduxForm({
    form: 'AddNewsLinkPage',
})(AddNewsLinkPage)

function mapStateToProps(state, props) {
    return {
        errNews: state.news.error,
        authors_for_news: state.news.authors_for_news,
        categories_for_news: state.news.categories_for_news,
        services_for_news: state.news.services_for_news,
        companies_for_news: state.news.companies_for_news,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            patchCurrentNews,
            postNewNews,
            resetErrors,
            getAuthorsForNews,
            getCategoriesForNews,
            getServicesForNews,
            getCompaniesForNews,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewsLinkPage)
