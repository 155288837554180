import React from 'react';
import './RenderField.scss';

const RenderField_pass = ({ disabled, input, placeholder, className, type, id, meta: { touched, error, warning }, handleInput, media_error = '' }) => (
    <label className={touched && error ? input.value !=='' ? 'block-input-pass error_border value' : 'error_border block-input-pass' : input.value !=='' ? 'block-input-pass value' : 'block-input-pass'}>
        <input disabled={disabled} {...input} className={className} placeholder={placeholder} id={id} type={type} autoComplete='off' onKeyDown={handleInput} />
         <span className={`error ${media_error}`}>{error}</span>
    </label>
);


export default RenderField_pass;