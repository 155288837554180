import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {  reduxForm } from 'redux-form';


import check_circle from '../../../assets/image/check-circle.svg';
import exclamination from '../../../assets/image/exclamation.svg';
import x_circle from '../../../assets/image/x_circle.svg';

import './DataBlock.scss'


class DataBlock extends Component {

    render(){

        return (

            <div className='data_wrapper'>
                <span className='data_title'>{this.props.title}</span>
                <div className='data_card active' >
                    <div className='card_text'>
                        <span className='card_title'>{this.props.active_title}</span>
                        <span className='card_amount'>{this.props.active_amount}</span>
                    </div>
                    <img src={check_circle} alt='checked'/>
                </div>

                    <div className='data_card no_data' >
                    <div className='card_text'>
                        <span className='card_title'>{this.props.no_data_title}</span>
                        <span className='card_amount'>{this.props.no_data_amount}</span>
                    </div>
                    <img src={exclamination} alt='exclamination' />
                </div>
                {this.props.unactive_title ?
                <div className='data_card unactive' >
                <div className='card_text'>
                    <span className='card_title'>{this.props.unactive_title}</span>
                    <span className='card_amount'>{this.props.unactive_amount}</span>
                </div>
                <img src={x_circle} alt='X ciircle' />
            </div> : null}

            </div>

        );
    }
}



DataBlock = reduxForm({
    form: 'DataBlock',

})(DataBlock);

function  mapStateToProps(state) {
    return{

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DataBlock);