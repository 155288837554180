import * as types from './constants.jsx';

export function getAllPublicationsList() {
    return {
        type: types.GET_ALL_PUBLICATIONS_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/api/publications/`,
                method: 'get',
            }
        }
    };
}

export function postNewPublications(data) {
    return {
        type: types.POST_NEW_PUBLICATIONS,
        payload: {
            client: 'default',
            request: {
                url: `/api/publications/new/`,
                method: 'post',
                data
            }
        }
    };
}

export function deletePublications(id) {
    return {
        type: types.DELETE_PUBLICATIONS,
        payload: {
            client: 'default',
            request: {
                url: `/api/publications/${id}/delete/`,
                method: 'delete',

            }
        }
    };
}


export function changePublicationsState(id, data) {
    return {
        type: types.CHANGE_PUBLICATIONS_STATUS,
        payload: {
            client: 'default',
            request: {
                url: `/api/publications/${id}/status/`,
                method: 'PUT',
                data
            }
        }
    };
}

export function getCurrentPublications(id) {
    return {
        type: types.GET_CURRENT_PUBLICATIONS,
        payload: {
            client: 'default',
            request: {
                url: `/api/publications/${id}/`,
                method: 'get'
            }
        }
    };
}

export function patchCurrentPublications(id, data) {
    return {
        type: types.PATCH_CURRENT_PUBLICATIONS,
        payload: {
            client: 'default',
            request: {
                url: `/api/publications/${id}/`,
                method: 'patch',
                data
            }
        }
    };
}

export function resetErrors() {
    return {
        type: types.RESET_ERRORS
    };
}

export function getAuthorsForPublications() {
    return {
        type: types.GET_AUTHORS_FOR_PUBLICATIONS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/lawyers/all/`,
                method: 'get',
            }
        }
    };
}

export function getCategoriesForPublications() {
    return {
        type: types.GET_CATEGORIES_FOR_PUBLICATIONS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/categories/`,
                method: 'get',
            }
        }
    };
}

export function getServicesForPublications() {
    return {
        type: types.GET_SERVICES_FOR_PUBLICATIONS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/services/available/`,
                method: 'get',
            }
        }
    };
}

export function getCompaniesForPublications() {
    return {
        type: types.GET_COMPANIES_FOR_PUBLICATIONS,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/companies/all/`,
                method: 'get',
            }
        }
    };
}
