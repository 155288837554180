import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { postLogin } from '../../../actions/authActions';

import TransitionedBlock from '../../HelperComponents/TransitionedBlock/TransitionedBlock';
import RenderField_mail from '../../HelperComponents/RenderField/renderField_mail';
import RenderField_pass from '../../HelperComponents/RenderField/RenderField_pass';

import './SignIn.scss'

class SignIn extends Component {

    state = {
        loading: false,
    };

    componentDidMount() {
        const { history } = this.props;
        if (localStorage.token) {
            history.push('/main');
        }
    }

    submitForm = (data) => {
        const { postLogin, history } = this.props;
        this.setState({ loading: true });
        let obj = { ...data};
        setTimeout(() => {
            postLogin(obj).then(res => {
                if(res.payload && res.payload.status && res.payload.status === 200) {
                    localStorage.token = res.payload.data.token;
                    localStorage.user_id = res.payload.data.id;
                    localStorage.user_name = res.payload.data.name;
                    history.push('/main');
                } else {
                    this.setState({loading: false});
                }
            });
        }, 500);
    };

    render(){
        const { handleSubmit, error_auth } = this.props;
        return (
            <TransitionedBlock>
                <div className="container">
                    <div className="image_site"> </div>
                    <div className="form_site">
                        <div className="form_title">Welcome Back!</div>
                        <form className="form_auth" onSubmit={handleSubmit(this.submitForm)}>
                                <Field
                                    name="username"
                                    className="email_input"
                                    type="text"
                                    component={RenderField_mail}
                                    placeholder="Enter Email Address.."
                                    autoComplete='off'
                                />

                                <Field
                                    name="password"
                                    className="email_input"
                                    type="password"
                                    component={RenderField_pass}
                                    placeholder="Password"
                                    autoComplete='off'
                                    active={true}
                                />
                            <button>Login</button>

                            <span className='back_error'>{error_auth.non_field_errors}</span>

                        </form>
                        <hr />
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

export const validate = values => {
    const errors = {};
    if (!values.username) {
        errors.username = 'Required field'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.username)) {
        errors.username = 'Incorrect email'
    }
    if (!values.password) {
        errors.password = 'Required field'
    }
    if (!values.first_name) {
        errors.first_name = 'Required field'
    }
    if (!values.last_name) {
        errors.last_name = 'Required field'
    }
    if (!values.city) {
        errors.city = 'Required field'
    }
    if (!values.state) {
        errors.state = 'Required field'
    }
    if (!values.zip_code) {
        errors.zip_code = 'Required field'
    }
    if (!values.address) {
        errors.address = 'Required field'
    }if (!values.company) {
        errors.company = 'Required field'
    }
    return errors
};


SignIn = reduxForm({
    form: 'SignIn',
    validate
})(SignIn);

function  mapStateToProps(state) {
    return{
        error_auth: state.auth.error_auth,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        postLogin,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);