import * as types from './constants.jsx';

export function certainProfile(id) {
    return {
        type: types.CERTAIN_PROFILE,
        payload: {
            client: 'default',
            request: {
                url: `/api/users/${id}`,
                method: 'get',

            }
        }

    };
}
