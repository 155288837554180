import React from 'react';
import { BallSpinner } from "react-spinners-kit";

const Loader = ({ size, color }) => {
    return (
        <BallSpinner
            size={size}
            color={color}
        />
    )
};

export default Loader;