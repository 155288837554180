import React from 'react';

import trash from '../../../assets/image/trash.svg'
import './ControlButtons.scss'
import DialogComponent from "../DialogComponent/DialogComponent";


class ControlDelete extends React.Component {

    state = {
      open: false,
    };

    toggleDialog = () => {
        this.setState(({open}) => ({
            open: !open
        }));
    };



    render() {
        const { delete_action, itemId, bodyText, item } = this.props;
        const { open } = this.state;
        return (
            <div className='control_wrapper'>
                <button onClick={this.toggleDialog} className='control_btn trash'><img src={trash} alt='trash icon' /></button>

                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                    classes='delete_dialog'
                >
                    <div className="dialog_header">
                        Are you sure that you want to delete this <br/> {item}?
                    </div>
                    <div className="dialog_body">
                        {bodyText}
                    </div>

                        <div className="dialog_footer">
                            <button onClick={() => {delete_action(itemId); this.toggleDialog()}}>Yes</button>
                            <button onClick={this.toggleDialog}>No</button>
                        </div>

                    </DialogComponent>
            </div>
        )
    }
}

export default ControlDelete;