import * as types from "../actions/constants";

const INITIAL_STATE = {
    all_companies_list: [],
    activeArr: [],
    unactiveArr: [],
    noDataArr: [],
    activeWithoutLawyerArr: [],
    all_companies_list_error: {},
    company_info: {},
    error: {},
    all_lawyers: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_ALL_COMPANIES_LIST_SUCCESS :
            let activeArr = [];
            let activeWithoutLawyerArr = [];
            let unactiveArr = [];
            let noDataArr = [];
            action.payload.data.forEach(el => {
                el.is_active ? activeArr.push(el) : unactiveArr.push(el);
                !el.has_data && noDataArr.push(el);
                el.lawyers === 0 && activeWithoutLawyerArr.push(el);
            });
            return {
                ...state,
                all_companies_list: action.payload.data,
                activeArr: activeArr,
                activeWithoutLawyerArr: activeWithoutLawyerArr,
                unactiveArr: unactiveArr,
                noDataArr: noDataArr
            };
        case types.GET_CURRENT_COMPANY_SUCCESS :
            return { ...state, company_info: action.payload.data };
        case types.PATCH_CURRENT_COMPANY_SUCCESS :
            return { ...state, error: {}};
        case types.CONNECT_LAWYER_TO_COMPANY_SUCCESS :
            return { ...state, error: {}};
        case types.GET_LAWYERS_FOR_COMPANIES_SUCCESS :
            return { ...state, all_lawyers: action.payload.data};
        case types.RESET_ERRORS :
            return { ...state, error: {}};

        case types.PATCH_CURRENT_COMPANY_FAIL :
            return { ...state, error: action.error.response.data };
        case types.CONNECT_LAWYER_TO_COMPANY_FAIL :
            return { ...state, error: action.error.response.data };
        case types.POST_NEW_COMPANY_FAIL :
            return {
                ...state,
                error: action.error.response.data
            };
        default:
            return state;
    }
}