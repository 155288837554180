import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import Loader from '../HelperComponents/Loader/Loader'
import EmptyIcon from '../../assets/image/no-image.svg'
import {
    getCurrentPublications,
    patchCurrentPublications,
    resetErrors,
    getAuthorsForPublications,
    getCategoriesForPublications,
    getServicesForPublications,
    getCompaniesForPublications,
} from '../../actions/PublicationsAction'
import RenderField_pass from '../HelperComponents/RenderField/RenderField_pass'
import { MultipleSelect } from './MultiSelector/MultiSelector'
import { N1ED } from '@edsdk/n1ed-react'

import './PublicationsProfile.scss'
import DialogComponent from '../HelperComponents/DialogComponent/DialogComponent'
import { scrollTop } from '../../helpers/functions'
import SeoPublication from './SeoPublication/SeoPublication'

class PublicationsProfile extends Component {
    state = {
        loading: true,
        imageUploading: false,
        language: 'english',
        authors_name: [],
        authors_id: [],
        co_authors_id: [],
        co_authors_name: [],
        categories_name: [],
        categories_id: [],
        services_name: [],
        services_id: [],
        companies_name: [],
        companies_id: [],
        content_english: '',
        content_estonian: '',
        dialogOpen: false,
        errorBack: null,
    }

    componentDidMount() {
        this.doRequest()
        scrollTop()
    }

    componentWillUnmount() {
        document.location.reload()
    }

    doRequest = () => {
        const {
            authors_name,
            co_authors_name,
            categories_name,
            services_name,
            companies_name,
            authors_id,
            co_authors_id,
            categories_id,
            services_id,
            companies_id,
        } = this.state
        const {
            getCurrentPublications,
            match: { params },
            getAuthorsForPublications,
            getCategoriesForPublications,
            getServicesForPublications,
            getCompaniesForPublications,
        } = this.props
        getCurrentPublications(params.id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                res.payload.data.authors_read.map(el =>
                    authors_name.includes(el.name)
                        ? null
                        : authors_name.push(el.name) && authors_id.push(el.id)
                )
                res.payload.data.co_authors_read.map(el =>
                    co_authors_name.includes(el.name)
                        ? null
                        : co_authors_name.push(el.name) &&
                          co_authors_id.push(el.id)
                )
                res.payload.data.categories_read.map(el =>
                    categories_name.includes(el.title_english)
                        ? null
                        : categories_name.push(el.title_english) &&
                          categories_id.push(el.id)
                )
                res.payload.data.services_read.map(el =>
                    services_name.includes(el.title_english)
                        ? null
                        : services_name.push(el.title_english) &&
                          services_id.push(el.id)
                )
                res.payload.data.companies_read.map(el =>
                    companies_name.includes(el.name)
                        ? null
                        : companies_name.push(el.name) &&
                          companies_id.push(el.id)
                )
                this.setState({
                    loading: false,
                    content_english: res.payload.data.text_english,
                    content_estonian: res.payload.data.text_estonian,
                })
            }
        })
        getAuthorsForPublications()
        getCategoriesForPublications()
        getServicesForPublications()
        getCompaniesForPublications()
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    imageSelectedHandler = e => {
        e.persist()
        this.setState({ image: e.target.files[0] })
    }

    imageUploadHandler = () => {
        const {
            patchCurrentPublications,
            match: { params },
            resetErrors,
        } = this.props
        const { image } = this.state
        const fd = new FormData()
        fd.append('pic', image)
        patchCurrentPublications(params.id, fd).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                resetErrors()
                this.doRequest()
                this.setState({ image: '' })
            }
        })
    }

    languageHandler = state => {
        this.setState({ language: state })
    }

    handleChangeAuthors = event => {
        const { authors_for_publications } = this.props
        this.setState({ authors_name: event.target.value })
        let obj = []
        event.target.value.filter(el =>
            authors_for_publications.forEach(element =>
                element.name.includes(el) ? obj.push(element.id) : null
            )
        )
        this.setState({ authors_id: obj })
    }

    handleChangeCoAuthors = event => {
        const { authors_for_publications } = this.props
        this.setState({ co_authors_name: event.target.value })
        let obj = []
        event.target.value.filter(el =>
            authors_for_publications.forEach(element =>
                element.name.includes(el) ? obj.push(element.id) : null
            )
        )
        this.setState({ co_authors_id: obj })
    }

    handleChangeCategories = event => {
        const { categories_for_publications } = this.props
        this.setState({ categories_name: event.target.value })
        let obj = []
        event.target.value.filter(el =>
            categories_for_publications.forEach(element =>
                element.title_english.includes(el) ? obj.push(element.id) : null
            )
        )
        this.setState({ categories_id: obj })
    }

    handleChangeServices = event => {
        const { services_for_publications } = this.props
        this.setState({ services_name: event.target.value })
        let obj = []
        event.target.value.filter(el =>
            services_for_publications.forEach(element =>
                element.title_english.includes(el) ? obj.push(element.id) : null
            )
        )
        this.setState({ services_id: obj })
    }

    handleChangeCompanies = event => {
        const { companies_for_publications } = this.props
        this.setState({ companies_name: event.target.value })
        let obj = []
        event.target.value.filter(el =>
            companies_for_publications.forEach(element =>
                element.name.includes(el) ? obj.push(element.id) : null
            )
        )
        this.setState({ companies_id: obj })
    }

    handleWYSIWYGEng = (content, editor) => {
        this.setState({ content_english: content })
    }

    handleWYSIWYGEst = (content, editor) => {
        this.setState({ content_estonian: content })
    }

    toggleDialog = () => {
        this.setState(({ dialogOpen }) => ({
            dialogOpen: !dialogOpen,
        }))
    }

    reRender = () => {
        const { history } = this.props
        history.push(`/main/publications/`)
    }

    submitForm = value => {
        const {
            patchCurrentPublications,
            match: { params },
            current_publication: { publication_type },
        } = this.props
        const {
            authors_id,
            co_authors_id,
            categories_id,
            services_id,
            companies_id,
            content_english,
            content_estonian,
        } = this.state
        this.setState({ errorBack: null })
        let data = {
            ...value,
            text_english: content_english,
            text_estonian: content_estonian,
            authors: authors_id,
            co_authors: co_authors_id,
            categories: categories_id,
            services: services_id,
            companies: companies_id,
            published_by: Number(localStorage.getItem('user_id')),
        }
        patchCurrentPublications(params.id, data).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            } else {
                if (
                    res.error &&
                    res.error.response &&
                    res.error.response.data &&
                    (res.error.response.data.public_header_english ||
                        res.error.response.data.public_header_estonian)
                ) {
                    this.setState({
                        errorBack: res.error.response.data.public_header_english
                            ? res.error.response.data.public_header_english[0]
                            : res.error.response.data.public_header_estonian[0],
                    })
                }
            }
        })
    }

    render() {
        const {
            loading,
            image,
            imageUploading,
            language,
            authors_name,
            co_authors_name,
            categories_name,
            services_name,
            companies_name,
            content_english,
            content_estonian,
            dialogOpen,
            errorBack,
        } = this.state
        const {
            seo_tags,
            current_publication,
            errPublications,
            authors_for_publications,
            categories_for_publications,
            services_for_publications,
            companies_for_publications,
            handleSubmit,
        } = this.props
        if (loading) return null
        return (
            <TransitionedBlock>
                <div className='news_profile_wrapper'>
                    <div className='breadcrumbs'>
                        <Link to='/main/dashboard'>Home</Link>
                        &nbsp; / &nbsp;{' '}
                        <Link to='/main/publications'>Publication</Link> &nbsp;
                        / &nbsp; {current_publication.title_english}
                    </div>

                    <div className='connection_row'>
                        <span className='connection_title'>
                            To public page:
                        </span>
                        <input
                            className='connection_input'
                            disabled
                            placeholder={`https://www.lawthor.com/publications/${current_publication.slug}`}
                        />
                    </div>

                    <SeoPublication id={current_publication.id} info={seo_tags} />

                    <form onSubmit={handleSubmit(this.submitForm)}>
                        <div className='profile_block'>
                            <div style={{ display: 'flex' }}>
                                {/*  BLOCK WITH IMAGE   */}
                                <div className='profile_block picture'>
                                    <div className='card_body'>
                                        <img
                                            className='profile_banner'
                                            src={
                                                current_publication.pic
                                                    ? current_publication.pic
                                                    : EmptyIcon
                                            }
                                            alt='profile picture'
                                        />
                                    </div>
                                    <div className='card_footer'>
                                        <span className='pic_err'>
                                            {errPublications &&
                                                errPublications.pic}
                                        </span>
                                        <div className='upload_file'>
                                            <input
                                                name='image'
                                                type='file'
                                                className='upload_input'
                                                onChange={e =>
                                                    this.imageSelectedHandler(e)
                                                }
                                            />
                                            <label className='upload_label'>
                                                {image
                                                    ? image.name
                                                    : 'Choose file'}
                                            </label>
                                        </div>
                                        <button
                                            onClick={e => {
                                                e.preventDefault()
                                                this.imageUploadHandler()
                                            }}
                                            className='button'
                                            disabled={!image}>
                                            {imageUploading ? (
                                                <Loader
                                                    size={30}
                                                    color='white'
                                                />
                                            ) : (
                                                'Update picture'
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {/*          BLOCK WITH INPUTS        */}
                                <div className='title_information_block'>
                                    <span className='text_row'>
                                        Publications language selection
                                    </span>
                                    <div className='lang_switcher'>
                                        <span
                                            className={`item ${
                                                language === 'english'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                this.languageHandler('english')
                                            }>
                                            English
                                        </span>
                                        <span
                                            className={`item ${
                                                language === 'estonian'
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() =>
                                                this.languageHandler('estonian')
                                            }>
                                            Estonian
                                        </span>
                                    </div>
                                    {language === 'english' ? (
                                        <div className='lang_block'>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                }}>
                                                <span className='back_error'>
                                                    {errPublications.details}
                                                </span>
                                                <span className='text_row'>
                                                    Title of publication (Max 25
                                                    words)
                                                </span>
                                            </div>
                                            <Field
                                                name='title_english'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder='Title english'
                                                autoComplete='off'
                                            />
                                            {current_publication.publication_type ===
                                                'link' && (
                                                <>
                                                    <span className='text_row'>
                                                        Link
                                                    </span>
                                                    <Field
                                                        name='source_link_english'
                                                        className='add_input'
                                                        type='text'
                                                        component={
                                                            RenderField_pass
                                                        }
                                                        placeholder='Link'
                                                        autoComplete='off'
                                                    />
                                                </>
                                            )}

                                            {'video' && (
                                                <>
                                                    <span className='text_row'>
                                                        Video link
                                                    </span>
                                                    <Field
                                                        name='video_link_english'
                                                        className='add_input'
                                                        type='text'
                                                        component={
                                                            RenderField_pass
                                                        }
                                                        placeholder='Video link'
                                                        autoComplete='off'
                                                    />
                                                </>
                                            )}

                                            <span className='text_row'>
                                                Published
                                            </span>
                                            <Field
                                                name='date_created'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder='Date created'
                                                autoComplete='off'
                                                disabled={true}
                                            />
                                            <span className='text_row'>
                                                Updated
                                            </span>
                                            <Field
                                                name='date_updated'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder='Date updated'
                                                autoComplete='off'
                                                disabled={true}
                                            />
                                        </div>
                                    ) : (
                                        <div className='lang_block'>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                }}>
                                                <span className='back_error'>
                                                    {errPublications.details}
                                                </span>
                                                <span className='text_row'>
                                                    Title of publication (Max 25
                                                    words)
                                                </span>
                                            </div>
                                            <Field
                                                name='title_estonian'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder='Title estonian'
                                                autoComplete='off'
                                            />
                                            {current_publication.publication_type ===
                                                'link' && (
                                                <>
                                                    <span className='text_row'>
                                                        Link
                                                    </span>
                                                    <Field
                                                        name='source_link_estonian'
                                                        className='add_input'
                                                        type='text'
                                                        component={
                                                            RenderField_pass
                                                        }
                                                        placeholder='Link'
                                                        autoComplete='off'
                                                    />
                                                </>
                                            )}

                                            {'video' && (
                                                <>
                                                    <span className='text_row'>
                                                        Video link
                                                    </span>
                                                    <Field
                                                        name='video_link_estonian'
                                                        className='add_input'
                                                        type='text'
                                                        component={
                                                            RenderField_pass
                                                        }
                                                        placeholder='Video link'
                                                        autoComplete='off'
                                                    />
                                                </>
                                            )}
                                            <span className='text_row'>
                                                Published
                                            </span>
                                            <Field
                                                name='date_created'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder='Date created'
                                                autoComplete='off'
                                            />
                                            <span className='text_row'>
                                                Updated
                                            </span>
                                            <Field
                                                name='date_updated'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder='Date updated'
                                                autoComplete='off'
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='input_row'>
                                <MultipleSelect
                                    error={errPublications.authors}
                                    label='Author(s)'
                                    handleChange={this.handleChangeAuthors}
                                    names={authors_name}
                                    search_array={authors_for_publications}
                                />
                                <MultipleSelect
                                    label='Co-author(s)'
                                    handleChange={this.handleChangeCoAuthors}
                                    names={co_authors_name}
                                    search_array={authors_for_publications}
                                />
                            </div>
                            <div className='input_row'>
                                <MultipleSelect
                                    label='Categories'
                                    handleChange={this.handleChangeCategories}
                                    names={categories_name}
                                    search_array={categories_for_publications}
                                />
                                <MultipleSelect
                                    label='Services'
                                    handleChange={this.handleChangeServices}
                                    names={services_name}
                                    search_array={services_for_publications}
                                />
                            </div>
                            <div className='input_row'>
                                <MultipleSelect
                                    label='Companies'
                                    handleChange={this.handleChangeCompanies}
                                    names={companies_name}
                                    search_array={companies_for_publications}
                                />
                            </div>
                            <div
                                className='input_row'
                                style={{ flexDirection: 'column' }}>
                                <span className='text_row'>
                                    This text will be shown in the header as
                                    part of the news. You can copy the first
                                    paragraph or write an SEO friendly
                                    introduction.
                                </span>
                                {language === 'english' ? (
                                    <Field
                                        name='public_header_english'
                                        component='textarea'
                                        placeholder='Add your description here'
                                        className='textarea'
                                        maxLengh='10'
                                    />
                                ) : (
                                    <Field
                                        name='public_header_estonian'
                                        component='textarea'
                                        placeholder='Add your description here'
                                        className='textarea'
                                        maxLengh='10'
                                    />
                                )}
                                {errorBack && (
                                    <span
                                        style={{
                                            color: 'red',
                                            marginTop: '10px',
                                        }}>
                                        {errorBack}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className='profile_block wysiwyg'>
                            <h2 className='content_title'>
                                Publication content
                            </h2>
                            {current_publication.publication_type === 'text' ? (
                                language === 'english' ? (
                                    <N1ED
                                        apiKey={'AJ9NDFLT'}
                                        loudChannel='5-stable'
                                        disabled={false}
                                        id='uuid'
                                        initialValue=''
                                        inline={true}
                                        onEditorChange={this.handleWYSIWYGEng}
                                        plugins={[
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount',
                                        ]}
                                        tagName='div'
                                        textareaName='text_english'
                                        toolbar='undo redo | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat | help'
                                        value={content_english}
                                        outputFormat='text'
                                        name='text_english'
                                    />
                                ) : (
                                    <N1ED
                                        apiKey={'AJ9NDFLT'}
                                        loudChannel='5-stable'
                                        disabled={false}
                                        id='uuid'
                                        initialValue=''
                                        inline={true}
                                        onEditorChange={this.handleWYSIWYGEst}
                                        plugins={[
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount',
                                        ]}
                                        tagName='div'
                                        textareaName='text_estonian'
                                        toolbar='undo redo | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat | help'
                                        value={content_estonian}
                                        outputFormat='text'
                                        name='text_estonian'
                                    />
                                )
                            ) : null}
                            <div className='buttons'>
                                <button className='green_button'>Update</button>
                                <button
                                    className='red_button'
                                    onClick={e => {
                                        e.preventDefault()
                                        this.toggleDialog()
                                    }}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <DialogComponent
                    open={dialogOpen}
                    onClose={this.toggleDialog}
                    classes='delete_dialog'>
                    <div className='dialog_header'>
                        Are you sure that you want to cancel?
                    </div>
                    <div className='dialog_body'>
                        If you chose yes all your modifications will be lost.
                    </div>
                    <div className='dialog_footer'>
                        <button
                            onClick={e => {
                                e.preventDefault()
                                this.reRender()
                                this.toggleDialog()
                            }}>
                            Yes, back to Publications page
                        </button>
                        <button
                            onClick={e => {
                                e.preventDefault()
                                this.toggleDialog()
                            }}>
                            No, I want to continue
                        </button>
                    </div>
                </DialogComponent>
            </TransitionedBlock>
        )
    }
}

PublicationsProfile = reduxForm({
    form: 'PublicationsProfile',
    enableReinitialize: true,
})(PublicationsProfile)

function mapStateToProps(state, props) {
    return {
        seo_tags: {
            seo_title_english: state.publications.current_publication.seo_title_english,
            seo_description_english: state.publications.current_publication.seo_description_english,
            seo_keywords_english: state.publications.current_publication.seo_keywords_english,
            seo_title_estonian: state.publications.current_publication.seo_title_estonian,
            seo_keywords_estonian: state.publications.current_publication.seo_keywords_estonian,
            seo_description_estonian: state.publications.current_publication.seo_description_estonian,
        },
        current_publication: state.publications.current_publication,
        errPublications: state.publications.error,
        authors_for_publications: state.publications.authors_for_publications,
        categories_for_publications:
            state.publications.categories_for_publications,
        services_for_publications: state.publications.services_for_publications,
        companies_for_publications:
            state.publications.companies_for_publications,
        initialValues: {
            title_english: state.publications.current_publication.title_english,
            title_estonian:
                state.publications.current_publication.title_estonian,
            date_created: state.publications.current_publication.date_created,
            date_updated: state.publications.current_publication.date_updated,
            public_header_english:
                state.publications.current_publication.public_header_english,
            public_header_estonian:
                state.publications.current_publication.public_header_estonian,
            text_english: state.publications.current_publication.text_english,
            text_estonian: state.publications.current_publication.text_estonian,
            source_link_english:
                state.publications.current_publication.source_link_english,
            video_link_english:
                state.publications.current_publication.video_link_english,
            source_link_estonian:
                state.publications.current_publication.source_link_estonian,
            // video_link_english:
            //     state.publications.current_publication.video_link_english,
        },
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getCurrentPublications,
            patchCurrentPublications,
            resetErrors,
            getAuthorsForPublications,
            getCategoriesForPublications,
            getServicesForPublications,
            getCompaniesForPublications,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicationsProfile)
