import * as types from "../actions/constants";

const INITIAL_STATE = {
    companies_for_news: [],
    services_for_news: [],
    categories_for_news: [],
    authors_for_news: [],
    current_news: [],
    all_news_list: [],
    published: [],
    in_review: [],
    error: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_ALL_NEWS_LIST_SUCCESS :
            let published = [];
            let in_review = [];
            action.payload.data.forEach(el => {
                el.status === 'published' ? published.push(el) : in_review.push(el);
            });
            return {
                ...state,
                all_news_list: action.payload.data,
                published: published,
                in_review: in_review
            };
        case types.POST_NEW_NEWS_FAIL :
            return {...state, error: action.error.response.data};
        case types.RESET_ERRORS :
            return {...state, error: {}};
        case types.GET_CURRENT_NEWS_SUCCESS :
            return {...state, current_news: action.payload.data};
        case types.PATCH_CURRENT_NEWS_FAIL :
            return { ...state, error: action.error.response.data };
        case types.PATCH_CURRENT_NEWS_SUCCESS :
            return { ...state, error: {}};
        case types.GET_AUTHORS_FOR_NEWS_SUCCESS :
            return {...state, authors_for_news: action.payload.data};
        case types.GET_CATEGORIES_FOR_NEWS_SUCCESS :
            return {...state, categories_for_news: action.payload.data};
        case types.GET_SERVICES_FOR_NEWS_SUCCESS :
            return { ...state, services_for_news: action.payload.data};
        case types.GET_COMPANIES_FOR_NEWS_SUCCESS :
            return { ...state, companies_for_news: action.payload.data};
        // case types.PATCH_CATEGORY_FOR_LAWYER_FAIL :
        //     return { ...state, error: action.error.response.data };
        // case types.PATCH_CATEGORY_FOR_LAWYER_SUCCESS :
        //     return { ...state, error: {}};
        default:
            return state;
    }
}