import * as types from './constants.jsx';

export function deleteCompany(id) {
    return {
        type: types.DELETE_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/api/companies/${id}/delete/`,
                method: 'delete',

            }
        }
    };
}




