import React from 'react';
import './RenderField.scss';

export const RenderField_text = ({ input, placeholder, type, className, meta: { touched, error }, handleInput }) => (
    <label className={touched && error ? input.value !=='' ? 'block-input-pass error_border value' : 'error_border block-input-pass' : input.value !=='' ? 'block-input-pass value' : 'block-input-pass'}>
    <input {...input} placeholder={placeholder} type={type} className={className} onKeyDown={handleInput}/>
        {touched && error && <span className="error">{error}</span>}
    </label>
);


export default RenderField_text;