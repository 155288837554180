import * as types from './constants.jsx';

export function getAvailableCompaniesList() {
    return {
        type: types.GET_AVAILABLE_COMPANIES_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/companies/`,
                method: 'get',

            }
        }
    };
}




