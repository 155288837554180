import * as types from "../actions/constants";

const INITIAL_STATE = {
    available_services: [],
    current_service: [],
    all_services_list: [],
    activeArr: [],
    unactiveArr: [],
    noDataArr: [],
    error: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_ALL_SERVICES_LIST_SUCCESS :
            let activeArr = [];
            let unactiveArr = [];
            let noDataArr = [];
            action.payload.data.forEach(el => {
                el.is_active ? activeArr.push(el) : unactiveArr.push(el);
                !el.has_data && noDataArr.push(el);
            });
            return {
                ...state,
                all_services_list: action.payload.data,
                activeArr: activeArr,
                unactiveArr: unactiveArr,
                noDataArr: noDataArr
            };
        case types.POST_NEW_SERVICE_FAIL :
            return {...state, error: action.error.response.data};
        case types.RESET_ERRORS :
            return {...state, error: {}};
        case types.GET_CURRENT_SERVICE_SUCCESS :
            return {...state, current_service: action.payload.data};
        case types.PATCH_CURRENT_SERVICE_FAIL :
            return { ...state, error: action.error.response.data };
        case types.PATCH_CURRENT_SERVICE_SUCCESS :
            return { ...state, error: {}};
        case types.GET_AVAILABLE_SERVICES_SUCCESS :
            return {...state, available_services: action.payload.data};
        case types.CONNECT_SERVICE_TO_COMPANY_FAIL :
            return { ...state, error: action.error.response.data };
        case types.CONNECT_SERVICE_TO_COMPANY_SUCCESS :
            return { ...state, error: {}};
        case types.PATCH_SERVICE_FOR_COMPANY_FAIL :
            return { ...state, error: action.error.response.data };
        case types.PATCH_SERVICE_FOR_COMPANY_SUCCESS :
            return { ...state, error: {}};
        default:
            return state;
    }
}