import * as types from "../actions/constants";

const INITIAL_STATE = {
    available_lawyers: [],
    error: {}
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_AVAILABLE_LAWYERS_LIST_SUCCESS :
            return {...state, available_lawyers: action.payload.data};
        default:
            return state;
    }
}