import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import FormControl from '@material-ui/core/FormControl'

import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import SelectComponent from '../HelperComponents/SelectComponentDefault/SelectComponent'
import MultiSelectComponent from '../HelperComponents/MultiSelectComponent/MultiSelectComponent'
import SummaryTable from './SummaryTable'
import DataCard from './DataCard/DataCard'
import RenderField_pass from '../HelperComponents/RenderField/RenderField_pass'
import renderField_add from '../HelperComponents/RenderField/RenderField_add_bluePages'
import RenderField_seo_description from '../HelperComponents/RenderField/RenderField_seo_description'
import { getOption } from '../../helpers/functions'
import countries from '../../helpers/countries.json'
import Loader from '../HelperComponents/Loader/Loader'
import SeoForm from '../HelperComponents/SeoForm/SeoForm'
import arrow from '../../assets/image/new_arrow.svg'

import {
    getAllCompaniesList,
    postNewCompany,
} from '../../actions/companiesAction'
import { changeCompaniesState } from '../../actions/changeCompanyStateAction'
import { deleteCompany } from '../../actions/deleteCompanyAction'
import { getStates } from '../../actions/StatesAction'

import './Companies.scss'
import el from 'date-fns/esm/locale/el/index.js'

class Companies extends Component {
    state = {
        loading: true,
        formOpen: false,
        controlLang: 'English',
        clicked: [],
        table_cols: [
            'ID',
            'Name',
            'Services',
            'News',
            'News Ref.',
            'Publications',
            'Publications Ref.',
            'Connected lawyers',
            'State',
            'Controls',
        ],
        country_option: null,
        country_options: [],
        formSubmitting: false,
        state_option: null,
        state_options: [],
    }

    componentDidMount() {
        const { getStates } = this.props
        getStates().then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                const states_list = res.payload.data
                let state_options = []
                states_list.forEach(e =>
                    state_options.push({
                        label: getOption(e.name),
                        value: e.id,
                    })
                )
                this.setState({ state_options })
            }
        })
        const countries_list = [...countries]
        let country_options = []
        countries_list.forEach(e =>
            country_options.push({ label: getOption(e.name), value: e.name })
        )
        this.setState({ country_options })
        this.doRequest()
    }

    doRequest = () => {
        const { getAllCompaniesList } = this.props
        getAllCompaniesList().then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({ loading: false })
            }
        })
    }

    openForm = () => {
        const formOpen = this.state.formOpen
        this.setState({ formOpen: !formOpen })
    }

    changeState = data => {
        const { changeCompaniesState } = this.props
        let id = data.is_active.split(',')[1]
        let value = {
            is_active: data.is_active.split(',')[0] === 'true' ? true : false,
        }
        changeCompaniesState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    deleteCompany = id => {
        const { deleteCompany } = this.props
        deleteCompany(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    submitForm = data => {
        const { country_option, state_option } = this.state
        const { postNewCompany, reset } = this.props
        this.setState({ formSubmitting: true })
        let dataToPost = {
            ...data,
            country: country_option ? country_option.value : null,
            state: state_option ? state_option.value : null,
        }
        postNewCompany(dataToPost).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 201
            ) {
                this.setState({ formOpen: false, formSubmitting: false })
                reset('Companies')
                this.doRequest()
            }
        })
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    tabs = ['English', 'Estonian']

    forms = [
        {
            title: '',
            slug: 'companies',
            link: '',
            pageName: 'Companies',
        },
        {
            title: '',
            slug: 'all-companies',
            link: '',
            pageName: 'All companies',
        },
    ]

    changeLang = el => {
        this.setState({ controlLang: el })
    }

    chooseForms = idx => {
        const { clicked: oldClicked } = this.state
        const elIdx = oldClicked.findIndex(el => el === idx)
        this.setState(({ clicked }) => ({
            clicked:
                elIdx === -1
                    ? [...clicked, idx]
                    : [...clicked.slice(0, elIdx), ...clicked.slice(elIdx + 1)],
        }))
    }

    render() {
        const {
            clicked,
            controlLang,
            formOpen,
            table_cols,
            loading,
            country_option,
            country_options,
            formSubmitting,
            state_option,
            state_options,
        } = this.state
        const {
            activeArr,
            unactiveArr,
            noDataArr,
            handleSubmit,
            errorAddingCompany,
            history,
        } = this.props
        if (loading) return null
        return (
            <TransitionedBlock>
                <div className='companies_wrapper'>
                    <div className='breadcrumbs'>
                        <Link to='/main/dashboard'>Home</Link>
                        &nbsp; / &nbsp; Companies
                    </div>
                    <SummaryTable
                        active_companies={activeArr.length}
                        no_data_companies={noDataArr.length}
                        unactive_companies={unactiveArr.length}
                    />

                    <div className='forms_items'>
                        {this.forms.map(
                            ({ title, slug, link, pageName }, idx) => (
                                <div
                                    key={idx}
                                    className={`choose_form ${
                                        clicked.includes(idx) ? 'clicked' : null
                                    }`}>
                                    <div
                                        className='forms_title'
                                        onClick={() => this.chooseForms(idx)}>
                                        <span>
                                            <b>{pageName}</b> titles and
                                            subtitles / SEO tags
                                        </span>
                                        <img src={arrow} alt='arrow' />
                                    </div>
                                    {clicked.includes(idx) ? (
                                        <SeoForm
                                            {...{ title, slug, link, idx }}
                                        />
                                    ) : null}
                                </div>
                            )
                        )}
                    </div>

                    <button className='green_button' onClick={this.openForm}>
                        Add a company
                    </button>
                    {formOpen ? (
                        <form
                            className='add_form'
                            onSubmit={handleSubmit(this.submitForm)}>
                            <div className='add_form-wrapper'>
                                <div className='create_info'>
                                    <div className='relative_position'>
                                        <span>Company name</span>
                                        <span className='back_error'>
                                            {errorAddingCompany.name}
                                        </span>
                                        <Field
                                            name='name'
                                            className='add_input'
                                            type='text'
                                            component={renderField_add}
                                            placeholder=''
                                            autoComplete='off'
                                        />
                                    </div>
                                    <div className='relative_position'>
                                        <span>Contact e-mail</span>
                                        <span className='back_error'>
                                            {errorAddingCompany.email}
                                        </span>
                                        <Field
                                            name='email'
                                            className='add_input'
                                            type='text'
                                            component={renderField_add}
                                            placeholder=''
                                            autoComplete='off'
                                        />
                                    </div>
                                    <div className='relative_position'>
                                        <span>Address</span>
                                        <span className='back_error'>
                                            {errorAddingCompany.address}
                                        </span>
                                        <Field
                                            name='address'
                                            className='add_input'
                                            type='text'
                                            component={RenderField_pass}
                                            placeholder=''
                                            autoComplete='off'
                                        />
                                    </div>
                                    <div className='small_br' />
                                    <span>Country</span>
                                    <FormControl style={{ width: '50%' }}>
                                        <SelectComponent
                                            value={country_option}
                                            options={country_options}
                                            change={e =>
                                                this.changeOption(
                                                    'country_option',
                                                    e
                                                )
                                            }
                                            isClearable='false'
                                            isSearchable={true}
                                            classname='state_selector'
                                            placeholder={'Choose...'}
                                        />
                                    </FormControl>
                                    <div className='add_form_city'>
                                        <div className='names first'>
                                            <span>City</span>
                                            <span className='back_error'>
                                                {errorAddingCompany.address}
                                            </span>
                                            <Field
                                                name='city'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder=''
                                                autoComplete='off'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>State</span>
                                            <span className='back_error'>
                                                {errorAddingCompany.state}
                                            </span>
                                            <FormControl
                                                className='select_wrapper'
                                                style={{ width: '100%' }}>
                                                <SelectComponent
                                                    value={state_option}
                                                    options={state_options}
                                                    change={e =>
                                                        this.changeOption(
                                                            'state_option',
                                                            e
                                                        )
                                                    }
                                                    isClearable='false'
                                                    isSearchable={false}
                                                    classname='state_selector'
                                                    placeholder={'Choose...'}
                                                />
                                            </FormControl>

                                            {/*SELECTOR STATE*/}

                                            {/*<FormControl style={{width: '100%'}}>*/}
                                            {/*    <SelectComponent*/}
                                            {/*        value={state_option}*/}
                                            {/*        options={state_options}*/}
                                            {/*        change={(e) => this.changeOption("state_option", e)}*/}
                                            {/*        isClearable="false"*/}
                                            {/*        isSearchable={false}*/}
                                            {/*        classname="state_selector"*/}
                                            {/*        placeholder={"Choose..."}*/}
                                            {/*    />*/}
                                            {/*</FormControl>*/}
                                        </div>
                                        <div className='names'>
                                            <span>Zip</span>
                                            <span className='back_error'>
                                                {errorAddingCompany.zip_code}
                                            </span>
                                            <Field
                                                name='zip_code'
                                                className='add_input'
                                                type='text'
                                                component={RenderField_pass}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='add_form_seo'>
                                    <div className='tabs'>
                                        {this.tabs.map((el, idx) => (
                                            <div
                                                key={idx}
                                                onClick={() =>
                                                    this.changeLang(el)
                                                }
                                                className={`control_lang-btn${
                                                    controlLang === el
                                                        ? ' active'
                                                        : ''
                                                }`}>
                                                {el}
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className={`seo_form${
                                            controlLang === 'English'
                                                ? ' visible'
                                                : ''
                                        }`}>
                                        <div className='names'>
                                            <span>Meta-title</span>
                                            <span className='back_error'>
                                                {errorAddingCompany.meta_title}
                                            </span>
                                            <Field
                                                name='seo_title_english'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-description</span>
                                            <span className='back_error'>
                                                {
                                                    errorAddingCompany.meta_description
                                                }
                                            </span>
                                            <Field
                                                name='seo_description_english'
                                                className='add_description'
                                                type='text'
                                                component={
                                                    RenderField_seo_description
                                                }
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-keywords</span>
                                            <span className='back_error'>
                                                {
                                                    errorAddingCompany.seo_keywords_estonian
                                                }
                                            </span>
                                            <Field
                                                name='seo_keywords_english'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className={`seo_form${
                                            controlLang === 'Estonian'
                                                ? ' visible'
                                                : ''
                                        }`}>
                                        <div className='names'>
                                            <span>Meta-title</span>
                                            <span className='back_error'>
                                                {errorAddingCompany.meta_title}
                                            </span>
                                            <Field
                                                name='seo_title_estonian'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-description</span>
                                            <span className='back_error'>
                                                {
                                                    errorAddingCompany.meta_description
                                                }
                                            </span>
                                            <Field
                                                name='seo_description_estonian'
                                                className='add_description'
                                                type='text'
                                                component={
                                                    RenderField_seo_description
                                                }
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                        <div className='names'>
                                            <span>Meta-keywords</span>
                                            <span className='back_error'>
                                                {
                                                    errorAddingCompany.seo_keywords_estonian
                                                }
                                            </span>
                                            <Field
                                                name='seo_keywords_estonian'
                                                className='add_input'
                                                type='text'
                                                component={renderField_add}
                                                placeholder=''
                                                autoComplete='off'
                                                media_error='media_error'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='add_form-button-wrapper'>
                                <button>
                                    {formSubmitting ? (
                                        <Loader size={30} color='white' />
                                    ) : (
                                        'Submit form'
                                    )}
                                </button>
                            </div>
                        </form>
                    ) : null}
                    <DataCard
                        table_cols={table_cols}
                        items={unactiveArr}
                        delete_action={this.deleteCompany}
                        stateChanger={this.changeState}
                        card_title='Unactive'
                        history={history}
                    />
                    <DataCard
                        table_cols={table_cols}
                        items={noDataArr}
                        stateChanger={this.changeState}
                        card_title='No Data'
                        history={history}
                    />
                    <DataCard
                        table_cols={table_cols}
                        items={activeArr}
                        stateChanger={this.changeState}
                        card_title='Active'
                        history={history}
                    />
                </div>
            </TransitionedBlock>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = 'Required field'
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)
    ) {
        errors.email = 'Incorrect email'
    }
    if (!values.name) {
        errors.name = 'Required field'
    }
    if (values.zip_code && values.zip_code.length > 5) {
        errors.zip_code = 'Less than 5 symbols'
    }
    return errors
}

Companies = reduxForm({
    form: 'Companies',
    validate,
})(Companies)

function mapStateToProps(state) {
    return {
        noDataArr: state.companies.noDataArr,
        unactiveArr: state.companies.unactiveArr,
        activeArr: state.companies.activeArr,
        errorAddingCompany: state.companies.error,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllCompaniesList,
            changeCompaniesState,
            deleteCompany,
            postNewCompany,
            getStates,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies)
