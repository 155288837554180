import * as types from './constants.jsx';

export function getAllServicesList() {
    return {
        type: types.GET_ALL_SERVICES_LIST,
        payload: {
            client: 'default',
            request: {
                url: `/api/services/`,
                method: 'get',
            }
        }
    };
}

export function postNewService(data) {
    return {
        type: types.POST_NEW_SERVICE,
        payload: {
            client: 'default',
            request: {
                url: `/api/services/new/`,
                method: 'post',
                data
            }
        }
    };
}

export function getCurrentService(id) {
    return {
        type: types.GET_CURRENT_SERVICE,
        payload: {
            client: 'default',
            request: {
                url: `/api/services/${id}/ `,
                method: 'get'
            }
        }
    };
}

export function patchCurrentService(id, data) {
    return {
        type: types.PATCH_CURRENT_SERVICE,
        payload: {
            client: 'default',
            request: {
                url: `/api/services/${id}/`,
                method: 'patch',
                data
            }
        }
    };
}
export function deleteService(id) {
    return {
        type: types.DELETE_SERVICE,
        payload: {
            client: 'default',
            request: {
                url: `/api/services/${id}/delete/`,
                method: 'delete',

            }
        }
    };
}


export function changeServiceState(id, data) {
    return {
        type: types.CHANGE_SERVICE_STATUS,
        payload: {
            client: 'default',
            request: {
                url: `/api/services/${id}/active/`,
                method: 'PUT',
                data
            }
        }
    };
}

export function getAvailableServicesList() {
    return {
        type: types.GET_AVAILABLE_SERVICES,
        payload: {
            client: 'default',
            request: {
                url: `/api/extra/services/available/`,
                method: 'get',

            }
        }
    };
}

export function connectServiceToCompany(data) {
    return {
        type: types.CONNECT_SERVICE_TO_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/api/services/connect/`,
                method: 'post',
                data
            }
        }
    };
}

export function patchServiceForCompany(data,id) {
    return {
        type: types.PATCH_SERVICE_FOR_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/api/services/connect/${id}/`,
                method: 'patch',
                data
            }
        }
    };
}

export function disconnectServiceFromCompany(id) {
    return {
        type: types.DISCONNECT_SERVICE_FROM_COMPANY,
        payload: {
            client: 'default',
            request: {
                url: `/api/services/connect/${id}/delete/`,
                method: 'delete',
            }
        }
    };
}

//
// export function getLawyersForCompanies() {
//     return {
//         type: types.GET_LAWYERS_FOR_COMPANIES,
//         payload: {
//             client: 'default',
//             request: {
//                 url: `/api/extra/lawyers_for_company/`,
//                 method: 'get',
//             }
//         }
//     };
// }
//
export function resetErrors() {
    return {
        type: types.RESET_ERRORS
    };
}