import React from 'react';

import check_circle from '../../assets/image/check-circle.svg';
import exclamination from '../../assets/image/exclamation.svg';

import './SummaryTable.scss'

const SummaryTable = ({in_review, published}) => {
    return (
        <div className='summary_table'>
            <div className='data_card active'>
                <div className='card_text'>
                    <span className='card_title'>published</span>
                    <span className='card_amount'>{published}</span>
                </div>
                <img src={check_circle} alt='checked'/>
            </div>
            <div className='data_card no_data' >
                <div className='card_text'>
                    <span className='card_title'>in review</span>
                    <span className='card_amount'>{in_review}</span>
                </div>
                <img src={exclamination} alt='exclamination' />
            </div>
        </div>
    );
};

export default SummaryTable;