import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';

import ControlDisconnect from "../../HelperComponents/ControlButtons/ControlDisconnect";
import SelectComponent from '../../HelperComponents/SelectComponentDefault/SelectComponent';
import { getOption, sortValues } from "../../../helpers/functions";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";

import change from '../../../assets/image/change.svg';
import edit from '../../../assets/image/edit.svg';
import arrow_up from '../../../assets/image/arrow_up.svg';
import arrow_down from '../../../assets/image/arrow_down.svg';

import '../../HelperComponents/ControlButtons/ControlButtons.scss';
import './DataCard.scss';
import ControlChangeLawyer from "../../UserProfile/ChageControls/ControlChangeLawyer";

class DataCard extends Component {

    state = {
        openDeleteDialog: false,
        openChangeDialog: false,
        active_sort: null,
        selected_id: null,
        input_value: '',
        sort_option: { label: getOption("10"), value: "10" },
        sort_options: [
            { label: getOption("10"), value: "10" },
            { label: getOption("25"), value: "25" },
            { label: getOption("50"), value: "50" },
            { label: getOption("100"), value: "100" }
        ]
    };

    handleSort = (item) => {
        const { active_sort } = this.state;
        active_sort && active_sort.slice(-2) === "up" && active_sort.includes(item) ?
            this.setState({ active_sort: `${item}_down` })
            :
            this.setState({ active_sort: `${item}_up` });
    };

    changeOption = (name, event) => {
        this.setState({ [name]: event });
    };

    onChange = (e) => {
        let input_value = e.target.value;
        this.setState({input_value});
    };

    moveToLawyerPage = (id) => {
        const { history } = this.props;
        history.push(`/main/lawyer-profile/${id}`);
    };

    render(){
        const {
            items,
            table_cols,
            disconnectLawyer,
            stateChanger,
        } = this.props;
        const {
            active_sort,
            sort_option,
            sort_options,
            input_value,
        } = this.state;

        let lawyersArray = [...items];
        lawyersArray = lawyersArray.filter(el => el.name.toLowerCase().includes(input_value.toLowerCase()));
        sortValues(lawyersArray, active_sort);

        if(items.length === 0) return <span>No lawyers yet</span>;
        return (
            <div className='company_data_card'>
                <div className='card_select_row'>
                    <span>Show</span>
                    <FormControl style={{width: "50px"}}>
                        <SelectComponent
                            value={sort_option}
                            options={sort_options}
                            change={(e) => this.changeOption("sort_option", e)}
                            isClearable="false"
                            isSearchable={false}
                            classname="state_selector"
                        />
                    </FormControl>
                    <span>entries</span>
                </div>
                <div className='card_search_row'><span>Search:</span>
                    <div className='search_form'>
                        <input
                            className="search_input"
                            type="text"
                            value={input_value}
                            onChange={this.onChange}
                            autoComplete="off"
                        />
                    </div>
                </div>
                <div className='card_table'>
                    <div className='card_items_row header_row'>
                        {table_cols.map(el => (
                            <div key={el} onClick={() => this.handleSort(el)} className="no_select">
                                <div>{el}</div>
                                {el !== "Controls" &&
                                <div className='arrows'>
                                    <img
                                        src={arrow_up}
                                        alt="up"
                                        className={active_sort === `${el}_up` ? "active_arrow" : ""}
                                    />
                                    <img
                                        src={arrow_down}
                                        alt="up"
                                        className={active_sort === `${el}_down` ? "active_arrow" : ""}
                                    />
                                </div>
                                }
                            </div>
                        ))}
                    </div>
                    {lawyersArray.length > 0 ?
                        lawyersArray.slice(0, sort_option.value).map((el, key) => (
                            <div className='card_items_row default_row' key={key}>
                                <div className='table_div'>{el.id}</div>
                                <div className='table_div'>{el.name}</div>
                                <div className='table_div'>{el.access_level.toUpperCase()}</div>
                                <div className='table_div'>{el.positions.length > 0 ? el.positions.toString() : '-'}</div>

                                {/*STATE*/}

                                <div className='table_div'>
                                <div className={el.is_active ? "table_circle active" : "table_circle"} />
                                {el.is_active ? "Active" : "Disabled"}
                                </div>

                                <div className='card_controls'>
                                    <div className='control_wrapper'>
                                        <div onClick={() => this.moveToLawyerPage(el.id)} className='control_btn link'>
                                            <img src={edit} alt='edit icon' />
                                        </div>
                                    </div>

                                    <ControlChangeLawyer itemId={el.id} itemStatus={el.is_active} stateChanger={stateChanger} />

                                    <ControlDisconnect
                                        disconnect_action={disconnectLawyer}
                                        item={'lawyer'}
                                        itemId={el.id}
                                    />
                                </div>
                            </div>
                        ))
                        :
                        <div className='card_items_row default_row' style={{height: "38px"}} />
                    }
                </div>
                <div className="card_entries_count">
                    Showing 1 to {sort_option.value > lawyersArray.length ? lawyersArray.length : sort_option.value} of {lawyersArray.length} entries
                </div>

            </div>
        );
    }
}

export default DataCard;