import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import AuthReducer from './reduceAuth';
import {reducer as formReducer} from 'redux-form';
import reduceAllUsersList from "./reduceAllUsersList";
import reduceCompanies from "./reduceCompanies";
import reduceCertainProfile from "./reduceCertainProfile";
import reduceAvailableLawyersList from "./reduceAvailableLawyersList";
import reduceAvailableCompaniesList from "./reduceAvailableCompaniesList";
import reduceLawyersList from "./reduceLawyersList";
import reduceLawyer from "./reduceLawyer";
import reduceCategories from "./reduceCategories";
import reduceServices from "./reduceServices";
import reduceNews from "./reduceNews";
import reducePublications from "./reducePublications";
import reduceStates from "./reduceStates";

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth: AuthReducer,
    users: reduceAllUsersList,
    companies: reduceCompanies,
    certainUser: reduceCertainProfile,
    available_lawyers: reduceAvailableLawyersList,
    available_companies: reduceAvailableCompaniesList,
    lawyers: reduceLawyersList,
    errors: reduceLawyer,
    categories: reduceCategories,
    services: reduceServices,
    news: reduceNews,
    publications: reducePublications,
    states: reduceStates,
});

export default rootReducer;