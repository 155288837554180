import * as types from "../actions/constants";

const INITIAL_STATE = {
    error: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.PATCH_CURRENT_LAWYER_FAIL :
            return { ...state,
                error: action.error.response.data,
            };
        case types.POST_DISCONNECT_LAWYER_FAIL :
            return { ...state, error: action.error.response.data };
        case types.POST_NEW_LAWYER_FAIL :
            return {
                ...state,
                error: action.error.response.data
            };
        case types.POST_NEW_POSITION_FAIL :
            return {
                ...state,
                error: action.error.response.data
            };
        case types.RESET_ERRORS :
            return {...state, error: {}};
        default:
            return state;
    }
}