import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import {connect} from "react-redux";
import renderField_mail from "../HelperComponents/RenderField/renderField_mail";
import { getCurrentCompany } from "../../actions/companiesAction";
import trash from "../../assets/image/trash.svg";
import {bindActionCreators} from "redux";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";

class MyServicesForm extends Component {
        state = {
            description_title: 'english',
        };

    languageHandler = (state) => {
        this.setState({description_title : state})
    };

    render(){
        const { submitFunc, handleSubmit, disabler, submitting, pristine, valid, deleteFunc, initialValues, reset  } = this.props;
        const { description_title } = this.state;
        let obj = initialValues;

        return (
            <TransitionedBlock>
                <form onSubmit={handleSubmit(submitFunc)}>
                    <div className='textarea_block'>
                        <div className='lang_switcher'>
                            <span className={`item ${description_title === 'english' ? 'active' : ''}`} onClick={() => this.languageHandler('english')}>English</span>
                            <span className={`item ${description_title === 'estonian' ? 'active' : ''}`} onClick={() => this.languageHandler('estonian')}>Estonian</span>
                        </div>
                    <div className='textarea_services'>
                        { description_title === 'english' ?
                            <Field name='description_english'
                                   component='textarea'
                                   placeholder='Your text in English'
                                   className='textarea'
                                   maxLengh='10'
                                   value=''
                            /> :
                            <Field name='description_estonian'
                                   component='textarea'
                                   placeholder='Your text in Estonian'
                                   className='textarea'
                                   maxLengh='10'
                                   value=''
                            /> }
                    </div>
                    </div>
                    <div className='row place'>
                        <div className='col'>
                            <span>Fixed rate</span>
                            <Field
                                name='fixed_rate'
                                component={renderField_mail}
                                className='add_input'
                                type='number'
                                placeholder=''
                                autoComplete='off'
                            />
                        </div>
                        <div className='col'>
                            <span>Hourly rate</span>
                            <Field
                                name='hourly_rate'
                                component={renderField_mail}
                                className='add_input'
                                type='number'
                                placeholder=''
                                autoComplete='off'
                            />
                        </div>
                    </div>
                    <div className='row controls'>
                    <button disabled={submitting || pristine || !valid || disabler === undefined} className='button' style={{marginRight: '10px'}}>Update</button>
                    <div className='control_wrapper'>
                        <button
                            disabled={disabler === undefined}
                            className='control_btn trash'
                            onClick={(e) => { e.preventDefault(); deleteFunc(obj.id); reset('MyServicesForm')}}
                        >
                            <img src={trash} alt='trash icon' />
                        </button>
                    </div>
                    </div>
                </form>
            </TransitionedBlock>
        );
    }
}

export const validate = values => {
    const errors = {};
    if (values.fixed_rate && /\./.test(values.fixed_rate)) {
        errors.fixed_rate = 'Invalid number'
    }
    if (values.hourly_rate && /\./.test(values.hourly_rate)) {
        errors.hourly_rate = 'Invalid number'
    }
    if (values.description_english && !/^\W*(\w+(\W+|$)){1,250}$/.test(values.description_english)) {
        errors.description_english = 'Maximum 250 words'
    }
    if (values.description_estonian && !/^\W*(\w+(\W+|$)){1,250}$/.test(values.description_estonian)) {
        errors.description_estonian = 'Maximum 250 words'
    }
    return errors
};

MyServicesForm = reduxForm({
    form: 'MyServicesForm',
    validate
})(MyServicesForm);

function  mapStateToProps(state, props) {
    return{

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCurrentCompany,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MyServicesForm);