//////////////////////////////auth actions//////////////////////////////

export const REGISTER = 'REGISTER'
export const REGISTER_FAIL = 'REGISTER_FAIL'

export const LOGIN = 'LOGIN'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const RESET_PASSWORD = 'RESET_PASSWORD'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL'

export const GOOGLE_AUTH = 'GOOGLE_AUTH'

//////////////////////////////////lawyers-actions/////////////////////////////////////////
export const POST_CREATE_USER = 'POST_CREATE_USER'
export const POST_CREATE_USER_FAIL = 'POST_CREATE_USER_FAIL'

export const POST_LANGUAGE = 'POST_LANGUAGE'
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE'

export const POST_DISCONNECT_LAWYER = 'POST_DISCONNECT_LAWYER'
export const POST_DISCONNECT_LAWYER_FAIL = 'POST_DISCONNECT_LAWYER_FAIL'

export const CONNECT_TO_USER = 'CONNECT_TO_USER'
export const CONNECT_TO_USER_FAIL = 'CONNECT_TO_USER_FAIL'

export const CONNECT_COMPANY_TO_USER = 'CONNECT_COMPANY_TO_USER'

export const CERTAIN_PROFILE = 'CERTAIN_PROFILE'
export const CERTAIN_PROFILE_SUCCESS = 'CERTAIN_PROFILE_SUCCESS'

export const GET_CURRENT_LAWYER = 'GET_CURRENT_LAWYER'
export const GET_CURRENT_LAWYER_SUCCESS = 'GET_CURRENT_LAWYER_SUCCESS'

export const PATCH_CURRENT_LAWYER = 'PATCH_CURRENT_LAWYER'
export const PATCH_CURRENT_LAWYER_FAIL = 'PATCH_CURRENT_LAWYER_FAIL'

export const PATCH_CURRENT_USER = 'PATCH_CURRENT_USER'
export const PATCH_CURRENT_USER_FAIL = 'PATCH_CURRENT_USER_FAIL'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_COMPANY = 'DELETE_COMPANY'
export const DELETE_LAWYER = 'DELETE_LAWYER'
export const DELETE_POSITION = 'DELETE_POSITION'

export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS'
export const CHANGE_LAWYER_STATUS = 'CHANGE_LAWYER_STATUS'
export const CHANGE_COMPANY_STATUS = 'CHANGE_COMPANY_STATUS'
export const CHANGE_POSITION_STATUS = 'CHANGE_POSITION_STATUS'

export const GET_ALL_USERS_LIST = 'GET_ALL_USERS_LIST'
export const GET_ALL_USERS_LIST_SUCCESS = 'GET_ALL_USERS_LIST_SUCCESS'

export const GET_USERS_IDS = 'GET_USERS_IDS'
export const GET_USERS_IDS_SUCCESS = 'GET_USERS_IDS_SUCCESS'

export const GET_LAWYERS_LIST = 'GET_ALL_LAWYERS_LIST'
export const GET_LAWYERS_LIST_SUCCESS = 'GET_ALL_LAWYERS_LIST_SUCCESS'

export const POST_NEW_LAWYER = 'POST_NEW_LAWYER'
export const POST_NEW_LAWYER_FAIL = 'POST_NEW_LAWYER_FAIL'

export const POST_NEW_POSITION = 'POST_NEW_POSITION'
export const POST_NEW_POSITION_FAIL = 'POST_NEW_POSITION_FAIL'

export const GET_AVAILABLE_LAWYERS_LIST = 'GET_AVAILABLE_LAWYERS_LIST'
export const GET_AVAILABLE_LAWYERS_LIST_SUCCESS =
    'GET_AVAILABLE_LAWYERS_LIST_SUCCESS'

export const GET_AVAILABLE_COMPANIES_LIST = 'GET_AVAILABLE_COMPANIES_LIST'
export const GET_AVAILABLE_COMPANIES_LIST_SUCCESS =
    'GET_AVAILABLE_COMPANIES_LIST_SUCCESS'

export const GET_USERS_FOR_LAWYERS = 'GET_USERS_FOR_LAWYERS'
export const GET_USERS_FOR_LAWYERS_SUCCESS = 'GET_USERS_FOR_LAWYERS_SUCCESS'

//////////////////////////////////companies-actions/////////////////////////////////////////
export const DISCONNECT_COMPANY = 'DISCONNECT_COMPANY'

export const RESET_ERRORS = 'RESET_ERRORS'

export const GET_CURRENT_COMPANY = 'GET_CURRENT_COMPANY'
export const GET_CURRENT_COMPANY_SUCCESS = 'GET_CURRENT_COMPANY_SUCCESS'

export const PATCH_CURRENT_COMPANY = 'PATCH_CURRENT_COMPANY'
export const PATCH_CURRENT_COMPANY_SUCCESS = 'PATCH_CURRENT_COMPANY_SUCCESS'
export const PATCH_CURRENT_COMPANY_FAIL = 'PATCH_CURRENT_COMPANY_FAIL'

export const GET_COMPANIES_FOR_LAWYERS = 'GET_COMPANIES_FOR_LAWYERS'
export const GET_COMPANIES_FOR_LAWYERS_SUCCESS =
    'GET_COMPANIES_FOR_LAWYERS_SUCCESS'

export const GET_ALL_COMPANIES_LIST = 'GET_ALL_COMPANIES_LIST'
export const GET_ALL_COMPANIES_LIST_SUCCESS = 'GET_ALL_COMPANIES_LIST_SUCCESS'

export const POST_NEW_COMPANY = 'POST_NEW_COMPANY'
export const POST_NEW_COMPANY_FAIL = 'POST_NEW_COMPANY_FAIL'

export const CONNECT_LAWYER_TO_COMPANY = 'CONNECT_LAWYER_TO_COMPANY'
export const CONNECT_LAWYER_TO_COMPANY_SUCCESS =
    'CONNECT_LAWYER_TO_COMPANY_SUCCESS'
export const CONNECT_LAWYER_TO_COMPANY_FAIL = 'CONNECT_LAWYER_TO_COMPANY_FAIL'

export const DISCONNECT_LAWYER_FROM_COMPANY = 'DISCONNECT_LAWYER_FROM_COMPANY'
export const DISCONNECT_LAWYER_FROM_COMPANY_FAIL =
    'DISCONNECT_LAWYER_FROM_COMPANY_FAIL'

export const GET_LAWYERS_FOR_COMPANIES = 'GET_LAWYERS_FOR_COMPANIES'
export const GET_LAWYERS_FOR_COMPANIES_SUCCESS =
    'GET_LAWYERS_FOR_COMPANIES_SUCCESS'
export const GET_LAWYERS_FOR_COMPANIES_FAIL = 'GET_LAWYERS_FOR_COMPANIES_FAIL'

///////////////////////////////////////////// CATEGORIES //////////////////////////////////////////////////////////////

export const GET_ALL_CATEGORIES_LIST = 'GET_ALL_CATEGORIES_LIST'
export const GET_ALL_CATEGORIES_LIST_SUCCESS = 'GET_ALL_CATEGORIES_LIST_SUCCESS'

export const POST_NEW_CATEGORY = 'POST_NEW_CATEGORY'
export const POST_NEW_CATEGORY_FAIL = 'POST_NEW_CATEGORY_FAIL'

export const GET_CURRENT_CATEGORY = 'GET_CURRENT_CATEGORY'
export const GET_CURRENT_CATEGORY_SUCCESS = 'GET_CURRENT_CATEGORY_SUCCESS'

export const PATCH_CURRENT_CATEGORY = 'PATCH_CURRENT_CATEGORY'
export const PATCH_CURRENT_CATEGORY_SUCCESS = 'PATCH_CURRENT_CATEGORY_SUCCESS'
export const PATCH_CURRENT_CATEGORY_FAIL = 'PATCH_CURRENT_CATEGORY_FAIL'

export const GET_AVAILABLE_CATEGORIES = 'GET_AVAILABLE_CATEGORIES'
export const GET_AVAILABLE_CATEGORIES_SUCCESS =
    'GET_AVAILABLE_CATEGORIES_SUCCESS'

export const CONNECT_CATEGORY_TO_LAWYER = 'CONNECT_LAWYER_TO_COMPANY'
export const CONNECT_CATEGORY_TO_LAWYER_SUCCESS =
    'CONNECT_LAWYER_TO_COMPANY_SUCCESS'
export const CONNECT_CATEGORY_TO_LAWYER_FAIL = 'CONNECT_LAWYER_TO_COMPANY_FAIL'

export const PATCH_CATEGORY_FOR_LAWYER = 'GET_CURRENT_CATEGORY' /////////////
export const PATCH_CATEGORY_FOR_LAWYER_SUCCESS =
    'PATCH_CURRENT_CATEGORY_SUCCESS'
export const PATCH_CATEGORY_FOR_LAWYER_FAIL = 'PATCH_CURRENT_CATEGORY_FAIL'

export const DISCONNECT_LAWYER_FROM_CATEGORY = 'DISCONNECT_LAWYER_FROM_CATEGORY'

export const DELETE_CATEGORY = 'DELETE_CATEGORY'

export const CHANGE_CATEGORY_STATUS = 'CHANGE_CATEGORY_STATUS'

///////////////////////////////////////////// SERVICES //////////////////////////////////////////////////////////////

export const GET_ALL_SERVICES_LIST = 'GET_ALL_SERVICES_LIST'
export const GET_ALL_SERVICES_LIST_SUCCESS = 'GET_ALL_SERVICES_LIST_SUCCESS'

export const POST_NEW_SERVICE = 'POST_NEW_SERVICE'
export const POST_NEW_SERVICE_FAIL = 'POST_NEW_SERVICE_FAIL'

export const GET_CURRENT_SERVICE = 'GET_CURRENT_SERVICE' ///////////////
export const GET_CURRENT_SERVICE_SUCCESS = 'GET_CURRENT_SERVICE_SUCCESS'

export const PATCH_CURRENT_SERVICE = 'PATCH_CURRENT_SERVICE' ///////////////
export const PATCH_CURRENT_SERVICE_SUCCESS = 'PATCH_CURRENT_SERVICE_SUCCESS'
export const PATCH_CURRENT_SERVICE_FAIL = 'PATCH_CURRENT_SERVICE_FAIL'

export const GET_AVAILABLE_SERVICES = 'GET_AVAILABLE_SERVICES'
export const GET_AVAILABLE_SERVICES_SUCCESS = 'GET_AVAILABLE_SERVICES_SUCCESS'

export const CONNECT_SERVICE_TO_COMPANY = 'CONNECT_SERVICE_TO_COMPANY'
export const CONNECT_SERVICE_TO_COMPANY_SUCCESS =
    'CONNECT_SERVICE_TO_COMPANY_SUCCESS'
export const CONNECT_SERVICE_TO_COMPANY_FAIL = 'CONNECT_SERVICE_TO_COMPANY_FAIL'

export const PATCH_SERVICE_FOR_COMPANY = 'GET_CURRENT_SERVICE' ////////////
export const PATCH_SERVICE_FOR_COMPANY_SUCCESS =
    'PATCH_SERVICE_FOR_COMPANY_SUCCESS'
export const PATCH_SERVICE_FOR_COMPANY_FAIL = 'PATCH_SERVICE_FOR_COMPANY_FAIL'

export const DISCONNECT_SERVICE_FROM_COMPANY = 'DISCONNECT_SERVICE_FROM_COMPANY'

export const DELETE_SERVICE = 'DELETE_SERVICE'

export const CHANGE_SERVICE_STATUS = 'CHANGE_SERVICE_STATUS'

/////////////////////////////////////////////////////NEWS/////////////////////////////////////////////

export const POST_NEW_NEWS = 'POST_NEW_NEWS'
export const POST_NEW_NEWS_FAIL = 'POST_NEW_NEWS_FAIL'

export const GET_ALL_NEWS_LIST = 'GET_ALL_NEWS_LIST'
export const GET_ALL_NEWS_LIST_SUCCESS = 'GET_ALL_NEWS_LIST_SUCCESS'

export const GET_CURRENT_NEWS = 'GET_CURRENT_NEWS'
export const GET_CURRENT_NEWS_SUCCESS = 'GET_CURRENT_NEWS_SUCCESS'

export const PATCH_CURRENT_NEWS = 'PATCH_CURRENT_NEWS' ///////////////
export const PATCH_CURRENT_NEWS_SUCCESS = 'PATCH_CURRENT_NEWS_SUCCESS'
export const PATCH_CURRENT_NEWS_FAIL = 'PATCH_CURRENT_NEWS_FAIL'

export const GET_AUTHORS_FOR_NEWS = 'GET_AUTHORS_FOR_NEWS'
export const GET_AUTHORS_FOR_NEWS_SUCCESS = 'GET_AUTHORS_FOR_NEWS_SUCCESS'

export const GET_CATEGORIES_FOR_NEWS = 'GET_CATEGORIES_FOR_NEWS'
export const GET_CATEGORIES_FOR_NEWS_SUCCESS = 'GET_CATEGORIES_FOR_NEWS_SUCCESS'

export const GET_SERVICES_FOR_NEWS = 'GET_SERVICES_FOR_NEWS'
export const GET_SERVICES_FOR_NEWS_SUCCESS = 'GET_SERVICES_FOR_NEWS_SUCCESS'

export const GET_COMPANIES_FOR_NEWS = 'GET_COMPANIES_FOR_NEWS'
export const GET_COMPANIES_FOR_NEWS_SUCCESS = 'GET_COMPANIES_FOR_NEWS_SUCCESS'

export const CHANGE_NEWS_STATUS = 'CHANGE_NEWS_STATUS'

export const DELETE_NEWS = 'DELETE_NEWS'

/////////////////////////////////////////////////////PUBLICATIONS/////////////////////////////////////////////

export const POST_NEW_PUBLICATIONS = 'POST_NEW_PUBLICATIONS'
export const POST_NEW_PUBLICATIONS_FAIL = 'POST_NEW_PUBLICATIONS_FAIL'

export const GET_ALL_PUBLICATIONS_LIST = 'GET_ALL_PUBLICATIONS_LIST'
export const GET_ALL_PUBLICATIONS_LIST_SUCCESS =
    'GET_ALL_PUBLICATIONS_LIST_SUCCESS'

export const GET_CURRENT_PUBLICATIONS = 'GET_CURRENT_PUBLICATIONS'
export const GET_CURRENT_PUBLICATIONS_SUCCESS =
    'GET_CURRENT_PUBLICATIONS_SUCCESS'

export const PATCH_CURRENT_PUBLICATIONS = 'PATCH_CURRENT_PUBLICATIONS' ///////////////
export const PATCH_CURRENT_PUBLICATIONS_SUCCESS =
    'PATCH_CURRENT_PUBLICATIONS_SUCCESS'
export const PATCH_CURRENT_PUBLICATIONS_FAIL = 'PATCH_CURRENT_PUBLICATIONS_FAIL'

export const GET_AUTHORS_FOR_PUBLICATIONS = 'GET_AUTHORS_FOR_PUBLICATIONS'
export const GET_AUTHORS_FOR_PUBLICATIONS_SUCCESS =
    'GET_AUTHORS_FOR_PUBLICATIONS_SUCCESS'

export const GET_CATEGORIES_FOR_PUBLICATIONS = 'GET_CATEGORIES_FOR_PUBLICATIONS'
export const GET_CATEGORIES_FOR_PUBLICATIONS_SUCCESS =
    'GET_CATEGORIES_FOR_PUBLICATIONS_SUCCESS'

export const GET_SERVICES_FOR_PUBLICATIONS = 'GET_SERVICES_FOR_PUBLICATIONS'
export const GET_SERVICES_FOR_PUBLICATIONS_SUCCESS =
    'GET_SERVICES_FOR_PUBLICATIONS_SUCCESS'

export const GET_COMPANIES_FOR_PUBLICATIONS = 'GET_COMPANIES_FOR_PUBLICATIONS'
export const GET_COMPANIES_FOR_PUBLICATIONS_SUCCESS =
    'GET_COMPANIES_FOR_PUBLICATIONS_SUCCESS'

export const CHANGE_PUBLICATIONS_STATUS = 'CHANGE_PUBLICATIONS_STATUS'

export const DELETE_PUBLICATIONS = 'DELETE_PUBLICATIONS'

export const GET_STATES = 'GET_STATES'
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS'

/////////////////////////////////////////////////////SEO/////////////////////////////////////////////

export const GET_SEO_FORMS_INFO = 'GET_SEO_FORMS_INFO'
export const GET_SEO_FORMS_INFO_SUCCESS = 'GET_SEO_FORMS_INFO_SUCCESS'
export const GET_SEO_FORMS_INFO_FAIL = 'GET_SEO_FORMS_INFO_FAIL'

export const PATCH_SEO_FORMS_INFO = 'PATCH_SEO_FORMS_INFO'
export const PATCH_SEO_FORMS_INFO_SUCCESS = 'PATCH_SEO_FORMS_INFO_SUCCESS'
export const PATCH_SEO_FORMS_INFO_FAIL = 'PATCH_SEO_FORMS_INFO_FAIL'

export const GET_SEO_FORMS_PROFILE_INFO = 'GET_SEO_FORMS_PROFILE_INFO'
export const GET_SEO_FORMS_PROFILE_INFO_SUCCESS =
    'GET_SEO_FORMS_PROFILE_INFO_SUCCESS'
export const GET_SEO_FORMS_PROFILE_INFO_FAIL = 'GET_SEO_FORMS_PROFILE_INFO_FAIL'

export const PATCH_SEO_FORMS_PROFILE_INFO = 'PATCH_SEO_FORMS_PROFILE_INFO'
export const PATCH_SEO_FORMS_PROFILE_INFO_SUCCESS =
    'PATCH_SEO_FORMS_PROFILE_INFO_SUCCESS'
export const PATCH_SEO_FORMS_PROFILE_INFO_FAIL =
    'PATCH_SEO_FORMS_PROFILE_INFO_FAIL'
