import React from 'react';

import check_circle from '../../assets/image/check-circle.svg';
// import exclamination from '../../assets/image/exclamation.svg';
import x_circle from '../../assets/image/x_circle.svg';

import './SummaryTable.scss'

const SummaryTable = ({active_users, no_data_lawyers, unactive_users}) => {
    return (
        <div className='summary_table'>
            <div className='data_card active'>
                <div className='card_text'>
                    <span className='card_title'>active users</span>
                    <span className='card_amount'>{active_users}</span>
                </div>
                <img src={check_circle} alt='checked'/>
            </div>
            {/*<div className='data_card active_without_lawyer'>*/}
                {/*<div className='card_text'>*/}
                    {/*<span className='card_title'>ACTIVE COMPANIES WITHOUT A LAWYER</span>*/}
                    {/*<span className='card_amount'>{active_companies_without_lawyer}</span>*/}
                {/*</div>*/}
                {/*<img src={check_circle} alt='checked'/>*/}
            {/*</div>*/}
            {/*<div className='data_card no_data' >*/}
            {/*    <div className='card_text'>*/}
            {/*        <span className='card_title'>no data lawyers</span>*/}
            {/*        <span className='card_amount'>{no_data_lawyers}</span>*/}
            {/*    </div>*/}
            {/*    <img src={exclamination} alt='exclamination' />*/}
            {/*</div>*/}
            <div className='data_card unactive' >
                <div className='card_text'>
                    <span className='card_title'>unactive users</span>
                    <span className='card_amount'>{unactive_users}</span>
                </div>
                <img src={x_circle} alt='X ciircle' />
            </div>
        </div>
    );
};

export default SummaryTable;