import React, { Component, Fragment } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Companies from '../../components/Companies/Companies'
import Header from '../../components/Header/Header'
import Sidebar from '../../components/Sidebar/Sidebar'
import Dashboard from '../../components/Dashboard/Dashboard'
import Footer from '../../components/Footer/Footer'
import Lawyers from '../../components/Lawyers/Lawyers'
import Categories from '../../components/Categories/Categories'
import Publications from '../../components/Publications/Publications'
import Services from '../../components/Services/Services'
import News from '../../components/News/News'
import SeoPages from '../../components/SeoPages/SeoPages'
import Users from '../../components/Users/Users'
import UserProfile from '../../components/UserProfile/UserProfile'
import CompanyProfile from '../../components/CompanyProfile/CompanyProfile'
import LawyerProfile from '../../components/LawyerProfile/LawyerProfile'
import CategoryProfile from '../../components/CategoryProfile/CategoryProfile'
import ServicesProfile from '../../components/ServicesProfile/ServicesProfile'
import NewsProfile from '../../components/NewsProfile/NewsProfile'
import AddNewsPage from '../../components/NewsProfile/AddNewsPage'
import AddNewsLinkPage from '../../components/NewsProfile/AddNewsLinkPage'
import AddNewsVideoPage from '../../components/NewsProfile/AddNewsVideoPage'
import AddPublicationsPage from '../../components/PublicationsProfile/AddPublicationsPage'
import AddPublicationsLinkPage from '../../components/PublicationsProfile/AddPublicationsLinkPage'
import AddPublicationsVideoPage from '../../components/PublicationsProfile/AddPublicationsVideoPage'
import PublicationsProfile from '../../components/PublicationsProfile/PublicationsProfile'

class Container extends Component {
    state = {}

    componentDidUpdate(prevProps) {
        console.log(this.props.match.url)
    }

    render() {
        const { match, history } = this.props

        if (!localStorage.token) return <Redirect to='/auth' />
        return (
            <Fragment>
                <div className='main_wrapper'>
                    <Sidebar />
                    <div className='main_content'>
                        <div>
                            <Header history={history} />
                            <Switch>
                                <Route
                                    path={match.url}
                                    exact
                                    component={() => (
                                        <Redirect to='/main/dashboard' />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/dashboard`}
                                    component={Dashboard}
                                />
                                <Route
                                    path={`${match.url}/companies`}
                                    component={Companies}
                                />
                                <Route
                                    path={`${match.url}/lawyers`}
                                    component={Lawyers}
                                />
                                <Route
                                    path={`${match.url}/categories`}
                                    component={Categories}
                                />
                                <Route
                                    path={`${match.url}/services`}
                                    component={Services}
                                />
                                <Route
                                    path={`${match.url}/news`}
                                    component={News}
                                />
                                <Route
                                    path={`${match.url}/publications`}
                                    component={Publications}
                                />
                                <Route
                                    path={`${match.url}/seo-pages`}
                                    component={SeoPages}
                                />
                                <Route
                                    path={`${match.url}/legal-users`}
                                    component={Users}
                                />
                                <Route
                                    path={`${match.url}/user-profile/:id`}
                                    render={props => <UserProfile {...props} />}
                                />
                                <Route
                                    path={`${match.url}/company-profile/:id`}
                                    render={props => (
                                        <CompanyProfile {...props} />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/lawyer-profile/:id`}
                                    render={props => (
                                        <LawyerProfile {...props} />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/category-profile/:id`}
                                    render={props => (
                                        <CategoryProfile {...props} />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/services-profile/:id`}
                                    render={props => (
                                        <ServicesProfile {...props} />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/news-profile/:id`}
                                    render={props => <NewsProfile {...props} />}
                                />
                                <Route
                                    path={`${match.url}/add-news`}
                                    render={props => <AddNewsPage {...props} />}
                                />
                                <Route
                                    path={`${match.url}/add-news-link`}
                                    render={props => (
                                        <AddNewsLinkPage {...props} />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/add-news-video`}
                                    render={props => (
                                        <AddNewsVideoPage {...props} />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/add-publication`}
                                    render={props => (
                                        <AddPublicationsPage {...props} />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/add-publication-link`}
                                    render={props => (
                                        <AddPublicationsLinkPage {...props} />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/add-publication-video`}
                                    render={props => (
                                        <AddPublicationsVideoPage {...props} />
                                    )}
                                />
                                <Route
                                    path={`${match.url}/publications-profile/:id`}
                                    render={props => (
                                        <PublicationsProfile {...props} />
                                    )}
                                />
                                <Route render={() => <p>Not found</p>} />
                            </Switch>
                        </div>
                        <Footer />
                    </div>
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
