import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import { bindActionCreators } from 'redux';
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import FlagIcon from "../../../FlagIcon";
import RenderField_mail from "../../HelperComponents/RenderField/renderField_mail";
import {validate} from "../../Categories/Categories";
import { patchCurrentService } from "../../../actions/ServicesAction";


class TitleDialog extends Component {


    submitForm = (data) => {
        const { patchCurrentService, toggler, doRequest } = this.props;
        let id = data.id;
        let obj = {'title_english':data.title_english, 'title_estonian':data.title_estonian};
        patchCurrentService(id, obj).then(res => {
            if(res.payload && res.payload.status && res.payload.status === 200) {
                toggler();
                doRequest();
            }
        })
    };

    render(){
        const { state, toggler, handleSubmit, reset,submitting, pristine, valid } = this.props;
        return (
            <DialogComponent
                open={state}
                onClose={() => {toggler(); reset('TitleDialog')}}
                classes='delete_dialog'
            >
                <div className="dialog_header">
                    Change service title
                </div>
                <form className='add_form' onSubmit={handleSubmit(this.submitForm)}>
                <div className="dialog_body">

                        <div className="relative_position">
                            <FlagIcon
                                code='gb'
                                squared={true}
                                size={'lg'}
                            />
                            <span>Title of service</span>
                            <span className="back_error">{}</span>
                            <Field
                                name="title_english"
                                className="add_input"
                                type="text"
                                component={RenderField_mail}
                                placeholder=""
                                autoComplete='off'
                            />
                        </div>
                        <div className="relative_position">
                            <FlagIcon
                                code='ee'
                                squared={true}
                                size={'lg'}
                            />
                            <span>Title of service</span>
                            <span className="back_error">{}</span>
                            <Field
                                name="title_estonian"
                                className="add_input"
                                type="text"
                                component={RenderField_mail}
                                placeholder=""
                                autoComplete='off'
                            />
                        </div>
                </div>
                <div className="dialog_footer">
                    <button className='add_btn' disabled={submitting || pristine || !valid}>Apply</button>
                    <button className='cancel_btn' onClick={(e) => {e.preventDefault(); toggler(); reset('TitleDialog')}}>Cancel</button>
                </div>
            </form>
    </DialogComponent>

        );
    }
}

TitleDialog = reduxForm({
    form: 'TitleDialog',
    enableReinitialize: true,
    validate
})(TitleDialog);

function mapStateToProps(state) {
    return{

    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        patchCurrentService,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TitleDialog);
