import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import FormControl from '@material-ui/core/FormControl'
import SelectComponent from '../HelperComponents/SelectComponentDefault/SelectComponent'
import { bindActionCreators } from 'redux'
import {
    getAvailableServicesList,
    connectServiceToCompany,
} from '../../actions/ServicesAction'
import { getCurrentCompany } from '../../actions/companiesAction'
import { getOption } from '../../helpers/functions'

class AddServiceForm extends Component {
    state = {
        service_option: null,
        service_options: [],
        placeholderValue: true,
        description_title: 'english',
    }

    componentDidMount() {
        const { getAvailableServicesList } = this.props
        getAvailableServicesList().then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                let service_options = res.payload.data

                this.setState({
                    service_options: service_options.map(e => ({
                        label: getOption(e.title_english),
                        value: e.title_english,
                        id: e.id
                    })),
                })
            }
        })
    }

    changeOption = (name, event) => {
        this.setState({ [name]: event })
    }

    submitForm = data => {
        const {
            connectServiceToCompany,
            getCurrentCompany,
            companyId,
            reset,
            toggler,
        } = this.props
        const { service_option } = this.state
        let obj = { ...data, service: service_option.id, company: companyId }
        connectServiceToCompany(obj).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 201
            ) {
                reset('AddServiceForm')
                getCurrentCompany(companyId)
                toggler()
            }
        })
    }

    languageHandler = state => {
        this.setState({ description_title: state })
    }

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props
        const {
            service_option,
            service_options,
            description_title,
        } = this.state

        return (
            <form onSubmit={handleSubmit(this.submitForm)}>
                <div className='block_field'>
                    <span className='field_title'>Search for a service</span>
                    <FormControl style={{ width: '35%' }}>
                        <SelectComponent
                            value={service_option}
                            options={service_options}
                            change={e => this.changeOption('service_option', e)}
                            isClearable='false'
                            isSearchable={true}
                            classname='state_selector'
                            placeholder={'Search from services'}
                        />
                    </FormControl>
                </div>
                <div className='collapse_row'>
                    <div className='block_field'>
                        <span className='field_title big'>Fixed rate</span>
                        <Field
                            name='fixed_rate'
                            className='add_input'
                            type='number'
                            component='input'
                            placeholder='Add rate in euros. '
                            autoComplete='off'
                        />
                    </div>
                    <div className='block_field'>
                        <span className='field_title big'>Hourly rate</span>
                        <Field
                            name='hourly_rate'
                            className='add_input'
                            type='number'
                            component='input'
                            placeholder='Add rate in euros.'
                            autoComplete='off'
                        />
                    </div>
                </div>
                <div className='collapse_row'>
                    <div className='card_body'>
                        <span className='field_title'>Description</span>
                        <div className='textarea_block'>
                            <div className='lang_switcher'>
                                <span
                                    className={`item ${
                                        description_title === 'english'
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        this.languageHandler('english')
                                    }>
                                    English
                                </span>
                                <span
                                    className={`item ${
                                        description_title === 'estonian'
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() =>
                                        this.languageHandler('estonian')
                                    }>
                                    Estonian
                                </span>
                            </div>
                            {description_title === 'english' ? (
                                <Field
                                    name='description_english'
                                    className='textarea'
                                    component='textarea'
                                    placeholder='Your text in English'
                                />
                            ) : (
                                <Field
                                    name='description_estonian'
                                    className='textarea'
                                    component='textarea'
                                    placeholder='Your text in Estonian'
                                />
                            )}
                        </div>
                    </div>
                </div>
                <button disabled={!service_option || !valid}>Add</button>
            </form>
        )
    }
}

export const validate = values => {
    const errors = {}
    if (
        values.description_english &&
        !/^\W*(\w+(\W+|$)){1,250}$/.test(values.description_english)
    ) {
        errors.description_english = 'Maximum 250 words'
    }
    if (
        values.description_estonian &&
        !/^\W*(\w+(\W+|$)){1,250}$/.test(values.description_estonian)
    ) {
        errors.description_estonian = 'Maximum 250 words'
    }
    return errors
}

AddServiceForm = reduxForm({
    form: 'AddServiceForm',
    validate,
})(AddServiceForm)

function mapStateToProps(state, props) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAvailableServicesList,
            connectServiceToCompany,
            getCurrentCompany,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddServiceForm)
