import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import TransitionedBlock from '../HelperComponents/TransitionedBlock/TransitionedBlock'
import DataCard from './DataCard/DataCard'
import SummaryTable from './SummaryTable'
import {
    getAllPublicationsList,
    deletePublications,
    changePublicationsState,
} from '../../actions/PublicationsAction'
import SeoForm from '../HelperComponents/SeoForm/SeoForm'
import arrow from '../../assets/image/new_arrow.svg'

import './Publications.scss'

class Publications extends Component {
    state = {
        loading: true,
        formOpen: false,
        clicked: [],
        setOpen: false,
        table_cols: [
            'Title',
            'Published by',
            'Date',
            'Author',
            'Co-Author(s)',
            'Linked company',
            'Status',
            'Controls',
        ],
    }

    componentDidMount() {
        this.doRequest()
    }

    doRequest = () => {
        const { getAllPublicationsList } = this.props
        getAllPublicationsList().then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.setState({ loading: false })
            }
        })
    }

    publicationsStateChanger = data => {
        const { changePublicationsState } = this.props
        let id = data.status.split(' ')[1]
        let value = {
            status:
                data.status.split(' ')[0] === 'review' ? 'review' : 'published',
        }
        changePublicationsState(id, value).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 200
            ) {
                this.doRequest()
            }
        })
    }

    publicationsDeletion = id => {
        const { deletePublications } = this.props
        deletePublications(id).then(res => {
            if (
                res.payload &&
                res.payload.status &&
                res.payload.status === 204
            ) {
                this.doRequest()
            }
        })
    }

    forms = [
        {
            title: '',
            slug: 'publications',
            link: '',
            pageName: 'Publications titles and subtitles / SEO tags',
        },
        {
            title: '',
            slug: 'all-publications',
            link: '',
            pageName: 'All publications titles and subtitles / SEO tags',
        },
    ]

    chooseForms = idx => {
        const { clicked: oldClicked } = this.state
        const elIdx = oldClicked.findIndex(el => el === idx)
        this.setState(({ clicked }) => ({
            clicked:
                elIdx === -1
                    ? [...clicked, idx]
                    : [...clicked.slice(0, elIdx), ...clicked.slice(elIdx + 1)],
        }))
    }

    render() {
        const { table_cols, loading, clicked } = this.state
        const { history, in_review, published } = this.props
        if (loading) return null
        return (
            <TransitionedBlock>
                <div className='categories_wrapper'>
                    <div className='breadcrumbs'>
                        <Link to='/main/dashboard'>Home</Link>
                        &nbsp; / &nbsp; Publications
                    </div>
                    <SummaryTable
                        in_review={in_review.length}
                        published={published.length}
                    />

                    <div className='forms_items'>
                        {this.forms.map(
                            ({ title, slug, link, pageName }, idx) => (
                                <div
                                    key={idx}
                                    className={`choose_form ${
                                        clicked.includes(idx) ? 'clicked' : null
                                    }`}>
                                    <div
                                        className='forms_title'
                                        onClick={() => this.chooseForms(idx)}>
                                        <span>{pageName}</span>
                                        <img src={arrow} alt='arrow' />
                                    </div>
                                    {clicked.includes(idx) ? (
                                        <SeoForm
                                            {...{ title, slug, link, idx }}
                                        />
                                    ) : null}
                                </div>
                            )
                        )}
                    </div>

                    <DataCard
                        table_cols={table_cols}
                        items={in_review}
                        delete_action={this.publicationsDeletion}
                        stateChanger={this.publicationsStateChanger}
                        card_title='In review'
                        history={history}
                    />
                    <DataCard
                        table_cols={table_cols}
                        items={published}
                        stateChanger={this.publicationsStateChanger}
                        card_title='Published'
                        history={history}
                    />
                </div>
            </TransitionedBlock>
        )
    }
}

Publications = reduxForm({
    form: 'News',
})(Publications)

function mapStateToProps(state) {
    return {
        all_publications_list: state.publications.all_publications_list,
        in_review: state.publications.in_review,
        published: state.publications.published,
        err_message: state.publications.error,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAllPublicationsList,
            deletePublications,
            changePublicationsState,
        },
        dispatch
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Publications)
